import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { CurrencyFormatter } from '../../constants';
import { exportStockReport, exportStockReportV2, getCompanyList, getStockList } from '../../services';

const engineList = ['D20E', 'D19E', 'D19Er', 'D18E', 'D14Er', 'D13E', 'D12E', 'D11H', 'D10H', 'D9E', 'EM120'];

class StockReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialID: '',
      materialName: '',
      engineType: '',
      productCode: '',
      companyID: props.auth.companyID === 'TCT_DS_VN' ? '' : props.auth.companyID,
      companyIDs: [],

      stockList: [],
      stockListSearchResult: [],
      stockListDisplay: [],
      page: 1,
      pageSize: 10,
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, setErrorMessage, common, setMaterialList } = this.props;
    const { pageSize } = this.state;
    setLoading(true);
    try {
      let { materialList } = common;
      if (materialList.length === 0) {
        const getMaterialListResult = await getStockList(auth.companyID);
        materialList = getMaterialListResult.data;
        setMaterialList(materialList);
      }
      const getCompanyListResult = await getCompanyList();
      this.setState({
        stockList: materialList,
        stockListSearchResult: materialList,
        stockListDisplay: materialList.slice(0, pageSize),
        companyIDs: [
          { id: '', label: 'Toàn Tổng công ty' },
          ...getCompanyListResult.data.map((e) => {
            return { id: e.companyID, label: e.companyName };
          }),
        ],
      });
    } catch {
      setErrorMessage('Có lỗi khi tải trang. Vui lòng thử lại');
      return;
    }
    setLoading(false);
  };

  filterStockList = () => {
    const { materialID, materialName, engineType, productCode, companyID, stockList, pageSize } = this.state;
    let stockListSearchResult = stockList;
    if (materialID !== '') {
      stockListSearchResult = stockListSearchResult.filter((e) => e.materialID.includes(materialID));
    }
    if (materialName !== '') {
      stockListSearchResult = stockListSearchResult.filter((e) => e.materialName.toUpperCase().includes(materialName.toUpperCase()));
    }
    if (engineType !== '') {
      stockListSearchResult = stockListSearchResult.filter((e) => e.engineList != null && e.engineList.includes(engineType));
    }
    if (productCode !== '') {
      stockListSearchResult = stockListSearchResult.filter((e) => e.productCode.toUpperCase().includes(productCode.toUpperCase()));
    }
    if (companyID !== '') {
      stockListSearchResult = stockListSearchResult.filter((e) => e.companyID === companyID);
    }
    this.setState({
      stockListSearchResult,
      stockListDisplay: stockListSearchResult.slice(0, pageSize),
    });
  };

  exportStockReport = async () => {
    const { setErrorMessage, setLoading } = this.props;
    const { materialID, engineType, companyID } = this.state;
    setLoading(true);

    await exportStockReport(materialID, engineType, companyID)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bao_cao_ton_kho.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
    setLoading(false);
  };

  exportStockReportV2 = async () => {
    const { setErrorMessage, setLoading } = this.props;
    const { materialID, engineType, companyID } = this.state;
    setLoading(true);
    await exportStockReportV2(materialID, engineType, companyID)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bao_cao_ton_kho_v2.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
    setLoading(false);
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const { stockListSearchResult, stockListDisplay, materialID, engineType, materialName, productCode, page, pageSize, companyID, companyIDs } = this.state;

    return (
      <div className="stock-report">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Báo cáo tồn kho</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="materialID-TextInput"
                placeholder="Vui lòng nhập một phần mã vật tư để tìm kiếm"
                labelText="Mã vật tư"
                value={materialID}
                onChange={(e) => this.setState({ materialID: e.target.value })}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="materialName-TextInput"
                placeholder="Vui lòng nhập một phần tên vật tư để tìm kiếm"
                labelText="Tên vật tư"
                value={materialName}
                onChange={(e) => this.setState({ materialName: e.target.value })}
              />
            </div>
            <div className="bx--col-lg-4">
              <Button onClick={() => this.filterStockList()} style={{ marginTop: '1rem' }}>
                Tìm
              </Button>
              <Button onClick={() => this.exportStockReport()} style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                Xuất báo cáo
              </Button>
              <Button onClick={() => this.exportStockReportV2()} style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                Xuất báo cáo v2
              </Button>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="productCode-TextInput"
                placeholder="Vui lòng nhập một phần mã nhà sản xuất để tìm kiếm"
                labelText="Mã nhà sản xuất"
                value={productCode}
                onChange={(e) => this.setState({ productCode: e.target.value })}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="engineType-Dropdown"
                titleText="Loại đầu máy"
                label=""
                items={engineList.map((e) => {
                  return { id: e, label: e };
                })}
                selectedItem={engineType === '' ? null : engineList.find((e) => e.id === engineType)}
                onChange={(e) => this.setState({ engineType: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="companyID-Dropdown"
                titleText="Đơn vị"
                label=""
                items={companyIDs}
                selectedItem={companyIDs.find((e) => e.id === companyID)}
                onChange={(e) => this.setState({ companyID: e.selectedItem.id })}
                disabled={auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Có tất cả ${stockListSearchResult.length} danh mục vật tư tồn kho.`}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader>STT</TableHeader>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="productCode" style={{ whiteSpace: 'nowrap' }}>
                        Mã nhà sản xuất
                      </TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit" style={{ whiteSpace: 'nowrap' }}>
                        Đơn vị
                      </TableHeader>
                      <TableHeader key="quantity" style={{ whiteSpace: 'nowrap' }}>
                        Lượng tồn
                      </TableHeader>
                      <TableHeader key="amount">Tiền tồn</TableHeader>
                      {auth.companyID === 'TCT_DS_VN' && <TableHeader key="companyID">Xí nghiệp</TableHeader>}
                      {auth.companyID === 'TCT_DS_VN' && <TableHeader key="total">Tổng lượng tồn</TableHeader>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stockListDisplay.map((row, index) => (
                      <TableRow key={`row-${index.toString()}}`}>
                        <TableCell key={`stt-${index.toString()}`}>{index + 1}</TableCell>
                        <TableCell key={`materialID-${index.toString()}`}>{row.materialID}</TableCell>
                        <TableCell key={`productCode-${index.toString()}`}>{row.productCode}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>{row.materialName}</TableCell>
                        <TableCell key={`unit-${index.toString()}`}>{row.unit}</TableCell>
                        <TableCell key={`quantity-${index.toString()}`}>{row.quantity}</TableCell>
                        <TableCell key={`amount-${index.toString()}`}>{CurrencyFormatter.format(row.amount)}</TableCell>
                        {auth.companyID === 'TCT_DS_VN' && (
                          <TableCell key={`companyID-${index.toString()}`}>{companyIDs.find((c) => c.id === row.companyID).label}</TableCell>
                        )}
                        {auth.companyID === 'TCT_DS_VN' && (
                          <TableCell key={`total-${index.toString()}`}>
                            {stockListSearchResult.reduce(
                              (accumulator, currentValue) => accumulator + (currentValue.materialID === row.materialID ? currentValue.quantity : 0),
                              0
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[10, 20, 30, 40, 50]}
                totalItems={stockListSearchResult.length}
                onChange={(target) => {
                  this.setState({
                    stockListDisplay: stockListSearchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

StockReport.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockReport);
