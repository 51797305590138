import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { getCompanyList, getPlanList, getUserList } from '../../services';

class PlanList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planList: [],
      userList: [],
      companyList: [],
      redirect: '',
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth } = this.props;
    const companyID = auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID;
    const status = companyID === 'TCT_DS_VN' ? 'completed' : '';
    setLoading(true);
    const getPlanListResult = await getPlanList(companyID, status);
    const getUserListResult = await getUserList();
    const getCompanyListResult = await getCompanyList();
    setLoading(false);
    this.setState({ planList: getPlanListResult.data, userList: getUserListResult.data, companyList: getCompanyListResult.data });
  };

  render() {
    // Props first
    const { setErrorMessage, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const { planList, userList, companyList, redirect } = this.state;

    if (redirect !== '') {
      return redirect;
    }

    return (
      <div className="engine">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal className="btn-success" open={submitResult !== ''} size="sm" onClose={() => this.reload()}>
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter onRequestSubmit={() => this.reload()} primaryButtonText="OK" secondaryButtonText="" />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Kế hoạch cung ứng vật tư</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          {auth.companyID !== 'TCT_DS_VN' && auth.role === 'phongkehoachvattu' && (
            <div className="bx--row">
              <div className="bx--col-lg-12">
                <Button onClick={() => this.setState({ redirect: <Redirect to="/plan/new" /> })}>Tạo kế hoạch vật tư mới</Button>
              </div>
            </div>
          )}
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Có tất cả ${planList.length} kế hoạch cung ứng vật tư.`}>
                <Table style={{ maxHeigh: '70vh' }}>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="stt">STT</TableHeader>
                      <TableHeader key="planName">Tên kế hoạch</TableHeader>
                      <TableHeader key="companyName">Thuộc đơn vị</TableHeader>
                      <TableHeader key="secondApprover">Người phê duyệt cuối</TableHeader>
                      <TableHeader key="status">Trạng thái</TableHeader>
                      <TableHeader key="description">Diễn giải</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {planList.map((plan, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`stt-${index.toString()}`}>{index + 1}</TableCell>
                        <TableCell key={`planName-${index.toString()}`}>
                          <Link
                            to={{
                              pathname: '/plan/detail',
                              search: `?planID=${plan.id}`,
                            }}
                          >
                            {plan.planName}
                          </Link>
                        </TableCell>
                        <TableCell key={`companyName-${index.toString()}`}>{companyList.find((e) => e.companyID === plan.companyID).companyName}</TableCell>
                        <TableCell key={`secondApprover-${index.toString()}`}>{userList.find((e) => e.userID === plan.secondApprover).username}</TableCell>
                        <TableCell key={`planStatus-${index.toString()}`}>
                          {plan.status === 'created' && <p style={{ color: '#da1e28' }}>Chờ được phê duyệt</p>}
                          {plan.status === 'half-approved' && <p style={{ color: '#cfe625' }}>Đã được phòng kế toán phê duyệt</p>}
                          {plan.status === 'completed' && <p style={{ color: '#12e65c' }}>Đã được ban giám đốc phê duyệt</p>}
                          {plan.status === 'cancelled' && <p style={{ color: 'black' }}>Đã bị huỷ</p>}
                        </TableCell>
                        <TableCell key={`description-${index.toString()}`}>{plan.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

PlanList.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,

  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanList);
