import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComboBox,
  ComposedModal,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setSubmitValue } from '../../actions/commonAction';
import { materialGroups, materialTypes } from '../../constants';
import { getMaterialInfo, getSpecList, getTechSpecStandards, updateMaterialInfo } from '../../services';

class MaterialUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      materialID: '',
      materialName: '',
      materialNameErrorMessage: '',
      unit: '',
      unitList: [],
      unitErrorMessage: '',
      productCode: '',
      materialGroupID: '',
      engineList: '',
      materialTypeID: '',
      materialTypeIDErrorMessage: '',
      specID: '',
      techSpecList: [],
      specStandardList: [],
    };
  }

  componentDidMount = async () => {
    const { setErrorMessage, setLoading, location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params == null) {
      setErrorMessage('Không có mã vật tư!!!');
      return;
    }
    const materialID = params.get('materialID');
    setLoading(true);
    try {
      const getMaterialInfoResult = await getMaterialInfo(materialID);
      const getTechSpecListResult = await getSpecList();
      const { specID } = getMaterialInfoResult.data;
      let specStandardList = [];
      if (specID != null && specID !== '') {
        const getSpecStandardLitResult = await getTechSpecStandards(specID);
        specStandardList = getSpecStandardLitResult.data;
      }
      this.setState({
        id: getMaterialInfoResult.data.id,
        materialID,
        materialName: getMaterialInfoResult.data.materialName,
        unit: getMaterialInfoResult.data.unit,
        productCode: getMaterialInfoResult.data.productCode,
        materialGroupID: getMaterialInfoResult.data.materialGroupID,
        materialTypeID: getMaterialInfoResult.data.materialTypeID,
        materialTypeName: getMaterialInfoResult.data.materialTypeName,
        engineList: getMaterialInfoResult.data.engineList,
        specID,
        specStandardList,
        techSpecList: [].concat({ id: '', label: '' }).concat(
          getTechSpecListResult.data.map((e) => {
            return { id: e.specID, label: e.specID.concat(' - ').concat(e.specName) };
          })
        ),
        unitList: [
          { id: 'bộ', label: 'bộ' },
          { id: 'cái', label: 'cái' },
          { id: 'cặp', label: 'cặp' },
          { id: 'cụm', label: 'cụm' },
          { id: 'cuộn', label: 'cuộn' },
          { id: 'vòng', label: 'vòng' },
          { id: 'ống', label: 'ống' },
          { id: 'dây', label: 'dây' },
          { id: 'vòng', label: 'vòng' },
          { id: 'kg', label: 'kg' },
          { id: 'l', label: 'l' },
          { id: 'l (15°C)', label: 'l (15°C)' },
          { id: 'bình', label: 'bình' },
          { id: 'lọ', label: 'lọ' },
          { id: 'hộp', label: 'hộp' },
          { id: 'm', label: 'm' },
          { id: 'm2', label: 'm2' },
          { id: 'sợi', label: 'sợi' },
          { id: 'tấm', label: 'tấm' },
          { id: 'túi', label: 'túi' },
          { id: 'viên', label: 'viên' },
          { id: 'quyển', label: 'quyển' },
        ],
      });
    } catch {
      setErrorMessage('Mã vật tư không tồn tại!!!');
    }
    setLoading(false);
  };

  updateMaterial = async () => {
    this.setState({
      materialNameErrorMessage: '',
      unitErrorMessage: '',
      materialTypeIDErrorMessage: '',
    });
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    setErrorMessage('');

    const { id, materialID, materialName, unit, productCode, materialGroupID, materialTypeID, materialTypeName, specID, engineList } = this.state;
    let hasError = false;
    if (materialName.trim() === '') {
      hasError = true;
      this.setState({ materialNameErrorMessage: 'Tên vật tư không thể bỏ trống' });
    }
    if (unit.trim() === '') {
      hasError = true;
      this.setState({ unitErrorMessage: 'Đơn vị tính không thể bỏ trống' });
    }
    if (materialTypeID === '') {
      hasError = true;
      this.setState({ materialTypeIDErrorMessage: 'Kho vật tư không thể bỏ trống' });
    }
    if (hasError) {
      return;
    }
    setLoading(true);
    try {
      await updateMaterialInfo({
        id,
        materialID,
        materialName,
        unit,
        productCode,
        engineList,
        materialGroupID,
        materialTypeID,
        materialTypeName,
        specID,
        status: 'A',
      });
      setSubmitResult('Thông tin vật tư được cập nhật thành công!');
    } catch {
      setErrorMessage('Có lỗi trong khi cập nhật vật tư. Vui lòng kiểm tra lại.');
    }
    setLoading(false);
  };

  loadSpecStandardTable = async (specID) => {
    if (specID === '') {
      this.setState({
        specStandardList: [],
        specID,
      });
      return;
    }
    const { setLoading } = this.props;
    setLoading(true);
    const getSpecStandardLitResult = await getTechSpecStandards(specID);
    setLoading(false);
    this.setState({
      specStandardList: getSpecStandardLitResult.data,
      specID,
    });
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      materialID,
      materialName,
      materialNameErrorMessage,
      unit,
      unitErrorMessage,
      unitList,
      productCode,
      engineList,
      materialGroupID,
      materialTypeID,
      materialTypeIDErrorMessage,
      specID,
      techSpecList,
      specStandardList,
    } = this.state;

    return (
      <div className="material-update">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/material/list');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/material/list');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Cập nhật danh mục vật tư mới</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <TextInput id="materialID-TextInput" placeholder="Vui lòng nhập mã vật tư" labelText="Mã định danh vật tư" value={materialID} disabled />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />

            <div className="bx--col-lg-4">
              <TextInput
                id="materialName-TextInput"
                placeholder="Vui lòng nhập tên vật tư"
                labelText="Tên vật tư"
                value={materialName}
                onChange={(e) => this.setState({ materialName: e.target.value, materialNameErrorMessage: '' })}
                invalid={materialNameErrorMessage !== ''}
                invalidText={materialNameErrorMessage}
                disabled={auth.role !== 'phongkythuat' || auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <Dropdown
                id="materialType-Dropdown"
                titleText="Kho vật tư"
                label=""
                items={materialTypes}
                selectedItem={materialTypeID === '' ? null : materialTypes.find((e) => e.id === materialTypeID)}
                onChange={(e) => this.setState({ materialTypeID: e.selectedItem.id, materialTypeIDErrorMessage: '' })}
                invalid={materialTypeIDErrorMessage !== ''}
                invalidText={materialTypeIDErrorMessage}
                disabled={auth.role !== 'phongkythuat' || auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <Dropdown
                id="materialGroup-Dropdown"
                titleText="Nhóm vật tư"
                label=""
                items={materialGroups}
                selectedItem={materialGroupID === '' ? null : materialGroups.find((e) => e.id === materialGroupID)}
                onChange={(e) => this.setState({ materialGroupID: e.selectedItem.id })}
                disabled={auth.role !== 'phongkythuat' || auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <TextInput
                id="productCode-TextInput"
                placeholder="Vui lòng nhập mã nhà sản xuất"
                labelText="Mã nhà sản xuất"
                value={productCode}
                onChange={(e) => this.setState({ productCode: e.target.value })}
                disabled={auth.role !== 'phongkythuat' || auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <TextInput
                id="engineList-TextInput"
                placeholder="Vui lòng nhập các loại đầu máy có thể dùng vật tư này"
                helperText="Nếu để trống có nghĩa vật tư này được phép dùng chung cho tất cả các loại đầu máy"
                labelText="Đầu máy"
                value={engineList}
                onChange={(e) => this.setState({ engineList: e.target.value })}
                disabled={auth.role !== 'phongkythuat' || auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <Dropdown
                id="unit-Dropdown"
                titleText="Đơn vị tính"
                label=""
                items={unitList}
                selectedItem={unit === '' ? null : unitList.find((e) => e.id === unit)}
                onChange={(e) => this.setState({ unit: e.selectedItem.id, unitErrorMessage: '' })}
                invalid={unitErrorMessage !== ''}
                invalidText={unitErrorMessage}
                disabled={auth.role !== 'phongkythuat' || auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <ComboBox
                id="specID-ComboBox"
                titleText="Mã thông số kĩ thuật"
                label=""
                items={techSpecList}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                selectedItem={specID === '' ? null : techSpecList.find((e) => e.id === specID)}
                onChange={async (e) => this.loadSpecStandardTable(e.selectedItem == null ? '' : e.selectedItem.id)}
                disabled={auth.role !== 'phongkythuat' || auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-12">
              {specID && (
                <TableContainer title="Bảng thông số kĩ thuật tương ứng">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeader key="standardID">Mã tiêu chuẩn kĩ thuật</TableHeader>
                        <TableHeader key="standardName">Tên tiêu chuẩn kĩ thuật</TableHeader>
                        <TableHeader key="unit">Đơn vị đo</TableHeader>
                        <TableHeader key="minValue">Giá trị nhỏ nhất</TableHeader>
                        <TableHeader key="maxValue">Giá trị lớn nhất</TableHeader>
                        <TableHeader key="defaultValue">Giá trị mặc định</TableHeader>
                        <TableHeader key="value">Giá trị thực tế</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {specStandardList.map((specStandard, index) => (
                        <TableRow key={`row-${index.toString()}`}>
                          <TableCell>{specStandard.standardID}</TableCell>
                          <TableCell>{specStandard.standardName}</TableCell>
                          <TableCell>{specStandard.unit}</TableCell>
                          <TableCell>{specStandard.minValue}</TableCell>
                          <TableCell>{specStandard.maxValue}</TableCell>
                          <TableCell>{specStandard.defaultValue}</TableCell>
                          <TableCell>{specStandard.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <br />
          {auth.companyID === 'TCT_DS_VN' && auth.role === 'phongkythuat' && (
            <div className="bx--row">
              <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
              <div className="bx--col-lg-4">
                <Button onClick={() => this.updateMaterial()}>Cập nhật thông tin vật tư</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MaterialUpdate.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  common: PropTypes.shape({ submitResult: PropTypes.string, errorMessage: PropTypes.string, isLoading: PropTypes.bool }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialUpdate);
