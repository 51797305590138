import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComboBox,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setSubmitValue } from '../../actions/commonAction';
import { engineTypes } from '../../constants';
import { getCompanyList, getLatestEngineList, insertEngine, updateEngine } from '../../services';

const registryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const warningDate = new Date(new Date().setMonth(new Date().getMonth() + 1));

class Engine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engineList: [],
      engineListDisplay: [],
      page: 1,
      pageSize: 10,

      newEngineID: '',
      newEngineIDErrorMessage: '',
      newCompanyID: '',
      newCompanyIDErrorMessage: '',
      newEngineType: '',
      newEngineTypeErrorMessage: '',
      newRegistryDate: this.formatDate(registryDate),

      id: '',
      updatedEngineID: '',
      updatedCompanyID: '',
      updatedEngineType: '',
      updatedRegistryDate: '',

      companyList: [],
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth } = this.props;
    setLoading(true);
    const getCompanyListResult = await getCompanyList();
    const getEngineListResult = await getLatestEngineList();
    const engineList = auth.companyID === 'TCT_DS_VN' ? getEngineListResult.data : getEngineListResult.data.filter((e) => e.companyID === auth.companyID);
    setLoading(false);
    this.setState({
      companyList: getCompanyListResult.data.map((e) => {
        return { id: e.companyID, label: e.companyName };
      }),
      newCompanyID: auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID,
      engineList,
      engineListDisplay: engineList.slice(0, 10),
    });
  };

  reload = async () => {
    const { setLoading, setSubmitResult, auth } = this.props;
    setSubmitResult('');
    setLoading(true);
    const getEngineListResult = await getLatestEngineList();
    const engineList = auth.companyID === 'TCT_DS_VN' ? getEngineListResult.data : getEngineListResult.data.filter((e) => e.companyID === auth.companyID);
    setLoading(false);
    this.setState({
      engineList,
      engineListDisplay: engineList.slice(0, 10),
      page: 1,
      pageSize: 10,

      newEngineID: '',
      newEngineIDErrorMessage: '',
      newCompanyIDErrorMessage: '',
      newEngineType: '',
      newEngineTypeErrorMessage: '',
      newRegistryDate: '',

      updatedEngineID: '',
      updatedEngineType: '',
      updatedRegistryDate: '',
    });
  };

  addNewEngine = async () => {
    const { setLoading, setSubmitResult, setErrorMessage } = this.props;
    const { engineList, newEngineID, newCompanyID, newEngineType, newRegistryDate } = this.state;
    let hasError = false;
    if (newEngineID.trim() === '') {
      this.setState({ newEngineIDErrorMessage: 'Số hiệu đầu máy không được bỏ trống' });
      hasError = true;
    }
    if (newEngineID !== '' && engineList.find((e) => e.engineID === newEngineID) !== undefined) {
      this.setState({ newEngineIDErrorMessage: 'Số hiệu đầu máy đã tồn tại' });
      hasError = true;
    }
    if (newCompanyID.trim() === '') {
      this.setState({ newCompanyIDErrorMessage: 'Đơn vị quản lý không được bỏ trống' });
      hasError = true;
    }
    if (newEngineType.trim() === '') {
      this.setState({ newEngineTypeErrorMessage: 'Loại đầu máy không được bỏ trống' });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    setLoading(true);
    try {
      await insertEngine({
        engineID: newEngineID,
        companyID: newCompanyID,
        engineType: newEngineType,
        registryDate: newRegistryDate,
      });
      setSubmitResult('Đầu máy mới được thêm thành công!');
    } catch {
      setErrorMessage('Đầu máy mới đã tồn tại. Vui lòng thử lại.');
    }
    setLoading(false);
  };

  updateEngine = async () => {
    const { setLoading, setSubmitResult, setErrorMessage } = this.props;
    const { id, updatedEngineID, updatedCompanyID, updatedEngineType, updatedRegistryDate } = this.state;
    setLoading(true);
    try {
      await updateEngine({
        id,
        engineID: updatedEngineID,
        companyID: updatedCompanyID,
        engineType: updatedEngineType,
        registryDate: updatedRegistryDate,
      });
      setSubmitResult('Đầu máy được cập nhật thành công!');
    } catch {
      setErrorMessage('Có lỗi khi cập nhật đầu máy. Vui lòng kiểm tra lại.');
    }
    setLoading(false);
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  getDateFromString = (inputString) => {
    const inputParts = inputString.split('/');
    return new Date(inputParts[2], inputParts[1] - 1, inputParts[0]);
  };

  render() {
    // Props first
    const { setErrorMessage, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      engineList,
      engineListDisplay,
      page,
      pageSize,

      newEngineID,
      newCompanyID,
      newEngineType,
      newRegistryDate,
      newEngineIDErrorMessage,
      newCompanyIDErrorMessage,
      newEngineTypeErrorMessage,

      updatedEngineID,
      updatedCompanyID,
      updatedEngineType,
      updatedRegistryDate,

      companyList,
    } = this.state;

    const engineIDList = engineList.map((e) => {
      return { id: e.engineID, label: e.engineID };
    });

    return (
      <div className="engine">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal className="btn-success" open={submitResult !== ''} size="sm" onClose={() => this.reload()}>
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter onRequestSubmit={() => this.reload()} primaryButtonText="OK" secondaryButtonText="" />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Danh sách đầu máy</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-3 bx--col-md-3">
              <TextInput
                id="newEngineID-TextInput"
                placeholder=""
                labelText="Số hiệu đầu máy"
                value={newEngineID}
                onChange={(e) => this.setState({ newEngineID: e.target.value, newEngineIDErrorMessage: '' })}
                invalid={newEngineIDErrorMessage !== ''}
                invalidText={newEngineIDErrorMessage}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Dropdown
                id="newCompany-Dropdown"
                titleText="Đơn vị quản lý"
                label=""
                items={companyList}
                selectedItem={newCompanyID === '' ? null : companyList.find((e) => e.id === newCompanyID)}
                onChange={(e) => this.setState({ newCompanyID: e.selectedItem.id, newCompanyIDErrorMessage: '' })}
                invalid={newCompanyIDErrorMessage !== ''}
                invalidText={newCompanyIDErrorMessage}
                disabled={auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Dropdown
                id="newEngineType-Dropdown"
                titleText="Loại đầu máy"
                label=""
                items={engineTypes}
                selectedItem={newEngineType === '' ? null : engineTypes.find((e) => e.id === newEngineType)}
                onChange={(e) => this.setState({ newEngineType: e.selectedItem.id, newEngineTypeErrorMessage: '' })}
                invalid={newEngineTypeErrorMessage !== ''}
                invalidText={newEngineTypeErrorMessage}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => this.setState({ newRegistryDate: this.formatDate(e[0]) })}
                value={newRegistryDate}
              >
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Ngày hết hạn đăng kiểm" id="newRegistryDate-datepicker" />
              </DatePicker>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.addNewEngine()} style={{ marginTop: '1rem' }}>
                Thêm
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-3 bx--col-md-3">
              <ComboBox
                id="updatedEngineID-ComboBox"
                titleText="Số hiệu đầu máy"
                label=""
                items={engineIDList}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                selectedItem={updatedEngineID === '' ? null : engineIDList.find((e) => e.id === updatedEngineID)}
                onChange={(e) => {
                  if (e.selectedItem == null) {
                    this.setState({
                      id: '',
                      updatedEngineID: '',
                      updatedCompanyID: '',
                      updatedEngineType: '',
                      updatedRegistryDate: '',
                    });
                    return;
                  }
                  const selectedEngine = engineList.find((engine) => engine.engineID === e.selectedItem.id);
                  this.setState({
                    id: selectedEngine.id,
                    updatedEngineID: selectedEngine.engineID,
                    updatedCompanyID: selectedEngine.companyID,
                    updatedEngineType: selectedEngine.engineType,
                    updatedRegistryDate: selectedEngine.registryDate == null ? this.formatDate(registryDate) : selectedEngine.registryDate,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Dropdown
                id="updatedCompany-Dropdown"
                titleText="Đơn vị quản lý"
                label=""
                items={companyList}
                selectedItem={updatedCompanyID === '' ? null : companyList.find((e) => e.id === updatedCompanyID)}
                onChange={(e) => this.setState({ updatedCompanyID: e.selectedItem.id })}
                disabled={auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Dropdown
                id="updatedEngineType-Dropdown"
                titleText="Loại đầu máy"
                label=""
                items={engineTypes}
                selectedItem={updatedEngineType === '' ? null : engineTypes.find((e) => e.id === updatedEngineType)}
                onChange={(e) => this.setState({ updatedEngineType: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => this.setState({ updatedRegistryDate: this.formatDate(e[0]) })}
                value={updatedRegistryDate}
              >
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Ngày hết hạn đăng kiểm" id="updatedRegistryDate-datepicker" />
              </DatePicker>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.updateEngine()} style={{ marginTop: '1rem' }} disabled={updatedEngineID === ''}>
                Sửa
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Có tất cả ${engineList.length} đầu máy.`}>
                <Table style={{ maxHeigh: '70vh' }}>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="engineID">Số hiệu đầu máy</TableHeader>
                      <TableHeader key="companyName">Đơn vị quản lý</TableHeader>
                      <TableHeader key="engineType">Loại đầu máy</TableHeader>
                      <TableHeader key="registryDate">Ngày hết hạn đăng kiểm</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {engineListDisplay.map((engine, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`engineID-${index.toString()}`}>{engine.engineID}</TableCell>
                        <TableCell key={`companyName-${index.toString()}`}>{companyList.find((e) => e.id === engine.companyID).label}</TableCell>
                        <TableCell key={`engineType-${index.toString()}`}>{engine.engineType}</TableCell>
                        <TableCell key={`registryDate-${index.toString()}`}>
                          {engine.registryDate !== null && (
                            <p style={{ color: this.getDateFromString(engine.registryDate).getTime() < warningDate.getTime() ? '#da1e28' : '#12e65c' }}>
                              {engine.registryDate}
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[10, 20, 30, 40, 50]}
                totalItems={engineList.length}
                onChange={(target) => {
                  this.setState({
                    engineListDisplay: engineList.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

Engine.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  common: PropTypes.shape({ submitResult: PropTypes.string, errorMessage: PropTypes.string, isLoading: PropTypes.bool }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Engine);
