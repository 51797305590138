import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComboBox,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setSubmitValue } from '../../actions/commonAction';
import {
  exportFuelInRecipe,
  exportFuelTestRecipe,
  getAccountTitleList,
  getCategoryList,
  getEngineListByCompany,
  getFuelOrder,
  getSupplierList,
  getUserList,
  updateFuelOrder,
} from '../../services';

const fuelMaterialList = [{ id: '000050001', label: 'Dầu Diesel' }];

class FuelInOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuelOrderInfo: {},
      orderNoteErrorMessage: '',
      approverErrorMessage: '',
      approverList: [],
      supplierErrorMessage: '',
      engineIDErrorMessage: '',
      supplierList: [],
      categoryList: [],
      engineList: [],
      accountList: [],
      creatorList: [],
    };
  }

  componentDidMount = async () => {
    const { setErrorMessage, setLoading, location, auth } = this.props;
    const params = new URLSearchParams(location.search);
    if (params == null) {
      setErrorMessage('Không có mã yêu cầu nhập kho nhiên liệu!!!');
      return;
    }
    const orderID = params.get('id');

    setLoading(true);
    try {
      const getFuelInOrderInfoResult = await getFuelOrder(orderID);
      if (getFuelInOrderInfoResult.data.requestor !== auth.userID && getFuelInOrderInfoResult.data.approver !== auth.userID) {
        setErrorMessage('Bạn không có quyền truy cập');
        setLoading(false);
        return;
      }

      const getApproverListResult = await getUserList('', '', auth.companyID, 'phongketoantaichinh');
      const getCreatorListResult = await getUserList('', '', auth.companyID, 'phongkehoachvattu');
      const getSupplierListResult = await getSupplierList();
      const getCategoryListResult = await getCategoryList();
      const getEngineListResult = await getEngineListByCompany(auth.companyID);
      const getAccountListResult = await getAccountTitleList();

      this.setState({
        engineList: getEngineListResult.data.map((e) => {
          return { id: e.engineID, label: e.engineID };
        }),
        supplierList: getSupplierListResult.data
          .sort((a, b) => a.supplierName.localeCompare(b.supplierName))
          .map((e) => {
            return { id: e.supplierID, label: e.supplierID.concat(' - ').concat(e.supplierName) };
          }),
        approverList: getApproverListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        creatorList: getCreatorListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        categoryList: [
          { id: '', label: '' },
          ...getCategoryListResult.data.map((e) => {
            return { id: e.categoryID, label: e.categoryName };
          }),
        ],
        accountList: [
          { id: '', label: '' },
          ...getAccountListResult.data.map((e) => {
            return { id: e.accountID, label: e.accountTitle.concat(' - ').concat(e.accountName) };
          }),
        ],
        fuelOrderInfo: getFuelInOrderInfoResult.data,
      });
    } catch {
      setErrorMessage('Không thể tải trang. Vui lòng thử lại.');
    }
    setLoading(false);
  };

  updateFuelOrder = async () => {
    const { fuelOrderInfo } = this.state;
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    setLoading(true);
    const getFuelInOrderInfoResult = await getFuelOrder(fuelOrderInfo.id);
    if (JSON.stringify(fuelOrderInfo) === JSON.stringify(getFuelInOrderInfoResult.data)) {
      setErrorMessage('Đơn nhập nhiên liệu chưa bị thay đổi. Vui lòng cập nhật đơn.');
      return;
    }
    try {
      await updateFuelOrder(fuelOrderInfo);
    } catch {
      setLoading(false);
      setErrorMessage('Có lỗi khi cập nhật đơn nhập nhiên liệu');
      return;
    }
    setLoading(false);
    setSubmitResult('Đơn nhập nhiên liệu được cập nhật thành công');
  };

  cancelFuelOrder = async () => {
    const { fuelOrderInfo } = this.state;
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    fuelOrderInfo.status = 'cancelled';
    setLoading(true);
    try {
      await updateFuelOrder(fuelOrderInfo);
    } catch {
      setLoading(false);
      setErrorMessage('Có lỗi khi huỷ đơn nhập nhiên liệu');
      return;
    }
    setLoading(false);
    setSubmitResult('Đơn nhập nhiên liệu bị huỷ thành công');
  };

  approveFuelOrder = async () => {
    const { fuelOrderInfo } = this.state;
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    fuelOrderInfo.status = 'completed';
    fuelOrderInfo.approveDate = this.formatDate(new Date());
    setLoading(true);
    try {
      await updateFuelOrder(fuelOrderInfo);
    } catch {
      setLoading(false);
      setErrorMessage('Có lỗi khi phê duyệt đơn nhập nhiên liệu');
      return;
    }
    setLoading(false);
    setSubmitResult('Đơn nhập nhiên liệu được phê duyệt thành công');
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  getStatus = () => {
    const { fuelOrderInfo } = this.state;
    if (fuelOrderInfo.status === 'cancelled') {
      return 'Bị huỷ';
    }
    if (fuelOrderInfo.status === 'created') {
      return 'Chờ phê duyệt';
    }
    if (fuelOrderInfo.status === 'completed') {
      return 'Đã được phê duyệt';
    }
    return '';
  };

  downloadFuelTestRecipe = async () => {
    const { setErrorMessage } = this.props;
    const { fuelOrderInfo } = this.state;
    await exportFuelTestRecipe(fuelOrderInfo.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bien_ban_kiem_nghiem_dau.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  downloadFuelInRecipe = async () => {
    const { setErrorMessage } = this.props;
    const { fuelOrderInfo } = this.state;
    await exportFuelInRecipe(fuelOrderInfo.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Phieu_nhap_kho.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const { supplierErrorMessage, supplierList, orderNoteErrorMessage, engineList, engineIDErrorMessage, creatorList } = this.state;
    const { fuelOrderInfo, approverErrorMessage, approverList, categoryList, accountList } = this.state;

    const fuelStockInReasonList = [
      { id: 'Nhập mới', label: 'Nhập mới' },
      { id: 'Nhập xả máy các cấp sửa chữa', label: 'Nhập xả máy các cấp sửa chữa' },
      { id: 'Nhập nhiên liệu xuất chạy tàu chưa hết', label: 'Nhập nhiên liệu xuất chạy tàu chưa hết' },
      { id: 'Nhập vay từ đơn vị khác', label: 'Nhập vay từ đơn vị khác' },
    ];

    return (
      <div className="fuel-report">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Yêu cầu nhập nhiên liệu</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Dropdown
                id="fuelMaterialID-Dropdown"
                titleText="Loại nhiên liệu"
                label=""
                items={fuelMaterialList}
                selectedItem={fuelOrderInfo.materialID === '' ? null : fuelMaterialList.find((e) => e.id === fuelOrderInfo.materialID)}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, materialID: e.selectedItem.id } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="reason-Dropdown"
                titleText="Lý do"
                label=""
                items={fuelStockInReasonList}
                selectedItem={fuelOrderInfo.orderNote === '' ? null : fuelStockInReasonList.find((e) => e.id === fuelOrderInfo.orderNote)}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    fuelOrderInfo: { ...prevState.fuelOrderInfo, orderNote: e.selectedItem.id, supplier: '' },
                    orderNoteErrorMessage: '',
                  }))
                }
                invalid={orderNoteErrorMessage !== ''}
                invalidText={orderNoteErrorMessage}
                disabled
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="attachedDocument-TextInput"
                placeholder=""
                labelText="Số chứng từ gốc kèm theo"
                value={fuelOrderInfo.attachedDocument}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, attachedDocument: e.target.value } }))}
                disabled
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Dropdown
                id="engineID-Dropdown"
                titleText="Đầu máy xả"
                label=""
                items={engineList}
                onChange={(e) =>
                  this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, supplier: e.selectedItem.id }, engineIDErrorMessage: '' }))
                }
                selectedItem={
                  // eslint-disable-next-line no-nested-ternary
                  fuelOrderInfo.orderNote === 'Nhập xả máy các cấp sửa chữa' || fuelOrderInfo.orderNote === 'Nhập nhiên liệu xuất chạy tàu chưa hết'
                    ? fuelOrderInfo.supplier === ''
                      ? null
                      : engineList.find((e) => e.id === fuelOrderInfo.supplier)
                    : null
                }
                invalid={engineIDErrorMessage !== ''}
                invalidText={engineIDErrorMessage}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="supplier-ComboBox"
                titleText="Đơn vị cung cấp"
                placeholder=""
                label=""
                items={supplierList}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    fuelOrderInfo: { ...prevState.fuelOrderInfo, supplier: e.selectedItem == null ? '' : e.selectedItem.id },
                    supplierErrorMessage: '',
                  }))
                }
                selectedItem={fuelOrderInfo.supplier === '' ? null : supplierList.find((e) => e.id === fuelOrderInfo.supplier)}
                invalid={supplierErrorMessage !== ''}
                invalidText={supplierErrorMessage}
                disabled
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="orderNo-TextInput"
                placeholder=""
                labelText="Số phiếu nhập"
                value={fuelOrderInfo.orderNo}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, orderNo: e.target.value } }))}
                disabled
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="recipeNo-TextInput"
                placeholder=""
                labelText="Số hoá đơn"
                value={fuelOrderInfo.recipeNo}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, recipeNo: e.target.value } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, recipeDate: this.formatDate(e[0]) } }))}
                value={fuelOrderInfo.recipeDate}
              >
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Ngày trên hoá đơn" id="recipeDate-datepicker" disabled />
              </DatePicker>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="deliver-TextInput"
                placeholder=""
                labelText="Người giao hàng"
                value={fuelOrderInfo.deliver}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, deliver: e.target.value } }))}
                disabled
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="stockName-TextInput"
                placeholder=""
                labelText="Nhập vào kho"
                value={fuelOrderInfo.stockName}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, stockName: e.target.value } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="address-TextInput"
                placeholder=""
                labelText="Địa chỉ kho"
                value={fuelOrderInfo.address}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, address: e.target.value } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="real-amount-TextInput"
                placeholder="Vui lòng nhập số lượng"
                labelText="Số lượng (lít thực tế)"
                value={fuelOrderInfo.realFuelQuantity}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, realFuelQuantity: e.target.value } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput
                id="standard-amount-TextInput"
                placeholder="Vui lòng nhập số lượng"
                labelText="Số lượng (lít tại 15°C)"
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, standardFuelQuantity: e.target.value } }))}
                value={fuelOrderInfo.standardFuelQuantity}
                disabled
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-3 bx--col-md-3">
              <ComboBox
                id="no-ComboBox"
                titleText="Nợ"
                placeholder=""
                label=""
                items={categoryList}
                selectedItem={fuelOrderInfo.no === '' ? null : categoryList.find((e) => e.id === fuelOrderInfo.no)}
                onChange={(e) =>
                  this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, no: e.selectedItem == null ? '' : e.selectedItem.id } }))
                }
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                disabled
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <ComboBox
                id="co-ComboBox"
                titleText="Có"
                placeholder=""
                label=""
                items={categoryList}
                selectedItem={fuelOrderInfo.co === '' ? null : categoryList.find((e) => e.id === fuelOrderInfo.co)}
                onChange={(e) =>
                  this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, co: e.selectedItem == null ? '' : e.selectedItem.id } }))
                }
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                disabled
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <ComboBox
                id="category-ComboBox"
                titleText="Khoản mục"
                placeholder=""
                label=""
                items={accountList}
                selectedItem={fuelOrderInfo.category === '' ? null : accountList.find((e) => e.id === fuelOrderInfo.category)}
                onChange={(e) =>
                  this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, category: e.selectedItem == null ? '' : e.selectedItem.id } }))
                }
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                disabled
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              {fuelOrderInfo.requestor === auth.userID && (
                <Dropdown
                  id="approver-Dropdown"
                  titleText="Người phê duyệt"
                  label=""
                  items={approverList}
                  selectedItem={fuelOrderInfo.approver === '' ? null : approverList.find((e) => e.id === fuelOrderInfo.approver)}
                  onChange={(e) =>
                    this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, approver: e.selectedItem.id }, approverErrorMessage: '' }))
                  }
                  invalid={approverErrorMessage !== ''}
                  invalidText={approverErrorMessage}
                  disabled
                />
              )}
              {fuelOrderInfo.approver === auth.userID && (
                <Dropdown
                  id="creator-Dropdown"
                  titleText="Người tạo đơn"
                  label=""
                  items={creatorList}
                  selectedItem={fuelOrderInfo.requestor === '' ? null : creatorList.find((e) => e.id === fuelOrderInfo.requestor)}
                  onChange={(e) =>
                    this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, requestor: e.selectedItem.id }, approverErrorMessage: '' }))
                  }
                  invalid={approverErrorMessage !== ''}
                  invalidText={approverErrorMessage}
                  disabled
                />
              )}
            </div>
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, requestDate: this.formatDate(e[0]) } }))}
                value={fuelOrderInfo.requestDate}
              >
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Ngày tạo yêu cầu" id="requestDate-datepicker" disabled />
              </DatePicker>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <TextInput id="status-TextInput" placeholder="" labelText="Trạng thái đơn nhiên liệu" value={this.getStatus()} disabled />
            </div>
          </div>
          <br />
          {fuelOrderInfo.status === 'completed' && (
            <div className="bx--row">
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.downloadFuelInRecipe()} style={{ marginTop: '1rem' }}>
                  Tải phiếu nhập kho
                </Button>
              </div>
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.downloadFuelTestRecipe()} style={{ marginTop: '1rem' }}>
                  Tải biên bản nghiệm thu
                </Button>
              </div>
            </div>
          )}
          {fuelOrderInfo.status === 'created' && fuelOrderInfo.requestor === auth.userID && (
            <div className="bx--row">
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.cancelFuelOrder()} style={{ marginTop: '1rem' }}>
                  Huỷ đơn
                </Button>
              </div>
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.updateFuelOrder()} style={{ marginTop: '1rem' }}>
                  Cập nhật
                </Button>
              </div>
            </div>
          )}
          {fuelOrderInfo.status === 'created' && fuelOrderInfo.approver === auth.userID && (
            <div className="bx--row">
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.cancelFuelOrder()} style={{ marginTop: '1rem' }}>
                  Huỷ đơn
                </Button>
              </div>
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.approveFuelOrder()} style={{ marginTop: '1rem' }}>
                  Phê duyệt
                </Button>
              </div>
            </div>
          )}
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4" />
            <div className="bx--col-lg-12">
              <TableContainer title="BIÊN BẢN NGHIỆM THU NHIÊN LIỆU">
                <Table>
                  <TableBody>
                    <TableRow key="nguoi1">
                      <TableCell>Trưởng ban nghiệm thu</TableCell>
                      <TableCell>
                        <TextInput
                          id="nguoi1-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.nguoi1}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, nguoi1: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>
                        <TextInput
                          id="chucvu1-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.chucvu1}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, chucvu1: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Đại diện</TableCell>
                      <TableCell>
                        <TextInput
                          id="daidien1-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.daidien1}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, daidien1: e.target.value } }))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="nguoi2">
                      <TableCell>Uỷ viên thứ nhất</TableCell>
                      <TableCell>
                        <TextInput
                          id="nguoi2-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.nguoi2}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, nguoi2: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>
                        <TextInput
                          id="chucvu2-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.chucvu2}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, chucvu2: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Đại diện</TableCell>
                      <TableCell>
                        <TextInput
                          id="daidien2-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.daidien2}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, daidien2: e.target.value } }))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="nguoi3">
                      <TableCell>Uỷ viên thứ hai</TableCell>
                      <TableCell>
                        <TextInput
                          id="nguoi3-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.nguoi3}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, nguoi3: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>
                        <TextInput
                          id="chucvu3-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.chucvu3}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, chucvu3: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Đại diện</TableCell>
                      <TableCell>
                        <TextInput
                          id="daidien3-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.daidien3}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, daidien3: e.target.value } }))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="nguoi4">
                      <TableCell>Uỷ viên thứ ba</TableCell>
                      <TableCell>
                        <TextInput
                          id="nguoi4-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.nguoi4}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, nguoi4: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>
                        <TextInput
                          id="chucvu4-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.chucvu4}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, chucvu4: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Đại diện</TableCell>
                      <TableCell>
                        <TextInput
                          id="daidien4-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.daidien4}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, daidien4: e.target.value } }))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="nguoi5">
                      <TableCell>Uỷ viên thứ tư</TableCell>
                      <TableCell>
                        <TextInput
                          id="nguoi5-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.nguoi5}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, nguoi5: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>
                        <TextInput
                          id="chucvu5-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.chucvu5}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, chucvu5: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Đại diện</TableCell>
                      <TableCell>
                        <TextInput
                          id="daidien5-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.daidien5}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, daidien5: e.target.value } }))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="nguoi6">
                      <TableCell>Uỷ viên thứ năm</TableCell>
                      <TableCell>
                        <TextInput
                          id="nguoi6-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.nguoi6}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, nguoi6: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>
                        <TextInput
                          id="chucvu6-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.chucvu6}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, chucvu6: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Đại diện</TableCell>
                      <TableCell>
                        <TextInput
                          id="daidien6-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.daidien6}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, daidien6: e.target.value } }))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="nguoi7">
                      <TableCell>Uỷ viên thứ sáu</TableCell>
                      <TableCell>
                        <TextInput
                          id="nguoi7-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.nguoi7}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, nguoi7: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>
                        <TextInput
                          id="chucvu7-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.chucvu7}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, chucvu7: e.target.value } }))}
                        />
                      </TableCell>
                      <TableCell>Đại diện</TableCell>
                      <TableCell>
                        <TextInput
                          id="daidien7-TextInput"
                          placeholder=""
                          labelText=""
                          value={fuelOrderInfo.daidien7}
                          onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, daidien7: e.target.value } }))}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <br />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4" />
            <div className="bx--col-lg-12">
              Căn cứ các hoá đơn mua nhiên liệu, các loại phương tiện toa xe P chuyên dùng.
              <br />
              <br />
              <strong> Phương thức kiểm nghiệm:</strong> Cùng nhau kiểm tra kẹp chì niêm phong, đo chiều cao nhiên liệu thực tế, đo nhiệt độ thực tế của dầu, đo
              tỷ trọng nhiên liệu, đo kiểm tra nước lẫn trong nhiên liệu bằng thuốc thử nước, sử dụng bảng VCF tiêu chuẩn, tính toán và chuyển đổi số liệu.
              <br />
              <br />
              Ban nghiệm thu đã nhất trí ghi vào biên bản các số liệu cụ thể sau đây: ( Đã loại bỏ lượng nước lẫn dầu nếu có)
            </div>
          </div>
          <br />
          <br />
        </div>

        <TableContainer>
          <Table>
            <TableHead style={{ border: '1px solid' }}>
              <TableRow key="header1">
                <TableHeader rowSpan={3} style={{ border: '1px solid', width: '7.5%' }}>
                  Số hiệu xe téc
                </TableHeader>
                <TableHeader rowSpan={3} style={{ border: '1px solid', width: '5%' }}>
                  Số phiếu nhập
                </TableHeader>
                <TableHeader colSpan={2} style={{ border: '1px solid', width: '15%' }}>
                  Nhiệt độ dầu (°C)
                </TableHeader>
                <TableHeader colSpan={2} style={{ border: '1px solid', width: '15%' }}>
                  Tỉ trọng thực tế
                </TableHeader>
                <TableHeader colSpan={2} style={{ border: '1px solid', width: '15%' }}>
                  VCF tiêu chuẩn
                </TableHeader>
                <TableHeader colSpan={4} style={{ border: '1px solid', width: '30%' }}>
                  Khối lượng
                </TableHeader>
                <TableHeader colSpan={2} style={{ border: '1px solid', width: '12.5%' }}>
                  Kết luận ở 15°C
                </TableHeader>
              </TableRow>
              <TableRow key="header2">
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '7.5%' }}>
                  PNK
                </TableHeader>
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '7.5%' }}>
                  Thực tế
                </TableHeader>
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '7.5%' }}>
                  PNK
                </TableHeader>
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '7.5%' }}>
                  Thực tế
                </TableHeader>
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '7.5%' }}>
                  PNK
                </TableHeader>
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '7.5%' }}>
                  Thực tế
                </TableHeader>
                <TableHeader colSpan={2} style={{ border: '1px solid', width: '15%' }}>
                  L thực tế
                </TableHeader>
                <TableHeader colSpan={2} style={{ border: '1px solid', width: '15%' }}>
                  L 15°C
                </TableHeader>
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '7.5%' }}>
                  Số lượng thừa(+)
                  <br />
                  Số lượng thiếu(-)
                </TableHeader>
                <TableHeader rowSpan={2} style={{ border: '1px solid', width: '5%' }}>
                  Chất lượng
                </TableHeader>
              </TableRow>
              <TableRow key="header3">
                <TableHeader style={{ border: '1px solid' }}>PNK</TableHeader>
                <TableHeader style={{ border: '1px solid' }}>Thực tế</TableHeader>
                <TableHeader style={{ border: '1px solid' }}>PNK</TableHeader>
                <TableHeader style={{ border: '1px solid' }}>Thực tế</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="row">
                <TableCell>
                  <TextInput
                    id="bienso-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.bienso}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, bienso: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>{fuelOrderInfo.orderNo}</TableCell>
                <TableCell>
                  <TextInput
                    id="standardTemp-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.standardTemp}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, standardTemp: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="realTemp-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.realTemp}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, realTemp: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="standardDensity-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.standardDensity}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, standardDensity: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="realDensity-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.realDensity}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, realDensity: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="standardVCF-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.standardVCF}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, standardVCF: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="realVCF-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.realVCF}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, realVCF: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="standardVolume-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.standardVolume}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, standardVolume: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="realVolume-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.realVolume}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, realVolume: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="standardVolumeAt15-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.standardVolumeAt15}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, standardVolumeAt15: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="realVolumeAt15-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.realVolumeAt15}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, realVolumeAt15: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="lag-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.lag}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, lag: e.target.value } }))}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    id="quality-TextInput"
                    placeholder=""
                    labelText=""
                    value={fuelOrderInfo.quality}
                    onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, quality: e.target.value } }))}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <br />
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, testDate: this.formatDate(e[0]) } }))}
                value={fuelOrderInfo.testDate}
              >
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Ngày nghiệm thu" id="testDate-datepicker" />
              </DatePicker>
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="comment-TextInput"
                placeholder=""
                labelText="Ý kiến ban kiểm nghiệm"
                value={fuelOrderInfo.comment}
                onChange={(e) => this.setState((prevState) => ({ fuelOrderInfo: { ...prevState.fuelOrderInfo, comment: e.target.value } }))}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FuelInOrderDetail.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  common: PropTypes.shape({ submitResult: PropTypes.string, errorMessage: PropTypes.string, isLoading: PropTypes.bool }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FuelInOrderDetail);
