import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComboBox,
  ComposedModal,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NumberInput,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { exportStoreTag, exportTagList, getCompanyList, getStockHistory, getStockList } from '../../services';

class StoreTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagList: [],
      tagListDisplay: [],
      page: 1,
      pageSize: 10,

      materialID: '',
      materialList: [],
      companyID: '',
      companyIDErrorMessage: '',
      year: '',
      yearErrorMessage: '',

      companyList: [],
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, common, setMaterialList } = this.props;
    setLoading(true);
    let { materialList } = common;
    if (materialList.length === 0) {
      const getMaterialListResult = await getStockList(auth.companyID);
      materialList = getMaterialListResult.data;
      setMaterialList(materialList);
    }
    const getCompanyListResult = await getCompanyList();
    setLoading(false);
    this.setState({
      materialList,
      companyList: getCompanyListResult.data.map((e) => {
        return { id: e.companyID, label: e.companyName };
      }),
      companyID: auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID,
      year: new Date().getFullYear(),
    });
  };

  getTagList = async () => {
    const { setLoading, setErrorMessage, common } = this.props;
    const { companyID, year } = this.state;
    if (companyID.trim() === '') {
      this.setState({ companyIDErrorMessage: 'Đơn vị quản lý không được bỏ trống' });
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    if (year === '' || isNaN(year) || year > new Date().getFullYear()) {
      this.setState({ yearErrorMessage: 'Năm không hợp lệ' });
      return;
    }
    setLoading(true);
    const { materialList } = common;
    try {
      const getTagListResult = await getStockHistory(companyID, year);
      const tagList = getTagListResult.data.map((e) => {
        const selectedMaterial = materialList.find((material) => material.materialID === e.materialID);
        if (selectedMaterial !== undefined) {
          e.materialName = selectedMaterial.materialName;
        } else {
          e.materialName = '';
        }
        return e;
      });
      const tagListDisplay = tagList.slice(0, 10);
      this.setState({
        tagList,
        tagListDisplay,
      });
    } catch {
      setErrorMessage('Không tìm thấy dữ liệu. Vui lòng thử lại.');
    }
    setLoading(false);
  };

  downloadTagList = async () => {
    const { setErrorMessage } = this.props;
    const { companyID, year } = this.state;
    await exportTagList(companyID, year)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Danh_sach_the_kho.zip');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  downloadStoreTag = async () => {
    const { setErrorMessage } = this.props;
    const { materialID, companyID, year } = this.state;
    if (materialID === '') {
      setErrorMessage('Vui lòng điền mã vật tư và thử lại');
      return;
    }
    await exportStoreTag(companyID, year, materialID)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `the_kho_${materialID}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  getDateFromString = (inputString) => {
    const inputParts = inputString.split('/');
    return new Date(inputParts[2], inputParts[1] - 1, inputParts[0]);
  };

  render() {
    // Props first
    const { setErrorMessage, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      page,
      pageSize,

      tagList,
      tagListDisplay,

      materialList,
      materialID,

      companyID,
      companyIDErrorMessage,
      year,
      yearErrorMessage,

      companyList,
    } = this.state;

    const materialIDList = materialList.map((e) => {
      return { id: e.materialID, label: e.materialID.concat(' - ').concat(e.materialName) };
    });

    return (
      <div className="engine">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal className="btn-success" open={submitResult !== ''} size="sm" onClose={() => this.reload()}>
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter onRequestSubmit={() => this.reload()} primaryButtonText="OK" secondaryButtonText="" />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Thẻ kho</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-3 bx--col-md-3">
              <Dropdown
                id="company-Dropdown"
                titleText="Đơn vị quản lý"
                label=""
                items={companyList}
                selectedItem={companyID === '' ? null : companyList.find((e) => e.id === companyID)}
                onChange={(e) => this.setState({ companyID: e.selectedItem.id, companyIDErrorMessage: '' })}
                invalid={companyIDErrorMessage !== ''}
                invalidText={companyIDErrorMessage}
                disabled={auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <NumberInput
                helperText=""
                id="year-NumberInput"
                invalidText={yearErrorMessage}
                label="Năm"
                max={9999}
                min={2022}
                size="lg"
                value={year}
                onChange={(e) => this.setState({ year: e.imaginaryTarget.value })}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="materialID-ComboBox"
                titleText="Mã vật tư"
                label=""
                items={materialIDList}
                selectedItem={materialID === '' ? null : materialIDList.find((e) => e.id === materialID)}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                onChange={(e) => this.setState({ materialID: e.selectedItem == null ? '' : e.selectedItem.id })}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.getTagList()} style={{ marginTop: '1rem' }}>
                Tìm kiếm
              </Button>
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Button onClick={() => this.downloadTagList()} style={{ marginTop: '1rem' }}>
                Tải thẻ kho toàn bộ
              </Button>
            </div>
            <div className="bx--col-lg-4">
              <Button onClick={() => this.downloadStoreTag()} style={{ marginTop: '1rem' }}>
                Tải thẻ kho theo mã
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Có tất cả ${tagList.length} danh mục kho.`}>
                <Table style={{ maxHeigh: '70vh' }}>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="stt">STT</TableHeader>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="exeDate">Ngày thực hiện</TableHeader>
                      <TableHeader key="orderNo">Số hoá đơn</TableHeader>
                      <TableHeader key="description">Diễn giải</TableHeader>
                      <TableHeader key="inQuantity">Nhập</TableHeader>
                      <TableHeader key="outQuantity">Xuất</TableHeader>
                      <TableHeader key="remainQuantity">Tồn</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tagListDisplay.map((stockHistory, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`stt-${index.toString()}`}>{index + 1}</TableCell>
                        <TableCell key={`materialID-${index.toString()}`}>{stockHistory.materialID}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>{stockHistory.materialName}</TableCell>
                        <TableCell key={`exeDate-${index.toString()}`}>{stockHistory.exeDate}</TableCell>
                        <TableCell key={`orderNo-${index.toString()}`}>{stockHistory.orderNo}</TableCell>
                        <TableCell key={`description-${index.toString()}`}>{stockHistory.description}</TableCell>
                        <TableCell key={`inQuantity-${index.toString()}`}>{stockHistory.inQuantity}</TableCell>
                        <TableCell key={`outQuantity-${index.toString()}`}>{stockHistory.outQuantity}</TableCell>
                        <TableCell key={`remainQuantity-${index.toString()}`}>{stockHistory.remainQuantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[10, 20, 30, 40, 50]}
                totalItems={tagList.length}
                onChange={(target) => {
                  this.setState({
                    tagListDisplay: tagList.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

StoreTag.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,

  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTag);
