import {
  Content,
  Header,
  HeaderContainer,
  HeaderName,
  SideNav,
  SideNavDivider,
  SideNavItems,
  SideNavMenu,
  SideNavMenuItem,
  SkipToContent,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import './App.scss';
import { removeCurrentLoggedInUser, setCurrentLoggedInUser } from './actions/authAction';
import { assignErrorMessage, setLoadingValue } from './actions/commonAction';
import { ACCESS_TOKEN } from './constants';
import Home from './pages/Home';
import Login from './pages/Login';
import CompanyAdd from './pages/company/CompanyAdd';
import CompanyList from './pages/company/CompanyList';
import CompanyUpdate from './pages/company/CompanyUpdate';
import Engine from './pages/engine/Engine';
import EngineAnalysis from './pages/engine/EngineAnalysis';
import EngineAnalysisList from './pages/engine/EngineAnalysisList';
import FuelInOrder from './pages/fuel/FuelInOrder';
import FuelInOrderDetail from './pages/fuel/FuelInOrderDetail';
import FuelOutOrder from './pages/fuel/FuelOutOrder';
import FuelOutOrderDetail from './pages/fuel/FuelOutOrderDetail';
import FuelReport from './pages/fuel/FuelReport';
import MaterialAdd from './pages/material/MaterialAdd';
import MaterialList from './pages/material/MaterialList';
import MaterialUpdate from './pages/material/MaterialUpdate';
import OrderList from './pages/order/OrderList';
import PriceAdjust from './pages/order/PriceAdjust';
import StockInOrder from './pages/order/StockInOrder';
import StockInOrderApprove from './pages/order/StockInOrderApprove';
import StockInOrderDetail from './pages/order/StockInOrderDetail';
import StockInOrderTest from './pages/order/StockInOrderTest';
import StockOutOrder from './pages/order/StockOutOrder';
import StockOutOrderApprove from './pages/order/StockOutOrderApprove';
import StockOutOrderDetail from './pages/order/StockOutOrderDetail';
import StockOutOrderTest from './pages/order/StockOutOrderTest';
import StoreTag from './pages/others/StoreTag';
import CreateNewPlan from './pages/plan/CreateNewPlan';
import PlanDetail from './pages/plan/PlanDetail';
import PlanList from './pages/plan/PlanList';
import DistributeReport from './pages/report/DistributeReport';
import OrderReport from './pages/report/OrderReport';
import StockInOrderReport from './pages/report/StockInOrderReport';
import StockOutOrderReport from './pages/report/StockOutOrderReport';
import StockReport from './pages/report/StockReport';
import Scrap from './pages/scrap/Scrap';
import ScrapPriceAdjust from './pages/scrap/ScrapPriceAdjust';
import StockUpdate from './pages/stock/StockUpdate';
import Supplier from './pages/supplier/Supplier';
import TechSpecAdd from './pages/tech/TechSpecAdd';
import TechSpecList from './pages/tech/TechSpecList';
import TechSpecUpdate from './pages/tech/TechSpecUpdate';
import TechStandard from './pages/tech/TechStandard';
import UserAdd from './pages/user/UserAdd';
import UserList from './pages/user/UserList';
import UserUpdate from './pages/user/UserUpdate';
import VCFAdjust from './pages/vcf/VCFAdjust';
import { getUserInfoByToken, signout } from './services';
import InventoryReportList from './pages/report/InventoryReportList';
import InventoryReport from './pages/report/InventoryReport';

class App extends Component {
  componentDidMount = async () => {
    const { removeUserInfo, history, setLoading, setErrorMessage, setUserInfo } = this.props;
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token == null) {
      removeUserInfo();
      history.push('/login');
      return;
    }
    setLoading(true);
    try {
      const getUserInfoByTokenResult = await getUserInfoByToken(token);
      setUserInfo(getUserInfoByTokenResult.data);
    } catch (error) {
      removeUserInfo();
      history.push('/login');
      setErrorMessage('Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại.');
    }
    setLoading(false);
  };

  logout = async () => {
    const { removeUserInfo, auth, history } = this.props;
    const { userID } = auth;
    const token = localStorage.getItem(ACCESS_TOKEN);
    removeUserInfo();
    await signout(userID, token);
    history.push('/home');
  };

  render() {
    const { auth } = this.props;
    const { isAuthenticated, isActive, companyName, role, username, roleName, userID } = auth;

    return (
      <div>
        <HeaderContainer
          render={() => (
            <Header aria-label="Carbon Tutorial">
              <SkipToContent />
              <HeaderName element={Link} to="/" prefix="">
                {companyName}
              </HeaderName>
              <HeaderName element={Link} to="/" prefix="">
                <span className="bx--header-margin-right" style={isAuthenticated ? { minWidth: '15rem' } : { minWidth: '30rem' }} />
                <h4>CỔNG THÔNG TIN QUẢN LÝ PHỤ TÙNG VẬT TƯ TỔNG CÔNG TY ĐSVN</h4>
              </HeaderName>
              {isAuthenticated && isActive && (
                <SideNav aria-label="Side navigation" expanded isPersistent>
                  <SideNavItems>
                    {(auth.role === 'SysAdmin' || auth.role === 'CompanyAdmin') && (
                      <SideNavMenu title="Quản lý chung" isActive>
                        <SideNavMenuItem element={Link} to="/user/list" disabled={role !== 'SysAdmin' && role !== 'CompanyAdmin'}>
                          Quản lý nhân sự
                        </SideNavMenuItem>
                        {auth.role === 'SysAdmin' && (
                          <SideNavMenuItem element={Link} to="/company/list" disabled={role !== 'SysAdmin'}>
                            Quản lý đơn vị
                          </SideNavMenuItem>
                        )}
                      </SideNavMenu>
                    )}
                    {(auth.role === 'SysAdmin' || auth.role === 'CompanyAdmin') && <SideNavDivider />}
                    <SideNavMenu title="Quản lý kĩ thuật" isActive>
                      <SideNavMenuItem element={Link} to="/tech/standard">
                        Danh mục tiêu chuẩn kĩ thuật
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/tech/spec/list">
                        Danh mục thông số kĩ thuật
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/material/list">
                        Danh mục vật tư
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/engine">
                        Danh mục đầu máy
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/supplier">
                        Danh mục nhà cung cấp
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/scrap">
                        Danh mục phế liệu
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/scrap-price">
                        Định giá phế liệu
                      </SideNavMenuItem>
                    </SideNavMenu>
                    <SideNavDivider />
                    <SideNavMenu title="Quản lý vật tư" isActive>
                      {role === 'phongkehoachvattu' && (
                        <SideNavMenuItem element={Link} to="/order/stock-in">
                          Yêu cầu nhập kho
                        </SideNavMenuItem>
                      )}
                      {(role === 'phongkehoachvattu' || role === 'xuongsx') && (
                        <SideNavMenuItem element={Link} to="/order/stock-out">
                          Yêu cầu xuất kho
                        </SideNavMenuItem>
                      )}
                      {role === 'phongkehoachvattu' && (
                        <SideNavMenuItem element={Link} to="/plan/new">
                          Tạo kế hoạch vật tư
                        </SideNavMenuItem>
                      )}
                      <SideNavMenuItem element={Link} to="/order/list">
                        Danh sách yêu cầu
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/stock/update">
                        Cập nhật kho đầu kì
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/order/price/adjust">
                        Cập nhật giá tháng trước
                      </SideNavMenuItem>
                    </SideNavMenu>
                    <SideNavDivider />
                    <SideNavMenu title="Quản lý nhiêu liệu" isActive hidden>
                      <SideNavMenuItem element={Link} to="/vcf">
                        Điều chỉnh VCF
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/fuel">
                        Báo cáo nhiên liệu
                      </SideNavMenuItem>
                      {role === 'phongkehoachvattu' && (
                        <SideNavMenuItem element={Link} to="/fuel/in">
                          Nhập nhiên liệu
                        </SideNavMenuItem>
                      )}
                      {role === 'phongkehoachvattu' && (
                        <SideNavMenuItem element={Link} to="/fuel/out">
                          Xuất nhiên liệu
                        </SideNavMenuItem>
                      )}
                    </SideNavMenu>
                    <SideNavDivider />
                    <SideNavMenu title="Báo cáo" isActive>
                      <SideNavMenuItem element={Link} to="/report/stock">
                        Báo cáo tồn kho
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/report/order">
                        Báo cáo xuất nhập kho tổng hợp
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/report/order/stock-in">
                        Báo cáo nhập kho
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/report/order/stock-out">
                        Báo cáo xuất kho
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/store/tags">
                        Thẻ kho
                      </SideNavMenuItem>
                      {(role === 'phongkehoachvattu' || role === 'phongketoantaichinh' || role === 'bangiamdoc') && (
                        <SideNavMenuItem element={Link} to="/plan/list">
                          Danh sách kế hoạch vật tư
                        </SideNavMenuItem>
                      )}
                      <SideNavMenuItem element={Link} to="/report/distribute">
                        Bảng phân bổ vật tư
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/inventory/report/list">
                        Báo cáo kiểm kê hàng hoá
                      </SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/engine/analysis/list">
                        Biên bản giải thể đầu máy
                      </SideNavMenuItem>
                    </SideNavMenu>
                    <SideNavDivider />
                    <SideNavMenu title="Thông tin người dùng">
                      <SideNavMenuItem>Tên đăng nhập: {userID}</SideNavMenuItem>
                      <SideNavMenuItem>Tên đầy đủ: {username}</SideNavMenuItem>
                      <SideNavMenuItem>Vị trí: {roleName}</SideNavMenuItem>
                      <SideNavMenuItem element={Link} to="/login" onClick={async () => this.logout()}>
                        Đăng xuất
                      </SideNavMenuItem>
                    </SideNavMenu>
                  </SideNavItems>
                </SideNav>
              )}
            </Header>
          )}
        />
        <Content className={`${isAuthenticated ? 'bx--side-nav__navigation_margin' : ''}`}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/login" component={Login} />

            <Route exact path="/user/list" component={isAuthenticated && (role === 'SysAdmin' || role === 'CompanyAdmin') ? UserList : Home} />
            <Route exact path="/user/add" component={isAuthenticated && (role === 'SysAdmin' || role === 'CompanyAdmin') ? UserAdd : Home} />
            <Route exact path="/user/update" component={isAuthenticated ? UserUpdate : Login} />

            <Route exact path="/company/list" component={isAuthenticated && role === 'SysAdmin' ? CompanyList : Home} />
            <Route exact path="/company/add" component={isAuthenticated && role === 'SysAdmin' ? CompanyAdd : Home} />
            <Route exact path="/company/update" component={isAuthenticated && role === 'SysAdmin' ? CompanyUpdate : Home} />

            <Route exact path="/engine" component={isAuthenticated ? Engine : Login} />
            <Route exact path="/engine/analysis/list" component={isAuthenticated ? EngineAnalysisList : Home} />
            <Route exact path="/engine/analysis" component={isAuthenticated ? EngineAnalysis : Home} />

            <Route exact path="/supplier" component={isAuthenticated ? Supplier : Login} />

            <Route exact path="/tech/standard" component={isAuthenticated ? TechStandard : Home} />
            <Route exact path="/tech/spec/list" component={isAuthenticated ? TechSpecList : Home} />
            <Route exact path="/tech/spec/add" component={isAuthenticated ? TechSpecAdd : Home} />
            <Route exact path="/tech/spec/update" component={isAuthenticated ? TechSpecUpdate : Home} />

            <Route exact path="/material/list" component={isAuthenticated ? MaterialList : Home} />
            <Route
              exact
              path="/material/add"
              component={isAuthenticated && auth.companyID === 'TCT_DS_VN' && auth.role === 'phongkythuat' ? MaterialAdd : Home}
            />
            <Route exact path="/material/update" component={isAuthenticated ? MaterialUpdate : Home} />

            <Route exact path="/scrap" component={isAuthenticated ? Scrap : Login} />
            <Route exact path="/scrap-price" component={isAuthenticated ? ScrapPriceAdjust : Login} />

            <Route exact path="/order/stock-in" component={isAuthenticated ? StockInOrder : Home} />
            <Route exact path="/order/stock-in/test" component={isAuthenticated ? StockInOrderTest : Home} />
            <Route exact path="/order/stock-in/approve" component={isAuthenticated ? StockInOrderApprove : Home} />
            <Route exact path="/order/stock-in/detail" component={isAuthenticated ? StockInOrderDetail : Home} />

            <Route exact path="/order/stock-out" component={isAuthenticated ? StockOutOrder : Home} />
            <Route exact path="/order/stock-out/test" component={isAuthenticated ? StockOutOrderTest : Home} />
            <Route exact path="/order/stock-out/approve" component={isAuthenticated ? StockOutOrderApprove : Home} />
            <Route exact path="/order/stock-out/detail" component={isAuthenticated ? StockOutOrderDetail : Home} />
            <Route exact path="/order/list" component={isAuthenticated ? OrderList : Home} />

            <Route exact path="/report/stock" component={isAuthenticated ? StockReport : Home} />
            <Route exact path="/report/order" component={isAuthenticated ? OrderReport : Home} />
            <Route exact path="/report/order/stock-in" component={isAuthenticated ? StockInOrderReport : Home} />
            <Route exact path="/report/order/stock-out" component={isAuthenticated ? StockOutOrderReport : Home} />
            <Route exact path="/store/tags" component={isAuthenticated ? StoreTag : Home} />

            <Route exact path="/vcf" component={isAuthenticated ? VCFAdjust : Home} />
            <Route exact path="/fuel" component={isAuthenticated ? FuelReport : Home} />
            <Route exact path="/fuel/in" component={isAuthenticated && role === 'phongkehoachvattu' ? FuelInOrder : Home} />
            <Route exact path="/fuel/in/detail" component={isAuthenticated ? FuelInOrderDetail : Home} />
            <Route exact path="/fuel/out" component={isAuthenticated && role === 'phongkehoachvattu' ? FuelOutOrder : Home} />
            <Route exact path="/fuel/out/detail" component={isAuthenticated ? FuelOutOrderDetail : Home} />

            <Route exact path="/stock/update" component={isAuthenticated ? StockUpdate : Home} />

            <Route exact path="/order/price/adjust" component={isAuthenticated && role === 'phongketoantaichinh' ? PriceAdjust : Home} />

            <Route exact path="/plan/new" component={isAuthenticated ? CreateNewPlan : Home} />
            <Route exact path="/plan/list" component={isAuthenticated ? PlanList : Home} />
            <Route exact path="/plan/detail" component={isAuthenticated ? PlanDetail : Home} />

            <Route exact path="/report/distribute" component={isAuthenticated ? DistributeReport : Home} />
            <Route exact path="/inventory/report/list" component={isAuthenticated ? InventoryReportList : Home} />
            <Route exact path="/inventory/report" component={isAuthenticated ? InventoryReport : Home} />
          </Switch>
        </Content>
      </div>
    );
  }
}

App.propTypes = {
  setUserInfo: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  removeUserInfo: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  common: PropTypes.shape({ errorMessage: PropTypes.string }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (userInfo) => dispatch(setCurrentLoggedInUser(userInfo)),
  removeUserInfo: () => dispatch(removeCurrentLoggedInUser()),
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
