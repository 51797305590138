import { CloudUpload32 } from '@carbon/icons-react';
import {
  Accordion,
  AccordionItem,
  Button,
  ComboBox,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { materialTypes, CurrencyFormatter, parseLocaleNumber, repairLevelList } from '../../constants';
import {
  addOrder,
  getAccountTitleList,
  getCategoryList,
  getEngineListByCompany,
  getOtherConsumerList,
  getStockList,
  getTestStockOutUsers,
  getApproveStockOutUsers,
} from '../../services';

class StockOutOrder extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;
    this.state = {
      orderInfo: {
        orderName: '',
        orderType: 'O',
        status: 'created',
        requestor: auth.userID,
        requestNote: '',
        requestDate: this.formatDate(new Date()),
        tester: '',
        testNote: '',
        approver: '',
        approveNote: '',

        consumer: '',
        repairGroup: '',
        repairLevel: '',

        no: '',
        co: '',
        attachedDocument: '',
        stockName: '',
        address: '',
        category: '',
        deliver: '',

        companyID: auth.companyID,
      },
      orderNameErrorMessage: '',
      requestNoteErrorMessage: '',
      testerErrorMessage: '',
      testerList: [],
      approverErrorMessage: '',
      approverList: [],
      orderDetailList: [],
      quantityErrorMessages: [],
      amountErrorMessages: [],
      categoryList: [],
      accountList: [],
      engineList: [],
      otherConsumerList: [],
      engineID: '',
      otherConsumer: '',

      materialList: [],
      searchResult: [],
      materialListDisplay: [],
      page: 1,
      pageSize: 5,
      filterMaterialID: '',
      filterProductCode: '',
      filterMaterialName: '',
      filterMaterialType: '',
      requestDateErrorMessage: '',
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, setErrorMessage, common, setMaterialList } = this.props;
    setLoading(true);
    try {
      let { materialList } = common;
      if (materialList.length === 0) {
        const getMaterialListResult = await getStockList(auth.companyID);
        materialList = getMaterialListResult.data;
        setMaterialList(materialList);
      }
      const getTesterListResult = await getTestStockOutUsers(auth.companyID, auth.userID);
      const getApproverListResult = await getApproveStockOutUsers(auth.companyID, auth.userID);
      const getCategoryListResult = await getCategoryList();
      const getOtherConsumerListResult = await getOtherConsumerList();
      const getEngineListResult = await getEngineListByCompany(auth.companyID);
      const getAccountListResult = await getAccountTitleList();

      this.setState({
        testerList: getTesterListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        approverList: getApproverListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        materialList,
        searchResult: materialList,
        materialListDisplay: materialList.slice(0, 5),
        categoryList: [
          { id: '', label: '' },
          ...getCategoryListResult.data.map((e) => {
            return { id: e.categoryID, label: e.categoryID.concat(' - ').concat(e.categoryName) };
          }),
        ],
        accountList: [
          { id: '', label: '' },
          ...getAccountListResult.data.map((e) => {
            return { id: e.accountID, label: e.accountTitle.concat(' - ').concat(e.accountName) };
          }),
        ],
        otherConsumerList: [
          { id: '', label: '' },
          ...getOtherConsumerListResult.data.map((e) => {
            return { id: e.consumerID, label: e.consumerName };
          }),
        ],
        engineList: [
          ...getEngineListResult.data.map((e) => {
            return { id: e.engineID, label: e.engineID };
          }),
          { id: 'other', label: 'Khác' },
        ],
      });
    } catch {
      setErrorMessage('Không thể tải trang. Vui lòng thử lại.');
    }
    setLoading(false);
  };

  save = async () => {
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    const { orderInfo, orderDetailList, quantityErrorMessages, amountErrorMessages, engineID, otherConsumer, materialList } = this.state;

    this.setState({
      orderNameErrorMessage: '',
      requestNoteErrorMessage: '',
      testerErrorMessage: '',
      approverErrorMessage: '',
      quantityErrorMessages: Array(orderDetailList.length).fill('', 0, orderDetailList.length),
      amountErrorMessages: Array(orderDetailList.length).fill('', 0, orderDetailList.length),
    });
    setErrorMessage('');

    let hasError = false;
    if (orderInfo.orderName.trim() === '') {
      hasError = true;
      this.setState({ orderNameErrorMessage: 'Tên yêu cầu không thể bỏ trống' });
    }
    if (orderInfo.requestNote.trim() === '') {
      hasError = true;
      this.setState({ requestNoteErrorMessage: 'Lý do không thể bỏ trống' });
    }
    if (orderInfo.tester === '') {
      hasError = true;
      this.setState({ testerErrorMessage: 'Lãnh đạo phê duyệt không thể bỏ trống' });
    }
    if (orderInfo.approver === '') {
      hasError = true;
      this.setState({ approverErrorMessage: 'Phòng Kế toán Tài chính phê duyệt không thể bỏ trống' });
    }

    if (orderInfo.requestDate === '') {
      hasError = true;
      this.setState({ requestDateErrorMessage: 'Ngày tạo đơn không được bỏ trống' });
    }
    const requestDateParts = orderInfo.requestDate.split('/');
    if (requestDateParts.length !== 3) {
      hasError = true;
      this.setState({ requestDateErrorMessage: 'Ngày tạo đơn không đúng định dạng' });
    }
    const requestDate = new Date(requestDateParts[2], requestDateParts[1] - 1, requestDateParts[0]);
    const currentDate = new Date();
    if (requestDate > currentDate) {
      hasError = true;
      this.setState({ requestDateErrorMessage: 'Ngày tạo đơn không được vượt ngày hiện tại' });
    }

    if (requestDateParts[1] - 1 !== currentDate.getMonth()) {
      hasError = true;
      this.setState({ requestDateErrorMessage: 'Ngày tạo đơn phải ở trong kỳ (tháng)' });
    }

    if (orderDetailList.length === 0) {
      hasError = true;
      setErrorMessage('Mỗi yêu cầu cần ít nhất 1 danh mục vật tư');
    }

    orderDetailList.forEach((e, index) => {
      if (e.requestQuantity === '') {
        hasError = true;
        quantityErrorMessages[index] = 'Cần nhập vào số lượng';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.requestQuantity !== '' && isNaN(e.requestQuantity)) || Number(e.requestQuantity) < 0) {
        hasError = true;
        quantityErrorMessages[index] = 'Số lượng không hợp lệ';
      }
      const material = materialList.find((item) => item.materialID === e.materialID);
      if (Number(e.requestQuantity) > material.quantity) {
        hasError = true;
        quantityErrorMessages[index] = 'Số lượng vượt quá lượng tồn trong kho';
      }
      if (material.minimumQuantity != null && material.quantity - Number(e.requestQuantity) < material.minimumQuantity) {
        hasError = true;
        quantityErrorMessages[index] = 'Số lượng xuất vượt quá lượng tồn tối thiểu';
      }
      // eslint-disable-next-line no-restricted-globals
      if (e.requestAmount !== '' && isNaN(e.requestAmount)) {
        hasError = true;
        amountErrorMessages[index] = 'Thành tiền không đúng định dạng';
      }
    });
    this.setState({ quantityErrorMessages, amountErrorMessages });

    if (
      orderDetailList.length > 0 &&
      new Set(
        orderDetailList.map((e) => {
          return e.materialID;
        })
      ).size !== orderDetailList.length
    ) {
      hasError = true;
      setErrorMessage('Có mã vật tư bị trùng. Vui lòng kiểm tra lại');
    }
    if (hasError) {
      return;
    }

    if (engineID !== 'other') {
      orderInfo.consumer = engineID;
    } else {
      orderInfo.consumer = otherConsumer;
    }
    setLoading(true);
    try {
      await addOrder({ orderInfo, orderDetailList });
    } catch {
      setErrorMessage('Có lỗi khi thêm yêu cầu. Vui lòng thử lại.');
    }
    setSubmitResult('Yêu cầu được thêm thành công');
    setLoading(false);
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  findMaterial = () => {
    const { filterMaterialID, filterProductCode, filterMaterialName, filterMaterialType, pageSize, materialList } = this.state;
    let searchResult = JSON.parse(JSON.stringify(materialList));
    if (filterMaterialID !== '') {
      searchResult = searchResult.filter((e) => e.materialID.includes(filterMaterialID));
    }
    if (filterMaterialName !== '') {
      searchResult = searchResult.filter((e) => e.materialName.toUpperCase().includes(filterMaterialName.toUpperCase()));
    }
    if (filterProductCode !== '') {
      searchResult = searchResult.filter((e) => e.productCode.toUpperCase().includes(filterProductCode.toUpperCase()));
    }
    if (filterMaterialType !== '') {
      searchResult = searchResult.filter((e) => e.materialTypeID === filterMaterialType);
    }
    this.setState({
      searchResult,
      materialListDisplay: searchResult.slice(0, pageSize),
    });
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      orderInfo,
      orderNameErrorMessage,
      requestNoteErrorMessage,
      testerList,
      approverList,
      orderDetailList,
      testerErrorMessage,
      approverErrorMessage,
      quantityErrorMessages,
      amountErrorMessages,
      categoryList,
      accountList,
      filterMaterialID,
      filterProductCode,
      filterMaterialName,
      filterMaterialType,
      materialListDisplay,
      searchResult,
      page,
      pageSize,
      engineList,
      otherConsumerList,
      engineID,
      otherConsumer,
      requestDateErrorMessage,
      materialList,
    } = this.state;

    const repairGroupList = [
      { id: 'Tổ Điện', label: 'Tổ Điện' },
      { id: 'Tổ Khung Gầm', label: 'Tổ Khung Gầm' },
      { id: 'Tổ Động cơ', label: 'Tổ Động cơ' },
      { id: 'Tổ Hãm', label: 'Tổ Hãm' },
      { id: 'Tổ Cơ khí', label: 'Tổ Cơ khí' },
      { id: 'Tổ Truyền động', label: 'Tổ Truyền động' },
      { id: 'Tổ Lâm tu ắc quy', label: 'Tổ Lâm tu ắc quy' },
    ];

    const qualityList = [
      { id: 'I', label: 'Loại I' },
      { id: 'II', label: 'Loại II' },
      { id: 'Phế liệu', label: 'Phế liệu' },
    ];

    const scrapList = materialList
      .filter((e) => e.materialTypeID === '1527')
      .map((e) => {
        return e.materialID;
      });

    return (
      <div className="stock-out">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Yêu cầu xuất kho</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="orderName-TextInput"
                placeholder="Vui lòng nhập tên yêu cầu"
                labelText="Tên yêu cầu"
                value={orderInfo.orderName}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, orderName: e.target.value }, orderNameErrorMessage: '' }))}
                invalid={orderNameErrorMessage !== ''}
                invalidText={orderNameErrorMessage}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="requestNote-TextInput"
                placeholder="Vui lòng nhập lý do lập bảng"
                labelText="Lý do"
                value={orderInfo.requestNote}
                onChange={(e) =>
                  this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, requestNote: e.target.value }, requestNoteErrorMessage: '' }))
                }
                invalid={requestNoteErrorMessage !== ''}
                invalidText={requestNoteErrorMessage}
              />
            </div>
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) =>
                  this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, requestDate: this.formatDate(e[0]) }, requestDateErrorMessage: '' }))
                }
                value={orderInfo.requestDate}
              >
                <DatePickerInput
                  datePickerType="single"
                  placeholder="dd/mm/yyyy"
                  labelText="Ngày tạo yêu cầu"
                  id="requestDate-datepicker"
                  invalid={requestDateErrorMessage !== ''}
                  invalidText={requestDateErrorMessage}
                />
              </DatePicker>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="tester-Dropdown"
                titleText="Phòng Kỹ thuật/Lãnh đạo phê duyệt cấp 1"
                label=""
                items={testerList}
                selectedItem={orderInfo.tester === '' ? null : testerList.find((e) => e.id === orderInfo.tester)}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, tester: e.selectedItem.id }, testerErrorMessage: '' }))}
                invalid={testerErrorMessage !== ''}
                invalidText={testerErrorMessage}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="approver-Dropdown"
                titleText="Phòng Kế toán Tài chính/Lãnh đạo phê duyệt cấp 2"
                label=""
                items={approverList}
                selectedItem={orderInfo.approver === '' ? null : approverList.find((e) => e.id === orderInfo.approver)}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, approver: e.selectedItem.id }, approverErrorMessage: '' }))}
                invalid={approverErrorMessage !== ''}
                invalidText={approverErrorMessage}
              />
            </div>
            <div className="bx--col-lg-4">
              <strong>Lưu ý:</strong>
              <br />
              <br /> - Với đơn xuất có giá trị &gt; 50M cần có PGĐ phê duyệt cấp 2
              <br />
              <br /> - Với đơn xuất có giá trị &gt; 100M cần có GĐ phê duyệt cấp 2
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="stockName-TextInput"
                placeholder=""
                labelText="Xuất tại kho (ngăn lô)"
                value={orderInfo.stockName}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, stockName: e.target.value } }))}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="address-TextInput"
                placeholder=""
                labelText="Địa chỉ kho"
                value={orderInfo.address}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, address: e.target.value } }))}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="attachedDocument-TextInput"
                placeholder=""
                labelText="Số chứng từ gốc kèm theo"
                value={orderInfo.attachedDocument}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, attachedDocument: e.target.value } }))}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="repairLevel-Dropdown"
                titleText="Cấp sửa chữa"
                label=""
                items={repairLevelList}
                selectedItem={orderInfo.repairLevel === '' ? null : repairLevelList.find((e) => e.id === orderInfo.repairLevel)}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, repairLevel: e.selectedItem.id } }))}
                disabled={engineID === 'other'}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="repairGroup-Dropdown"
                titleText="Tổ sửa chữa"
                label=""
                items={repairGroupList}
                selectedItem={orderInfo.repairGroup === '' ? null : repairGroupList.find((e) => e.id === orderInfo.repairGroup)}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, repairGroup: e.selectedItem.id } }))}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="deliver-TextInput"
                placeholder=""
                labelText="Địa chỉ (bộ phận)"
                value={orderInfo.deliver}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, deliver: e.target.value } }))}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <ComboBox
                id="no-ComboBox"
                titleText="Nợ"
                placeholder=""
                label=""
                items={categoryList}
                selectedItem={orderInfo.no === '' ? null : categoryList.find((e) => e.id === orderInfo.no)}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, no: e.selectedItem == null ? '' : e.selectedItem.id } }))}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="co-ComboBox"
                titleText="Có"
                placeholder=""
                label=""
                items={categoryList}
                selectedItem={orderInfo.co === '' ? null : categoryList.find((e) => e.id === orderInfo.co)}
                onChange={(e) => this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, co: e.selectedItem == null ? '' : e.selectedItem.id } }))}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="category-ComboBox"
                titleText="Khoản mục"
                placeholder=""
                label=""
                items={accountList}
                selectedItem={orderInfo.category === '' ? null : accountList.find((e) => e.id === orderInfo.category)}
                onChange={(e) =>
                  this.setState((prevState) => ({ orderInfo: { ...prevState.orderInfo, category: e.selectedItem == null ? '' : e.selectedItem.id } }))
                }
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="engineID-Dropdown"
                titleText="Đầu máy"
                label=""
                items={engineList}
                selectedItem={engineID === '' ? null : engineList.find((e) => e.id === engineID)}
                onChange={(e) => this.setState({ engineID: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="otherConsumer-ComboBox"
                titleText="Đối tượng chi phí khác"
                placeholder=""
                label=""
                items={otherConsumerList}
                selectedItem={otherConsumer === '' ? null : otherConsumerList.find((e) => e.id === otherConsumer)}
                onChange={(e) => this.setState({ otherConsumer: e.selectedItem == null ? '' : e.selectedItem.id })}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                disabled={engineID !== 'other'}
              />
            </div>
            <div className="bx--col-lg-4">
              <Button onClick={() => this.save()} style={{ marginTop: '1rem' }}>
                Lưu thông tin
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
        </div>
        <div className="bx--grid">
          <Accordion>
            <AccordionItem title={<strong>Tìm kiếm nhanh vật tư</strong>}>
              <div className="bx--row">
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterMaterialID-TextInput"
                    placeholder="Vui lòng nhập một phần mã vật tư để tìm kiếm"
                    labelText="Mã vật tư"
                    value={filterMaterialID}
                    onChange={(e) => this.setState({ filterMaterialID: e.target.value })}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterProductCode-TextInput"
                    placeholder="Vui lòng nhập một phần mã nhà sản xuất để tìm kiếm"
                    labelText="Mã nhà sản xuất"
                    value={filterProductCode}
                    onChange={(e) => this.setState({ filterProductCode: e.target.value })}
                  />
                </div>
                <div className="bx--col-lg-3 bx--col-md-3">
                  <Button onClick={() => this.findMaterial()} style={{ marginTop: '1rem' }}>
                    Tìm
                  </Button>
                </div>
              </div>
              <br />
              <div className="bx--row">
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterMaterialName-TextInput"
                    placeholder="Vui lòng nhập một phần tên vật tư để tìm kiếm"
                    labelText="Tên vật tư"
                    value={filterMaterialName}
                    onChange={(e) => this.setState({ filterMaterialName: e.target.value })}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
                <div className="bx--col-lg-4">
                  <Dropdown
                    id="filterMaterialType-Dropdown"
                    titleText="Kho vật tư (tài khoản kho)"
                    label=""
                    items={materialTypes}
                    selectedItem={filterMaterialType === '' ? null : materialTypes.find((e) => e.id === filterMaterialType)}
                    onChange={(e) => this.setState({ filterMaterialType: e.selectedItem.id })}
                  />
                </div>
                <div className="bx--col-lg-3 bx--col-md-3">
                  <Button
                    style={{ marginTop: '1rem' }}
                    onClick={() => this.setState({ filterMaterialID: '', filterProductCode: '', filterMaterialName: '', filterMaterialType: '' })}
                  >
                    Xoá bộ lọc
                  </Button>
                </div>
              </div>
              <br />
              <hr className="LeftNav-module--divider--1Z49I" />
              <div className="bx--row">
                <div className="bx--col-lg-2 bx--col-md-2" />
                <div className="bx--col-lg-12">
                  <TableContainer title={`Kết quả tìm kiếm cho ra ${searchResult.length} mục vật tư tương ứng.`}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeader key="materialID">Mã vật tư</TableHeader>
                          <TableHeader key="productCode">Mã nhà sản xuất</TableHeader>
                          <TableHeader key="materialName">Tên vật tư</TableHeader>
                          <TableHeader key="engineList">Đầu máy</TableHeader>
                          <TableHeader key="unit">Đơn vị tính</TableHeader>
                          <TableHeader key="minimumQuantity">Lượng tồn tối thiểu</TableHeader>
                          <TableHeader key="quantity">Lượng tồn trong kho</TableHeader>
                          <TableHeader />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {materialListDisplay.map((material, index) => (
                          <TableRow key={`row-${index.toString()}}`}>
                            <TableCell key={`materialID-${index.toString()}`}>{material.materialID}</TableCell>
                            <TableCell key={`productCode-${index.toString()}`}>{material.productCode}</TableCell>
                            <TableCell key={`materialName-${index.toString()}`}>{material.materialName}</TableCell>
                            <TableCell key={`engineList-${index.toString()}`}>{material.engineList}</TableCell>
                            <TableCell key={`unit-${index.toString()}`}>{material.unit}</TableCell>
                            <TableCell key={`minimumQuantity-${index.toString()}`}>{material.minimumQuantity}</TableCell>
                            <TableCell key={`quantity-${index.toString()}`}>{material.quantity}</TableCell>
                            <TableCell>
                              <Button
                                disabled={orderDetailList.find((e) => e.materialID === material.materialID)}
                                onClick={() => {
                                  orderDetailList.push({
                                    materialID: material.materialID,
                                    materialName: material.materialName,
                                    productCode: material.productCode,
                                    unit: material.unit,
                                    quality: 'I',
                                    requestQuantity: '',
                                    requestAmount: '',
                                  });
                                  quantityErrorMessages.push('');
                                  amountErrorMessages.push('');
                                  this.setState({ orderDetailList, quantityErrorMessages, amountErrorMessages });
                                }}
                              >
                                Thêm
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    className="fixed-pagination"
                    backwardText="Previous page"
                    forwardText="Next page"
                    itemsPerPageText="Items per page:"
                    page={page}
                    pageNumberText="Page Number"
                    pageSize={pageSize}
                    pageSizes={[5, 10, 15]}
                    totalItems={searchResult.length}
                    onChange={(target) => {
                      this.setState({
                        materialListDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                        page: target.page,
                        pageSize: target.pageSize,
                      });
                    }}
                  />
                </div>
              </div>
              <br />
            </AccordionItem>
          </Accordion>
        </div>
        <br />
        <br />
        <div className="bx--grid">
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer
                title="Chi tiết danh mục xuất kho"
                description="(*) Thành tiền không bắt buộc phải nhập. Nếu nhập thành tiền thì sẽ được coi là đơn giá thủ công"
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="stt">STT</TableHeader>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="productCode">Mã nhà sản xuất</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit">Đơn vị</TableHeader>
                      <TableHeader key="quality" style={{ width: '10%' }}>
                        Phân loại
                      </TableHeader>
                      <TableHeader key="quantity">Số lượng</TableHeader>
                      <TableHeader key="amount">Thành tiền</TableHeader>
                      <TableHeader />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetailList.map((row, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`stt-${index.toString()}`}>{index + 1}</TableCell>
                        <TableCell key={`materialID-${index.toString()}`}>{orderDetailList[index].materialID}</TableCell>
                        <TableCell key={`productCode-${index.toString()}`}>{orderDetailList[index].productCode}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>{orderDetailList[index].materialName}</TableCell>
                        <TableCell key={`unit-${index.toString()}`}>{orderDetailList[index].unit}</TableCell>
                        <TableCell key={`quality-${index.toString()}`}>
                          <Dropdown
                            id={`quality-Dropdown-${index}`}
                            titleText=""
                            label=""
                            items={qualityList}
                            selectedItem={orderDetailList[index].quality === '' ? null : qualityList.find((e) => e.id === orderDetailList[index].quality)}
                            onChange={(e) => {
                              orderDetailList[index].quality = e.selectedItem.id;
                              this.setState({ orderDetailList });
                            }}
                            disabled={!scrapList.includes(orderDetailList[index].materialID)}
                          />
                        </TableCell>
                        <TableCell key={`quantity-${index.toString()}`}>
                          <TextInput
                            id={`quantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              orderDetailList[index].requestQuantity = e.target.value;
                              quantityErrorMessages[index] = '';
                              this.setState({ orderDetailList, quantityErrorMessages });
                            }}
                            value={orderDetailList[index].requestQuantity}
                            invalid={quantityErrorMessages[index] !== ''}
                            invalidText={quantityErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`amount-${index.toString()}`}>
                          <TextInput
                            id={`amount-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                              orderDetailList[index].requestAmount = parseLocaleNumber(e.target.value);
                              amountErrorMessages[index] = '';
                              this.setState({ orderDetailList, amountErrorMessages });
                            }}
                            value={orderDetailList[index].requestAmount ? CurrencyFormatter.format(orderDetailList[index].requestAmount) : ''}
                            invalid={amountErrorMessages[index] !== ''}
                            invalidText={amountErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`remove-button-${index.toString()}`}>
                          <Button
                            onClick={() => {
                              this.setState({
                                orderDetailList: orderDetailList.filter((e) => e.materialID !== row.materialID),
                                quantityErrorMessages: Array(orderDetailList.length).fill('', 0, orderDetailList.length),
                                amountErrorMessages: Array(orderDetailList.length).fill('', 0, orderDetailList.length),
                              });
                            }}
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
        </div>
      </div>
    );
  }
}

StockOutOrder.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockOutOrder);
