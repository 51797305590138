import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NumberInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextArea,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CurrencyFormatter, parseLocaleNumber } from '../../constants';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { exportPlan, getPlan, getStockList, getUserList, updatePlan } from '../../services';

class PlanDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planInfo: {},
      planDetailList: [],
      materialList: [],
      userList: [],

      incomeQuantityErrorMessages: [],
      requestQuantityErrorMessages: [],
      requestAmountErrorMessages: [],
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, common, location, setMaterialList, setErrorMessage } = this.props;
    const params = new URLSearchParams(location.search);
    if (params == null) {
      setErrorMessage('Không có mã kế hoạch!!!');
      return;
    }
    const planID = params.get('planID');

    setLoading(true);
    try {
      let { materialList } = common;
      if (materialList.length === 0) {
        const getMaterialListResult = await getStockList(auth.companyID);
        materialList = getMaterialListResult.data;
        setMaterialList(materialList);
      }
      const getPlanResult = await getPlan(planID);
      const { planInfo } = getPlanResult.data;
      const { planDetailList } = getPlanResult.data;
      if (
        auth.companyID !== 'TCT_DS_VN' &&
        (auth.companyID !== planInfo.companyID ||
          (auth.userID !== planInfo.requestor && auth.userID !== planInfo.firstApprover && auth.userID !== planInfo.secondApprover))
      ) {
        setErrorMessage('Không có quyền truy cập');
        setLoading(false);
        return;
      }
      const getUserListResult = await getUserList();
      if (auth.userID === planInfo.firstApprover) {
        planInfo.firstApproveDate = this.formatDate(new Date());
      }
      if (auth.userID === planInfo.secondApprover) {
        planInfo.secondApproveDate = this.formatDate(new Date());
      }
      this.setState({
        planInfo,
        planDetailList,
        materialList,
        userList: getUserListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        incomeQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
        requestQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
        requestAmountErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      });
    } catch {
      setErrorMessage('Có lỗi khi tải trang!!!');
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  downloadPlan = async () => {
    const { setErrorMessage } = this.props;
    const { planInfo } = this.state;
    await exportPlan(planInfo.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Phu_luc_ke_hoach.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  approvePlan = async () => {
    const { setErrorMessage, setLoading, setSubmitResult, auth } = this.props;
    const { planInfo, planDetailList, incomeQuantityErrorMessages, requestQuantityErrorMessages, requestAmountErrorMessages } = this.state;
    this.setState({
      incomeQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      requestQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      requestAmountErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
    });
    let hasError = false;
    planDetailList.forEach((e, index) => {
      if (e.incomeQuantity === '') {
        hasError = true;
        incomeQuantityErrorMessages[index] = 'Lượng nhập không được bỏ trống';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.incomeQuantity !== '' && isNaN(e.incomeQuantity)) || Number(e.incomeQuantity) < 0) {
        hasError = true;
        incomeQuantityErrorMessages[index] = 'Lượng nhập không hợp lệ';
      }
      if (e.requestQuantity === '') {
        hasError = true;
        requestQuantityErrorMessages[index] = 'Cần nhập vào số lượng';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.requestQuantity !== '' && isNaN(e.requestQuantity)) || Number(e.requestQuantity) < 0) {
        hasError = true;
        requestQuantityErrorMessages[index] = 'Số lượng không hợp lệ';
      }
      if (e.requestAmount === '') {
        hasError = true;
        requestAmountErrorMessages[index] = 'Cần nhập vào thành tiền';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.requestAmount !== '' && isNaN(e.requestAmount)) || Number(e.requestAmount) < 0) {
        hasError = true;
        requestAmountErrorMessages[index] = 'Thành tiền không đúng định dạng';
      }
    });
    this.setState({
      incomeQuantityErrorMessages,
      requestQuantityErrorMessages,
      requestAmountErrorMessages,
    });
    if (hasError) {
      return;
    }
    if (auth.userID === planInfo.firstApprover) {
      planInfo.status = 'half-approved';
    }
    if (auth.userID === planInfo.secondApprover) {
      planInfo.status = 'completed';
    }
    setLoading(true);
    try {
      await updatePlan({ planInfo, planDetailList });
    } catch {
      setErrorMessage('Có lỗi khi huỷ yêu cầu. Vui lòng thử lại.');
    }
    setSubmitResult('Kế hoạch được phê duyệt thành công');
    setLoading(false);
  };

  cancelPlan = async () => {
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    const { planInfo, planDetailList } = this.state;
    planInfo.status = 'cancelled';
    setLoading(true);
    try {
      await updatePlan({ planInfo, planDetailList });
    } catch {
      setErrorMessage('Có lỗi khi huỷ yêu cầu. Vui lòng thử lại.');
    }
    setSubmitResult('Kế hoạch được huỷ thành công');
    setLoading(false);
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  getDateFromString = (inputString) => {
    const inputParts = inputString.split('/');
    return new Date(inputParts[2], inputParts[1] - 1, inputParts[0]);
  };

  render() {
    // Props first
    const { setErrorMessage, common, auth, setSubmitResult, history } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      planInfo,
      planDetailList,
      materialList,
      incomeQuantityErrorMessages,
      requestQuantityErrorMessages,
      requestAmountErrorMessages,

      userList,
    } = this.state;

    return (
      <div className="engine">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Kế hoạch vật tư</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="planName-TextInput"
                placeholder=""
                labelText="Tên kế hoạch"
                value={planInfo.planName}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, planName: e.target.value } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <NumberInput
                helperText=""
                id="year-NumberInput"
                invalidText="Năm không đúng định dạng"
                label="Năm"
                max={9999}
                min={2022}
                size="lg"
                value={planInfo.year}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, year: e.target.value } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => {
                  if (auth.userID === planInfo.firstApprover) {
                    this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, firstApproveDate: this.formatDate(e[0]) } }));
                  }
                  if (auth.userID === planInfo.secondApprover) {
                    this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, secondApproveDate: this.formatDate(e[0]) } }));
                  }
                }}
                value={auth.userID === planInfo.firstApprover ? planInfo.firstApproveDate : planInfo.secondApproveDate}
                disabled={auth.userID !== planInfo.firstApprover && auth.userID !== planInfo.secondApprover}
              >
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Ngày phê duyệt" id="approveDate-datepicker" />
              </DatePicker>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="firstApprover-Dropdown"
                titleText="Phòng kế toán phê duyệt"
                label=""
                items={userList}
                selectedItem={planInfo.firstApprover === '' ? null : userList.find((e) => e.id === planInfo.firstApprover)}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, firstApprover: e.selectedItem.id } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="secondApprover-Dropdown"
                titleText="Ban giám đốc phê duyệt"
                label=""
                items={userList}
                selectedItem={planInfo.secondApprover === '' ? null : userList.find((e) => e.id === planInfo.secondApprover)}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, secondApprover: e.selectedItem.id } }))}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <Button
                onClick={() => this.cancelPlan()}
                style={{ marginTop: '1rem', backgroundColor: '#da1e28' }}
                disabled={
                  planInfo.status === 'completed' ||
                  planInfo.status === 'cancelled' ||
                  (auth.userID !== planInfo.requestor && auth.userID !== planInfo.firstApprover && auth.userID !== planInfo.secondApprover)
                }
              >
                Huỷ
              </Button>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <TextArea
                cols={50}
                rows={4}
                id="description-TextArea"
                placeholder=""
                labelText="Nội dung"
                value={planInfo.description}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, description: e.target.value } }))}
              />
            </div>
            <div className="bx--col-lg-4">
              <Button
                onClick={() => this.approvePlan()}
                style={{ marginTop: '2rem' }}
                disabled={
                  !(planInfo.status === 'created' && auth.userID === planInfo.firstApprover) &&
                  !(planInfo.status === 'half-approved' && auth.userID === planInfo.secondApprover)
                }
              >
                Phê duyệt
              </Button>
              <Button onClick={() => this.downloadPlan()} style={{ marginTop: '2rem', marginLeft: '2rem' }} disabled={planInfo.status !== 'completed'}>
                Tải về
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
        </div>

        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title="Chi tiết danh mục vật tư kế hoạch năm">
                <Table style={{ maxHeigh: '70vh' }}>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="stt">STT</TableHeader>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit">Đơn vị tính</TableHeader>
                      <TableHeader key="engineType">Loại đầu máy</TableHeader>
                      <TableHeader key="engineNumber" style={{ width: '7%' }}>
                        Số máy
                      </TableHeader>
                      <TableHeader key="repairLevel">Cấp sửa chữa</TableHeader>
                      <TableHeader key="stockQuantity" style={{ width: '7%' }}>
                        Tồn kho
                      </TableHeader>
                      <TableHeader key="incomeQuantity" style={{ width: '7%' }}>
                        Đang nhập
                      </TableHeader>
                      <TableHeader key="requestQuantity" style={{ width: '7%' }}>
                        Yêu cầu
                      </TableHeader>
                      <TableHeader key="requestAmount">Thành tiền</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {planDetailList.map((row, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`stt-${index.toString()}`}>{index + 1}</TableCell>
                        <TableCell key={`materialID-${index.toString()}`}>{planDetailList[index].materialID}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>
                          {materialList.find((e) => e.materialID === planDetailList[index].materialID).materialName}
                        </TableCell>
                        <TableCell key={`unit-${index.toString()}`}>{materialList.find((e) => e.materialID === planDetailList[index].materialID).unit}</TableCell>
                        <TableCell key={`engineType-${index.toString()}`}>{planDetailList[index].engineType}</TableCell>
                        <TableCell key={`engineNumber-${index.toString()}`}>{planDetailList[index].engineNumber}</TableCell>
                        <TableCell key={`repairLevel-${index.toString()}`}>{planDetailList[index].repairLevel}</TableCell>
                        <TableCell key={`stockQuantity-${index.toString()}`}>{planDetailList[index].stockQuantity}</TableCell>
                        <TableCell key={`incomeQuantity-${index.toString()}`}>
                          <TextInput
                            id={`incomeQuantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              // eslint-disable-next-line no-restricted-globals
                              if (e.target.value !== '' && !isNaN(e.target.value)) {
                                incomeQuantityErrorMessages[index] = '';
                              }
                              planDetailList[index].incomeQuantity = e.target.value;
                              this.setState({ planDetailList, incomeQuantityErrorMessages });
                            }}
                            value={planDetailList[index].incomeQuantity}
                            invalid={incomeQuantityErrorMessages[index] !== ''}
                            invalidText={incomeQuantityErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`requestQuantity-${index.toString()}`}>
                          <TextInput
                            id={`requestQuantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              // eslint-disable-next-line no-restricted-globals
                              if (e.target.value !== '' && !isNaN(e.target.value)) {
                                requestQuantityErrorMessages[index] = '';
                              }
                              planDetailList[index].requestQuantity = e.target.value;
                              this.setState({ planDetailList, requestQuantityErrorMessages });
                            }}
                            value={planDetailList[index].requestQuantity}
                            invalid={requestQuantityErrorMessages[index] !== ''}
                            invalidText={requestQuantityErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`requestAmount-${index.toString()}`}>
                          <TextInput
                            id={`requestAmount-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              if (e.target.value === '') {
                                e.target.value = '0';
                              }
                              planDetailList[index].requestAmount = parseLocaleNumber(e.target.value);
                              requestAmountErrorMessages[index] = '';
                              this.setState({ planDetailList, requestAmountErrorMessages });
                            }}
                            value={CurrencyFormatter.format(planDetailList[index].requestAmount)}
                            invalid={requestAmountErrorMessages[index] !== ''}
                            invalidText={requestAmountErrorMessages[index]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

PlanDetail.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,

  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetail);
