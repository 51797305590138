import { CloudUpload32 } from '@carbon/icons-react';
import {
  Accordion,
  AccordionItem,
  Button,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NumberInput,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextArea,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { engineTypes, materialTypes, CurrencyFormatter, parseLocaleNumber } from '../../constants';
import { createPlan, getStockList, getUserList } from '../../services';

class CreateNewPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planInfo: {
        planName: '',
        companyID: '',
        planStatus: '',
        requestor: '',
        requestDate: '',
        firstApprover: '',
        secondApprover: '',
        description: '',
        year: '',
      },
      planDetailList: [],
      firstApproverList: [],
      secondApproverList: [],

      stockList: [],
      materialList: [],
      searchResult: [],
      materialListDisplay: [],
      page: 1,
      pageSize: 5,
      filterMaterialID: '',
      filterProductCode: '',
      filterMaterialName: '',
      filterMaterialType: '',

      planNameErrorMessage: '',
      requestDateErrorMessage: '',
      firstApproverErrorMessage: '',
      secondApproverErrorMessage: '',
      engineTypeErrorMessages: [],
      engineNumberErrorMessages: [],
      repairLevelErrorMessages: [],
      incomeQuantityErrorMessages: [],
      requestQuantityErrorMessages: [],
      requestAmountErrorMessages: [],
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, common, setMaterialList } = this.props;
    setLoading(true);
    let { materialList } = common;
    if (materialList.length === 0) {
      const getMaterialListResult = await getStockList(auth.companyID);
      materialList = getMaterialListResult.data;
      setMaterialList(materialList);
    }
    const getStockListResult = await getStockList(auth.companyID);
    const getFirstApproverListResult = await getUserList('', '', auth.companyID, 'phongketoantaichinh');
    const getSecondApproverListResult = await getUserList('', '', auth.companyID, 'bangiamdoc');
    setLoading(false);

    const { planInfo } = this.state;
    planInfo.companyID = auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID;
    planInfo.year = new Date().getFullYear();
    planInfo.requestDate = this.formatDate(new Date());
    this.setState({
      materialList,
      searchResult: materialList,
      materialListDisplay: materialList.slice(0, 5),
      planInfo,
      firstApproverList: getFirstApproverListResult.data.map((e) => {
        return { id: e.userID, label: e.username };
      }),
      secondApproverList: getSecondApproverListResult.data.map((e) => {
        return { id: e.userID, label: e.username };
      }),
      stockList: getStockListResult.data,
    });
  };

  findMaterial = () => {
    const { filterMaterialID, filterProductCode, filterMaterialName, filterMaterialType, pageSize, materialList } = this.state;
    let searchResult = JSON.parse(JSON.stringify(materialList));
    if (filterMaterialID !== '') {
      searchResult = searchResult.filter((e) => e.materialID.includes(filterMaterialID));
    }
    if (filterMaterialName !== '') {
      searchResult = searchResult.filter((e) => e.materialName.toUpperCase().includes(filterMaterialName.toUpperCase()));
    }
    if (filterProductCode !== '') {
      searchResult = searchResult.filter((e) => e.productCode.toUpperCase().includes(filterProductCode.toUpperCase()));
    }
    if (filterMaterialType !== '') {
      searchResult = searchResult.filter((e) => e.materialTypeID === filterMaterialType);
    }
    this.setState({
      searchResult,
      materialListDisplay: searchResult.slice(0, pageSize),
    });
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  getDateFromString = (inputString) => {
    const inputParts = inputString.split('/');
    return new Date(inputParts[2], inputParts[1] - 1, inputParts[0]);
  };

  createNewPlan = async () => {
    const { setErrorMessage, setLoading, setSubmitResult, auth } = this.props;
    const {
      planInfo,
      planDetailList,
      engineTypeErrorMessages,
      engineNumberErrorMessages,
      repairLevelErrorMessages,
      incomeQuantityErrorMessages,
      requestQuantityErrorMessages,
      requestAmountErrorMessages,
    } = this.state;

    this.setState({
      planNameErrorMessage: '',
      requestDateErrorMessage: '',
      firstApproverErrorMessage: '',
      secondApproverErrorMessage: '',
      engineTypeErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      engineNumberErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      repairLevelErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      incomeQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      requestQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
      requestAmountErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
    });
    setErrorMessage('');

    let hasError = false;
    if (planInfo.planName.trim() === '') {
      hasError = true;
      this.setState({ planNameErrorMessage: 'Tên kế hoạch không thể bỏ trống' });
    }
    if (planInfo.requestDate === '') {
      hasError = true;
      this.setState({ requestDateErrorMessage: 'Ngày tạo kế hoạch không thể bỏ trống' });
    }
    const currentDate = new Date();
    if (planInfo.requestDate > currentDate) {
      hasError = true;
      this.setState({ requestDateErrorMessage: 'Ngày tạo đơn không được vượt ngày hiện tại' });
    }
    if (planInfo.firstApprover === '') {
      hasError = true;
      this.setState({ firstApproverErrorMessage: 'Phòng kế toán không thể bỏ trống' });
    }
    if (planInfo.secondApprover === '') {
      hasError = true;
      this.setState({ secondApproverErrorMessage: 'Ban giám đốc không thể bỏ trống' });
    }
    if (planDetailList.length === 0) {
      hasError = true;
      setErrorMessage('Danh mục vật tư của kế hoạch không được bỏ trống');
    }
    planDetailList.forEach((e, index) => {
      if (e.engineType === '') {
        hasError = true;
        engineTypeErrorMessages[index] = 'Loại đầu máy không được bỏ trống';
      }
      if (e.engineNumber === '') {
        hasError = true;
        engineNumberErrorMessages[index] = 'Lượng đầu máy không được bỏ trống';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.engineNumber !== '' && isNaN(e.engineNumber)) || Number(e.engineNumber) < 0) {
        hasError = true;
        engineNumberErrorMessages[index] = 'Lượng đầu máy không hợp lệ';
      }
      if (e.repairLevel === '') {
        hasError = true;
        repairLevelErrorMessages[index] = 'Cấp sửa chữa không được bỏ trống';
      }
      if (e.incomeQuantity === '') {
        hasError = true;
        incomeQuantityErrorMessages[index] = 'Lượng nhập không được bỏ trống';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.incomeQuantity !== '' && isNaN(e.incomeQuantity)) || Number(e.incomeQuantity) < 0) {
        hasError = true;
        incomeQuantityErrorMessages[index] = 'Lượng nhập không hợp lệ';
      }
      if (e.requestQuantity === '') {
        hasError = true;
        requestQuantityErrorMessages[index] = 'Cần nhập vào số lượng';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.requestQuantity !== '' && isNaN(e.requestQuantity)) || Number(e.requestQuantity) < 0) {
        hasError = true;
        requestQuantityErrorMessages[index] = 'Số lượng không hợp lệ';
      }
      if (e.requestAmount === '') {
        hasError = true;
        requestAmountErrorMessages[index] = 'Cần nhập vào thành tiền';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.requestAmount !== '' && isNaN(e.requestAmount)) || Number(e.requestAmount) < 0) {
        hasError = true;
        requestAmountErrorMessages[index] = 'Thành tiền không đúng định dạng';
      }
    });
    this.setState({
      engineTypeErrorMessages,
      engineNumberErrorMessages,
      repairLevelErrorMessages,
      incomeQuantityErrorMessages,
      requestQuantityErrorMessages,
      requestAmountErrorMessages,
    });
    if (hasError) {
      return;
    }
    setLoading(true);
    try {
      planInfo.requestor = auth.userID;
      planInfo.status = 'created';
      await createPlan({ planInfo, planDetailList });
      setSubmitResult('Kế hoạch được tạo thành công');
    } catch {
      setErrorMessage('Có lỗi khi tạo kế hoạch. Vui lòng thử lại sau');
    }
    setLoading(false);
  };

  getMinimumQuantity = (material) => {
    const { stockList } = this.state;
    const stock = stockList.find((e) => e.materialID === material.materialID);
    if (stock === undefined) {
      return '';
    }
    return stock.minimumQuantity;
  };

  getQuantity = (material) => {
    const { stockList } = this.state;
    const stock = stockList.find((e) => e.materialID === material.materialID);
    if (stock === undefined) {
      return '';
    }
    return stock.quantity;
  };

  render() {
    // Props first
    const { setErrorMessage, common, setSubmitResult, history } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      planInfo,
      planDetailList,

      planNameErrorMessage,
      requestDateErrorMessage,
      firstApproverList,
      secondApproverList,
      firstApproverErrorMessage,
      secondApproverErrorMessage,

      searchResult,
      materialListDisplay,
      page,
      pageSize,
      filterMaterialID,
      filterProductCode,
      filterMaterialName,
      filterMaterialType,

      engineTypeErrorMessages,
      engineNumberErrorMessages,
      repairLevelErrorMessages,
      incomeQuantityErrorMessages,
      requestQuantityErrorMessages,
      requestAmountErrorMessages,
    } = this.state;

    const repairLevelList = [
      { id: 'Đột xuất', label: 'Đột xuất' },
      { id: 'Ro', label: 'Ro' },
      { id: 'R1', label: 'R1' },
      { id: 'R2', label: 'R2' },
      { id: 'R3', label: 'R3' },
      { id: 'Rt', label: 'Rt' },
      { id: 'Rk', label: 'Rk' },
      { id: 'Đại tu', label: 'Đại tu' },
      { id: 'S/c thường xuyên', label: 'S/c thường xuyên' },
    ];

    return (
      <div className="engine">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Kế hoạch vật tư</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="planName-TextInput"
                placeholder=""
                labelText="Tên kế hoạch"
                value={planInfo.planName}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, planName: e.target.value }, planNameErrorMessage: '' }))}
                invalid={planNameErrorMessage !== ''}
                invalidText={planNameErrorMessage}
              />
            </div>
            <div className="bx--col-lg-4">
              <NumberInput
                helperText=""
                id="year-NumberInput"
                invalidText="Năm không đúng định dạng"
                label="Năm"
                max={9999}
                min={2022}
                size="lg"
                value={planInfo.year}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, year: e.target.value } }))}
              />
            </div>
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) =>
                  this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, requestDate: this.formatDate(e[0]) }, requestDateErrorMessage: '' }))
                }
                value={planInfo.requestDate}
              >
                <DatePickerInput
                  datePickerType="single"
                  placeholder="dd/mm/yyyy"
                  labelText="Ngày tạo yêu cầu"
                  id="requestDate-datepicker"
                  invalid={requestDateErrorMessage !== ''}
                  invalidText={requestDateErrorMessage}
                />
              </DatePicker>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="firstApprover-Dropdown"
                titleText="Phòng kế toán phê duyệt"
                label=""
                items={firstApproverList}
                selectedItem={planInfo.firstApprover === '' ? null : firstApproverList.find((e) => e.id === planInfo.firstApprover)}
                onChange={(e) =>
                  this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, firstApprover: e.selectedItem.id }, firstApproverErrorMessage: '' }))
                }
                invalid={firstApproverErrorMessage !== ''}
                invalidText={firstApproverErrorMessage}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="secondApprover-Dropdown"
                titleText="Ban giám đốc phê duyệt"
                label=""
                items={secondApproverList}
                selectedItem={planInfo.secondApprover === '' ? null : secondApproverList.find((e) => e.id === planInfo.secondApprover)}
                onChange={(e) =>
                  this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, secondApprover: e.selectedItem.id }, secondApproverErrorMessage: '' }))
                }
                invalid={secondApproverErrorMessage !== ''}
                invalidText={secondApproverErrorMessage}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <TextArea
                cols={50}
                rows={4}
                id="description-TextArea"
                placeholder=""
                labelText="Nội dung"
                value={planInfo.description}
                onChange={(e) => this.setState((prevState) => ({ planInfo: { ...prevState.planInfo, description: e.target.value } }))}
              />
            </div>
            <div className="bx--col-lg-4">
              <Button onClick={() => this.createNewPlan()} style={{ marginTop: '2rem' }}>
                Tạo kế hoạch
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
        </div>
        <div className="bx--grid">
          <Accordion>
            <AccordionItem title={<strong>Tìm kiếm nhanh vật tư</strong>}>
              <div className="bx--row">
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterMaterialID-TextInput"
                    placeholder="Vui lòng nhập một phần mã vật tư để tìm kiếm"
                    labelText="Mã vật tư"
                    value={filterMaterialID}
                    onChange={(e) => this.setState({ filterMaterialID: e.target.value })}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterProductCode-TextInput"
                    placeholder="Vui lòng nhập một phần mã nhà sản xuất để tìm kiếm"
                    labelText="Mã nhà sản xuất"
                    value={filterProductCode}
                    onChange={(e) => this.setState({ filterProductCode: e.target.value })}
                  />
                </div>
                <div className="bx--col-lg-3 bx--col-md-3">
                  <Button onClick={() => this.findMaterial()} style={{ marginTop: '1rem' }}>
                    Tìm
                  </Button>
                </div>
              </div>
              <br />
              <div className="bx--row">
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterMaterialName-TextInput"
                    placeholder="Vui lòng nhập một phần tên vật tư để tìm kiếm"
                    labelText="Tên vật tư"
                    value={filterMaterialName}
                    onChange={(e) => this.setState({ filterMaterialName: e.target.value })}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
                <div className="bx--col-lg-4">
                  <Dropdown
                    id="filterMaterialType-Dropdown"
                    titleText="Kho vật tư (tài khoản kho)"
                    label=""
                    items={materialTypes}
                    selectedItem={filterMaterialType === '' ? null : materialTypes.find((e) => e.id === filterMaterialType)}
                    onChange={(e) => this.setState({ filterMaterialType: e.selectedItem.id })}
                  />
                </div>
                <div className="bx--col-lg-3 bx--col-md-3">
                  <Button
                    style={{ marginTop: '1rem' }}
                    onClick={() => this.setState({ filterMaterialID: '', filterProductCode: '', filterMaterialName: '', filterMaterialType: '' })}
                  >
                    Xoá bộ lọc
                  </Button>
                </div>
              </div>
              <br />
              <hr className="LeftNav-module--divider--1Z49I" />
              <div className="bx--row">
                <div className="bx--col-lg-2 bx--col-md-2" />
                <div className="bx--col-lg-12">
                  <TableContainer title={`Kết quả tìm kiếm cho ra ${searchResult.length} mục vật tư tương ứng.`}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeader key="materialID">Mã vật tư</TableHeader>
                          <TableHeader key="productCode">Mã nhà sản xuất</TableHeader>
                          <TableHeader key="materialName">Tên vật tư</TableHeader>
                          <TableHeader key="engineList">Đầu máy</TableHeader>
                          <TableHeader key="unit">Đơn vị tính</TableHeader>
                          <TableHeader key="minimumQuantity">Lượng tồn tối thiểu</TableHeader>
                          <TableHeader key="quantity">Lượng tồn trong kho</TableHeader>
                          <TableHeader />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {materialListDisplay.map((material, index) => (
                          <TableRow key={`row-${index.toString()}}`}>
                            <TableCell key={`materialID-${index.toString()}`}>{material.materialID}</TableCell>
                            <TableCell key={`productCode-${index.toString()}`}>{material.productCode}</TableCell>
                            <TableCell key={`materialName-${index.toString()}`}>{material.materialName}</TableCell>
                            <TableCell key={`engineList-${index.toString()}`}>{material.engineList}</TableCell>
                            <TableCell key={`unit-${index.toString()}`}>{material.unit}</TableCell>
                            <TableCell key={`minimumQuantity-${index.toString()}`}>{material.minimumQuantity}</TableCell>
                            <TableCell key={`quantity-${index.toString()}`}>{material.quantity}</TableCell>
                            <TableCell>
                              <Button
                                onClick={() => {
                                  planDetailList.push({
                                    materialID: material.materialID,
                                    materialName: material.materialName,
                                    unit: material.unit,
                                    engineType: '',
                                    engineNumber: '',
                                    repairLevel: '',
                                    stockQuantity: material.quantity,
                                    incomeQuantity: 0,
                                    requestQuantity: '',
                                    requestAmount: '',
                                  });
                                  engineTypeErrorMessages.push('');
                                  engineNumberErrorMessages.push('');
                                  repairLevelErrorMessages.push('');
                                  incomeQuantityErrorMessages.push('');
                                  requestQuantityErrorMessages.push('');
                                  requestAmountErrorMessages.push('');
                                  this.setState({
                                    planDetailList,
                                    engineTypeErrorMessages,
                                    engineNumberErrorMessages,
                                    repairLevelErrorMessages,
                                    incomeQuantityErrorMessages,
                                    requestQuantityErrorMessages,
                                    requestAmountErrorMessages,
                                  });
                                }}
                              >
                                Thêm
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    className="fixed-pagination"
                    backwardText="Previous page"
                    forwardText="Next page"
                    itemsPerPageText="Items per page:"
                    page={page}
                    pageNumberText="Page Number"
                    pageSize={pageSize}
                    pageSizes={[5, 10, 15]}
                    totalItems={searchResult.length}
                    onChange={(target) => {
                      this.setState({
                        materialListDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                        page: target.page,
                        pageSize: target.pageSize,
                      });
                    }}
                  />
                </div>
              </div>
              <br />
            </AccordionItem>
          </Accordion>
        </div>
        <br />
        <br />
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title="Chi tiết danh mục vật tư kế hoạch năm">
                <Table style={{ maxHeigh: '70vh' }}>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="stt">STT</TableHeader>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit">Đơn vị tính</TableHeader>
                      <TableHeader key="engineType">Loại đầu máy</TableHeader>
                      <TableHeader key="engineNumber" style={{ width: '7%' }}>
                        Số máy
                      </TableHeader>
                      <TableHeader key="repairLevel">Cấp sửa chữa</TableHeader>
                      <TableHeader key="stockQuantity" style={{ width: '7%' }}>
                        Tồn kho
                      </TableHeader>
                      <TableHeader key="incomeQuantity" style={{ width: '7%' }}>
                        Đang nhập
                      </TableHeader>
                      <TableHeader key="requestQuantity" style={{ width: '7%' }}>
                        Yêu cầu
                      </TableHeader>
                      <TableHeader key="requestAmount">Thành tiền</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {planDetailList.map((row, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`stt-${index.toString()}`}>{index + 1}</TableCell>
                        <TableCell key={`materialID-${index.toString()}`}>{planDetailList[index].materialID}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>{planDetailList[index].materialName}</TableCell>
                        <TableCell key={`unit-${index.toString()}`}>{planDetailList[index].unit}</TableCell>
                        <TableCell key={`engineType-${index.toString()}`}>
                          <Dropdown
                            id={`engineType-Dropdown-${index}`}
                            titleText=""
                            label=""
                            items={engineTypes}
                            selectedItem={planDetailList[index].engineType === '' ? null : engineTypes.find((e) => e.id === planDetailList[index].engineType)}
                            onChange={(e) => {
                              planDetailList[index].engineType = e.selectedItem.id;
                              if (planDetailList[index].engineType !== '') {
                                engineTypeErrorMessages[index] = '';
                              }
                              this.setState({ planDetailList, engineTypeErrorMessages });
                            }}
                            invalid={engineTypeErrorMessages[index] !== ''}
                            invalidText={engineTypeErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`engineNumber-${index.toString()}`}>
                          <TextInput
                            id={`engineNumber-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              // eslint-disable-next-line no-restricted-globals
                              if (e.target.value !== '' && !isNaN(e.target.value)) {
                                engineNumberErrorMessages[index] = '';
                              }
                              planDetailList[index].engineNumber = e.target.value;
                              this.setState({ planDetailList, engineNumberErrorMessages });
                            }}
                            value={planDetailList[index].engineNumber}
                            invalid={engineNumberErrorMessages[index] !== ''}
                            invalidText={engineNumberErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`repairLevel-${index.toString()}`}>
                          <Dropdown
                            id={`repairLevel-Dropdown-${index}`}
                            titleText=""
                            label=""
                            items={repairLevelList}
                            selectedItem={
                              planDetailList[index].repairLevel === '' ? null : repairLevelList.find((e) => e.id === planDetailList[index].repairLevel)
                            }
                            onChange={(e) => {
                              planDetailList[index].repairLevel = e.selectedItem.id;
                              if (planDetailList[index].repairLevel !== '') {
                                repairLevelErrorMessages[index] = '';
                              }
                              this.setState({ planDetailList, repairLevelErrorMessages });
                            }}
                            invalid={repairLevelErrorMessages[index] !== ''}
                            invalidText={repairLevelErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`stockQuantity-${index.toString()}`}>{planDetailList[index].stockQuantity}</TableCell>
                        <TableCell key={`incomeQuantity-${index.toString()}`}>
                          <TextInput
                            id={`incomeQuantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              // eslint-disable-next-line no-restricted-globals
                              if (e.target.value !== '' && !isNaN(e.target.value)) {
                                incomeQuantityErrorMessages[index] = '';
                              }
                              planDetailList[index].incomeQuantity = e.target.value;
                              this.setState({ planDetailList, incomeQuantityErrorMessages });
                            }}
                            value={planDetailList[index].incomeQuantity}
                            invalid={incomeQuantityErrorMessages[index] !== ''}
                            invalidText={incomeQuantityErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`requestQuantity-${index.toString()}`}>
                          <TextInput
                            id={`requestQuantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              // eslint-disable-next-line no-restricted-globals
                              if (e.target.value !== '' && !isNaN(e.target.value)) {
                                requestQuantityErrorMessages[index] = '';
                              }
                              planDetailList[index].requestQuantity = e.target.value;
                              this.setState({ planDetailList, requestQuantityErrorMessages });
                            }}
                            value={planDetailList[index].requestQuantity}
                            invalid={requestQuantityErrorMessages[index] !== ''}
                            invalidText={requestQuantityErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`requestAmount-${index.toString()}`}>
                          <TextInput
                            id={`requestAmount-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              if (e.target.value === '') {
                                e.target.value = '0';
                              }
                              planDetailList[index].requestAmount = parseLocaleNumber(e.target.value);
                              requestAmountErrorMessages[index] = '';
                              this.setState({ planDetailList, requestAmountErrorMessages });
                            }}
                            value={CurrencyFormatter.format(planDetailList[index].requestAmount)}
                            invalid={requestAmountErrorMessages[index] !== ''}
                            invalidText={requestAmountErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`remove-button-${index.toString()}`}>
                          <Button
                            onClick={() => {
                              planDetailList.splice(index, 1);
                              this.setState({
                                planDetailList,
                                engineTypeErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
                                engineNumberErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
                                repairLevelErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
                                incomeQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
                                requestQuantityErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
                                requestAmountErrorMessages: Array(planDetailList.length).fill('', 0, planDetailList.length),
                              });
                            }}
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

CreateNewPlan.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,

  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewPlan);
