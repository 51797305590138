import { CloudUpload32 } from '@carbon/icons-react';
import {
  Accordion,
  AccordionItem,
  Button,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextArea,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { CurrencyFormatter, materialTypes, parseLocaleNumber } from '../../constants';
import {
  approveInventoryReportInfo,
  exportInventoryReport,
  getInventoryReportDetailList,
  getInventoryReportInfo,
  getStockList,
  getUserList,
  saveInventoryReportDetailList,
  saveInventoryReportInfo,
} from '../../services';

class InventoryReport extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;
    this.state = {
      inventoryReportInfo: {
        id: '',
        status: 'created',
        creator: auth.userID,
        createdDate: this.formatDate(new Date()),
        firstApprover: '',
        secondApprover: '',
        leader: '',
        leaderPosition: '',
        leaderRepresentation: '',
        firstCommissioner: '',
        firstCommissionerPosition: '',
        firstCommissionerRepresentation: '',
        secondCommissioner: '',
        secondCommissionerPosition: '',
        secondCommissionerRepresentation: '',
        approveNote: '',
        companyID: auth.companyID,
      },
      firstApproverList: [],
      firstApproverErrorMessage: '',
      secondApproverList: [],
      secondApproverErrorMessage: '',
      userList: [],
      createdDateErrorMessage: '',
      inventoryReportDetailList: [],
      quantityErrorMessages: [],
      amountErrorMessages: [],
      materialList: [],
      searchResult: [],
      materialListDisplay: [],
      page: 1,
      pageSize: 5,
      filterMaterialID: '',
      filterProductCode: '',
      filterMaterialName: '',
      filterMaterialType: '',
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, setErrorMessage, common, setMaterialList, location } = this.props;
    const params = new URLSearchParams(location.search);

    try {
      setLoading(true);
      let { materialList } = common;
      if (materialList.length === 0) {
        const getMaterialListResult = await getStockList(auth.companyID);
        materialList = getMaterialListResult.data;
        setMaterialList(materialList);
      }

      const getFirstApproverListResult = await getUserList('', '', auth.companyID, 'phongketoantaichinh');
      const getSecondApproverListResult = await getUserList('', '', auth.companyID, 'bangiamdoc');
      const getUserListResult = await getUserList('', '', auth.companyID, '');

      this.setState({
        firstApproverList: getFirstApproverListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        secondApproverList: getSecondApproverListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        userList: getUserListResult.data.map((e) => {
          return { id: e.userID, label: e.username };
        }),
        materialList,
        searchResult: materialList,
        materialListDisplay: materialList.slice(0, 5),
      });

      if (params == null || params.size === 0) {
        return;
      }
      const inventoryReportID = params.get('inventoryReportID');
      const getInventoryReportInfoResult = await getInventoryReportInfo(inventoryReportID);
      if (
        getInventoryReportInfoResult.data.creator !== auth.userID &&
        getInventoryReportInfoResult.data.firstApprover !== auth.userID &&
        getInventoryReportInfoResult.data.secondApprover !== auth.userID
      ) {
        setErrorMessage('Bạn không có quyền truy cập ');
        setLoading(false);
        return;
      }
      const getInventorReportDetailListResult = await getInventoryReportDetailList(inventoryReportID);
      this.setState({
        inventoryReportInfo: getInventoryReportInfoResult.data,
        inventoryReportDetailList: getInventorReportDetailListResult.data,
        quantityErrorMessages: Array(getInventorReportDetailListResult.data.length).fill('', 0, getInventorReportDetailListResult.data.length),
        amountErrorMessages: Array(getInventorReportDetailListResult.data.length).fill('', 0, getInventorReportDetailListResult.data.length),
      });
    } catch {
      setErrorMessage('Không thể tải trang. Vui lòng thử lại.');
    } finally {
      setLoading(false);
    }
  };

  save = async () => {
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    const { inventoryReportInfo, inventoryReportDetailList, quantityErrorMessages, amountErrorMessages } = this.state;

    this.setState({
      firstApproverErrorMessage: '',
      secondApproverErrorMessage: '',
      quantityErrorMessages: Array(inventoryReportDetailList.length).fill('', 0, inventoryReportDetailList.length),
      amountErrorMessages: Array(inventoryReportDetailList.length).fill('', 0, inventoryReportDetailList.length),
    });
    setErrorMessage('');

    let hasError = false;
    if (inventoryReportInfo.firstApprover.trim() === '') {
      hasError = true;
      this.setState({ firstApproverErrorMessage: 'Trưởng phòng phê duyệt không thể bỏ trống' });
    }
    if (inventoryReportInfo.secondApprover.trim() === '') {
      hasError = true;
      this.setState({ secondApproverErrorMessage: 'Ban giám đốc phê duyệt không thể bỏ trống' });
    }
    if (inventoryReportInfo.creator === inventoryReportInfo.firstApprover) {
      hasError = true;
      this.setState({ firstApproverErrorMessage: 'Người tạo không được phép phê duyệt' });
    }
    if (inventoryReportInfo.creator === inventoryReportInfo.secondApprover) {
      hasError = true;
      this.setState({ secondApproverErrorMessage: 'Người tạo không được phép phê duyệt' });
    }
    if (inventoryReportInfo.createdDate === '') {
      hasError = true;
      this.setState({ createdDateErrorMessage: 'Ngày tạo báo cáo không được bỏ trống' });
    }
    const requestDateParts = inventoryReportInfo.createdDate.split('/');
    if (requestDateParts.length !== 3) {
      hasError = true;
      this.setState({ createdDateErrorMessage: 'Ngày tạo đơn không đúng định dạng' });
    }
    const requestDate = new Date(requestDateParts[2], requestDateParts[1] - 1, requestDateParts[0]);
    const currentDate = new Date();
    if (requestDate > currentDate) {
      hasError = true;
      this.setState({ createdDateErrorMessage: 'Ngày tạo đơn không được vượt ngày hiện tại' });
    }

    if (inventoryReportDetailList.length === 0) {
      hasError = true;
      setErrorMessage('Báo cáo cần ít nhất 1 danh mục vật tư');
    }

    inventoryReportDetailList.forEach((e, index) => {
      if (e.realQuantity === '') {
        hasError = true;
        quantityErrorMessages[index] = 'Cần nhập vào số lượng thực tế';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.realQuantity !== '' && isNaN(e.realQuantity)) || Number(e.realQuantity) < 0) {
        hasError = true;
        quantityErrorMessages[index] = 'Số lượng không hợp lệ';
      }
      if (e.realAmount === '') {
        hasError = true;
        amountErrorMessages[index] = 'Cần nhập vào thành tiền thực tế';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.realAmount !== '' && isNaN(e.realAmount)) || Number(e.realAmount) < 0) {
        hasError = true;
        amountErrorMessages[index] = 'Thành tiền không đúng định dạng';
      }
    });
    this.setState({ quantityErrorMessages, amountErrorMessages });

    if (
      inventoryReportDetailList.length > 0 &&
      new Set(
        inventoryReportDetailList.map((e) => {
          return e.materialID;
        })
      ).size !== inventoryReportDetailList.length
    ) {
      hasError = true;
      setErrorMessage('Có mã vật tư bị trùng. Vui lòng kiểm tra lại');
    }
    if (hasError) {
      return;
    }

    setLoading(true);
    try {
      const getInventoryReportInfoResponse = await saveInventoryReportInfo(inventoryReportInfo);
      inventoryReportDetailList.forEach((e) => {
        e.inventoryReportID = getInventoryReportInfoResponse.data.id;
      });
      await saveInventoryReportDetailList(getInventoryReportInfoResponse.data.id, inventoryReportDetailList);
    } catch {
      setErrorMessage('Có lỗi khi tạo báo cáo. Vui lòng thử lại.');
    }
    setSubmitResult('Báo cáo được tạo thành công');
    setLoading(false);
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  findMaterial = () => {
    const { filterMaterialID, filterProductCode, filterMaterialName, filterMaterialType, pageSize, materialList } = this.state;
    let searchResult = JSON.parse(JSON.stringify(materialList));
    if (filterMaterialID !== '') {
      searchResult = searchResult.filter((e) => e.materialID.includes(filterMaterialID));
    }
    if (filterMaterialName !== '') {
      searchResult = searchResult.filter((e) => e.materialName.toUpperCase().includes(filterMaterialName.toUpperCase()));
    }
    if (filterProductCode !== '') {
      searchResult = searchResult.filter((e) => e.productCode.toUpperCase().includes(filterProductCode.toUpperCase()));
    }
    if (filterMaterialType !== '') {
      searchResult = searchResult.filter((e) => e.materialTypeID === filterMaterialType);
    }
    this.setState({
      searchResult,
      materialListDisplay: searchResult.slice(0, pageSize),
    });
  };

  fistAprrove = async () => {
    const { history } = this.props;
    const { inventoryReportInfo } = this.state;
    inventoryReportInfo.status = 'half-approved';
    await approveInventoryReportInfo(inventoryReportInfo);
    history.push('/inventory/report/list');
  };

  secondAprrove = async () => {
    const { history } = this.props;
    const { inventoryReportInfo } = this.state;
    inventoryReportInfo.status = 'full-approved';
    await approveInventoryReportInfo(inventoryReportInfo);
    history.push('/inventory/report/list');
  };

  downloadReport = async () => {
    const { setErrorMessage } = this.props;
    const { inventoryReportInfo } = this.state;
    await exportInventoryReport(inventoryReportInfo.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bao_cap_kiem_ke.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      inventoryReportInfo,
      userList,
      firstApproverList,
      firstApproverErrorMessage,
      secondApproverList,
      secondApproverErrorMessage,
      inventoryReportDetailList,
      quantityErrorMessages,
      amountErrorMessages,
      createdDateErrorMessage,
      filterMaterialID,
      filterProductCode,
      filterMaterialName,
      filterMaterialType,
      materialListDisplay,
      searchResult,
      page,
      pageSize,
    } = this.state;

    const editable = inventoryReportInfo.status === 'created' && inventoryReportInfo.creator === auth.userID;

    return (
      <div className="stock-in">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Báo cáo kiểm kê hàng hoá</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="tester-Dropdown"
                titleText="Người lập báo cáo"
                label=""
                items={userList}
                selectedItem={inventoryReportInfo.creator === '' ? null : userList.find((e) => e.id === inventoryReportInfo.creator)}
                disabled
              />
            </div>
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) =>
                  this.setState((prevState) => ({
                    inventoryReportInfo: { ...prevState.inventoryReportInfo, createdDate: this.formatDate(e[0]) },
                    createdDateErrorMessage: '',
                  }))
                }
                value={inventoryReportInfo.createdDate}
              >
                <DatePickerInput
                  datePickerType="single"
                  placeholder="dd/mm/yyyy"
                  labelText="Ngày kiểm kê"
                  id="createdDate-datepicker"
                  invalid={createdDateErrorMessage !== ''}
                  invalidText={createdDateErrorMessage}
                  disabled={!editable}
                />
              </DatePicker>
            </div>
            <div className="bx--col-lg-4">
              {editable && <Button onClick={() => this.save()}>Lưu báo cáo</Button>}
              {inventoryReportInfo.firstApprover === auth.userID && inventoryReportInfo.status === 'created' && (
                <Button onClick={() => this.fistAprrove()}>Phê duyệt</Button>
              )}
              {inventoryReportInfo.secondApprover === auth.userID && inventoryReportInfo.status === 'half-approved' && (
                <Button onClick={() => this.secondAprrove()}>Phê duyệt</Button>
              )}
              {inventoryReportInfo.status === 'full-approved' && <Button onClick={() => this.downloadReport()}>Tải báo cáo</Button>}
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-12">Ban kiểm kê gồm:</div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="leader-Dropdown"
                titleText="Trưởng ban"
                label=""
                items={userList}
                selectedItem={inventoryReportInfo.leader === '' ? null : userList.find((e) => e.id === inventoryReportInfo.leader)}
                onChange={(e) => this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, leader: e.selectedItem.id } }))}
                disabled={!editable}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="leaderPosition-TextInput"
                placeholder=""
                labelText="Chức vụ"
                value={inventoryReportInfo.leaderPosition}
                onChange={(e) => this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, leaderPosition: e.target.value } }))}
                disabled={!editable}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="leaderRepresentation-TextInput"
                placeholder=""
                labelText="Đại diện"
                value={inventoryReportInfo.leaderRepresentation}
                onChange={(e) =>
                  this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, leaderRepresentation: e.target.value } }))
                }
                disabled={!editable}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="firstCommissioner-Dropdown"
                titleText="Uỷ viên 1"
                label=""
                items={userList}
                selectedItem={inventoryReportInfo.firstCommissioner === '' ? null : userList.find((e) => e.id === inventoryReportInfo.firstCommissioner)}
                onChange={(e) =>
                  this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, firstCommissioner: e.selectedItem.id } }))
                }
                disabled={!editable}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="leaderPosition-TextInput"
                placeholder=""
                labelText="Chức vụ"
                value={inventoryReportInfo.firstCommissionerPosition}
                onChange={(e) =>
                  this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, firstCommissionerPosition: e.target.value } }))
                }
                disabled={!editable}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="firstCommissionerPosition-TextInput"
                placeholder=""
                labelText="Đại diện"
                value={inventoryReportInfo.firstCommissionerPosition}
                onChange={(e) =>
                  this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, firstCommissionerPosition: e.target.value } }))
                }
                disabled={!editable}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="secondCommissioner-Dropdown"
                titleText="Uỷ viên 2"
                label=""
                items={userList}
                selectedItem={inventoryReportInfo.secondCommissioner === '' ? null : userList.find((e) => e.id === inventoryReportInfo.secondCommissioner)}
                onChange={(e) =>
                  this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, secondCommissioner: e.selectedItem.id } }))
                }
                disabled={!editable}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="secondCommissionerPosition-TextInput"
                placeholder=""
                labelText="Chức vụ"
                value={inventoryReportInfo.secondCommissionerPosition}
                onChange={(e) =>
                  this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, secondCommissionerPosition: e.target.value } }))
                }
                disabled={!editable}
              />
            </div>
            <div className="bx--col-lg-4">
              <TextInput
                id="secondCommissionerRepresentation-TextInput"
                placeholder=""
                labelText="Đại diện"
                value={inventoryReportInfo.secondCommissionerRepresentation}
                onChange={(e) =>
                  this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, secondCommissionerRepresentation: e.target.value } }))
                }
                disabled={!editable}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="first-approver-Dropdown"
                titleText="Trưởng phòng phê duyệt"
                label=""
                items={firstApproverList}
                selectedItem={inventoryReportInfo.firstApprover === '' ? null : firstApproverList.find((e) => e.id === inventoryReportInfo.firstApprover)}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    inventoryReportInfo: { ...prevState.inventoryReportInfo, firstApprover: e.selectedItem.id },
                    firstApproverErrorMessage: '',
                  }))
                }
                invalid={firstApproverErrorMessage !== ''}
                invalidText={firstApproverErrorMessage}
                disabled={!editable}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="second-approver-Dropdown"
                titleText="Ban giám đốc phê duyệt"
                label=""
                items={secondApproverList}
                selectedItem={inventoryReportInfo.secondApprover === '' ? null : secondApproverList.find((e) => e.id === inventoryReportInfo.secondApprover)}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    inventoryReportInfo: { ...prevState.inventoryReportInfo, secondApprover: e.selectedItem.id },
                    secondApproverErrorMessage: '',
                  }))
                }
                invalid={secondApproverErrorMessage !== ''}
                invalidText={secondApproverErrorMessage}
                disabled={!editable}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <TextArea
                id="approveNote-TextInput"
                placeholder=""
                labelText="Ý kiến phê duyệt"
                value={inventoryReportInfo.approveNote}
                onChange={(e) => this.setState((prevState) => ({ inventoryReportInfo: { ...prevState.inventoryReportInfo, approveNote: e.target.value } }))}
                disabled={editable}
              />
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
        </div>
        <div className="bx--grid" style={{ hidden: `${editable ? '' : 'hidden'}` }}>
          <Accordion>
            <AccordionItem title={<strong>Tìm kiếm nhanh vật tư</strong>}>
              <div className="bx--row">
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterMaterialID-TextInput"
                    placeholder="Vui lòng nhập một phần mã vật tư để tìm kiếm"
                    labelText="Mã vật tư"
                    value={filterMaterialID}
                    onChange={(e) => this.setState({ filterMaterialID: e.target.value })}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterProductCode-TextInput"
                    placeholder="Vui lòng nhập một phần mã nhà sản xuất để tìm kiếm"
                    labelText="Mã nhà sản xuất"
                    value={filterProductCode}
                    onChange={(e) => this.setState({ filterProductCode: e.target.value })}
                  />
                </div>
                <div className="bx--col-lg-3 bx--col-md-3">
                  <Button onClick={() => this.findMaterial()} style={{ marginTop: '1rem' }}>
                    Tìm
                  </Button>
                </div>
              </div>
              <br />
              <div className="bx--row">
                <div className="bx--col-lg-4">
                  <TextInput
                    id="filterMaterialName-TextInput"
                    placeholder="Vui lòng nhập một phần tên vật tư để tìm kiếm"
                    labelText="Tên vật tư"
                    value={filterMaterialName}
                    onChange={(e) => this.setState({ filterMaterialName: e.target.value })}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
                <div className="bx--col-lg-4">
                  <Dropdown
                    id="filterMaterialType-Dropdown"
                    titleText="Kho vật tư (tài khoản kho)"
                    label=""
                    items={materialTypes}
                    selectedItem={filterMaterialType === '' ? null : materialTypes.find((e) => e.id === filterMaterialType)}
                    onChange={(e) => this.setState({ filterMaterialType: e.selectedItem.id })}
                  />
                </div>
                <div className="bx--col-lg-3 bx--col-md-3">
                  <Button
                    style={{ marginTop: '1rem' }}
                    onClick={() => this.setState({ filterMaterialID: '', filterProductCode: '', filterMaterialName: '', filterMaterialType: '' })}
                  >
                    Xoá bộ lọc
                  </Button>
                </div>
              </div>
              <br />
              <hr className="LeftNav-module--divider--1Z49I" />
              <div className="bx--row">
                <div className="bx--col-lg-2 bx--col-md-2" />
                <div className="bx--col-lg-12">
                  <TableContainer title={`Kết quả tìm kiếm cho ra ${searchResult.length} mục vật tư tương ứng.`}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeader key="materialID">Mã vật tư</TableHeader>
                          <TableHeader key="productCode">Mã nhà sản xuất</TableHeader>
                          <TableHeader key="materialName">Tên vật tư</TableHeader>
                          <TableHeader key="engineList">Đầu máy</TableHeader>
                          <TableHeader key="unit">Đơn vị tính</TableHeader>
                          <TableHeader key="quantity">Lượng tồn trong kho</TableHeader>
                          <TableHeader key="amount">Tiền tồn</TableHeader>
                          <TableHeader />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {materialListDisplay.map((material, index) => (
                          <TableRow key={`row-${index.toString()}}`}>
                            <TableCell key={`materialID-${index.toString()}`}>{material.materialID}</TableCell>
                            <TableCell key={`productCode-${index.toString()}`}>{material.productCode}</TableCell>
                            <TableCell key={`materialName-${index.toString()}`}>{material.materialName}</TableCell>
                            <TableCell key={`engineList-${index.toString()}`}>{material.engineList}</TableCell>
                            <TableCell key={`unit-${index.toString()}`}>{material.unit}</TableCell>
                            <TableCell key={`quantity-${index.toString()}`}>{material.quantity}</TableCell>
                            <TableCell key={`amount-${index.toString()}`}>{material.amount}</TableCell>
                            <TableCell>
                              <Button
                                disabled={inventoryReportDetailList.find((e) => e.materialID === material.materialID) !== undefined}
                                onClick={() => {
                                  inventoryReportDetailList.push({
                                    materialID: material.materialID,
                                    materialName: material.materialName,
                                    unit: material.unit,
                                    usedPosition: '',
                                    originalQuantity: material.quantity,
                                    originalAmount: material.amount,
                                    realQuantity: '',
                                    realAmount: '',
                                  });
                                  quantityErrorMessages.push('');
                                  amountErrorMessages.push('');
                                  this.setState({ inventoryReportDetailList, quantityErrorMessages, amountErrorMessages });
                                }}
                              >
                                Thêm
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    className="fixed-pagination"
                    backwardText="Previous page"
                    forwardText="Next page"
                    itemsPerPageText="Items per page:"
                    page={page}
                    pageNumberText="Page Number"
                    pageSize={pageSize}
                    pageSizes={[5, 10, 15]}
                    totalItems={searchResult.length}
                    onChange={(target) => {
                      this.setState({
                        materialListDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                        page: target.page,
                        pageSize: target.pageSize,
                      });
                    }}
                  />
                </div>
              </div>
              <br />
            </AccordionItem>
          </Accordion>
        </div>
        <br />
        <br />
        <div className="bx--grid">
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title="Chi tiết danh mục báo cáo kiểm kê">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="stt">STT</TableHeader>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit">Đơn vị</TableHeader>
                      <TableHeader key="usedPosition">Nơi sử dụng</TableHeader>
                      <TableHeader key="originalQuantity">Lượng tồn trong kho</TableHeader>
                      <TableHeader key="originalAmount">Tiền tồn</TableHeader>
                      <TableHeader key="realQuantity">Lượng tồn thực tế</TableHeader>
                      <TableHeader key="realAmount">Tiền tồn thực tế</TableHeader>
                      <TableHeader />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inventoryReportDetailList.map((row, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`stt-${index.toString()}`}>{index + 1}</TableCell>
                        <TableCell key={`materialID-${index.toString()}`}>{inventoryReportDetailList[index].materialID}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>{inventoryReportDetailList[index].materialName}</TableCell>
                        <TableCell key={`unit-${index.toString()}`}>{inventoryReportDetailList[index].unit}</TableCell>
                        <TableCell key={`usedPosition-${index.toString()}`}>
                          <TextInput
                            id={`usedPosition-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              inventoryReportDetailList[index].usedPosition = e.target.value;
                              this.setState({ inventoryReportDetailList });
                            }}
                            value={inventoryReportDetailList[index].usedPosition}
                            disabled={!editable}
                          />
                        </TableCell>
                        <TableCell key={`originalQuantity-${index.toString()}`}>{inventoryReportDetailList[index].originalQuantity}</TableCell>
                        <TableCell key={`originalAmount-${index.toString()}`}>
                          {CurrencyFormatter.format(inventoryReportDetailList[index].originalAmount)}
                        </TableCell>
                        <TableCell key={`realQuantity-${index.toString()}`}>
                          <TextInput
                            id={`realQuantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              inventoryReportDetailList[index].realQuantity = e.target.value;
                              quantityErrorMessages[index] = '';
                              this.setState({ inventoryReportDetailList });
                            }}
                            value={inventoryReportDetailList[index].realQuantity}
                            invalid={quantityErrorMessages[index] !== ''}
                            invalidText={quantityErrorMessages[index]}
                            disabled={!editable}
                          />
                        </TableCell>
                        <TableCell key={`realAmount-${index.toString()}`}>
                          <TextInput
                            id={`realAmount-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              if (e.target.value === '') {
                                e.target.value = '0';
                              }
                              inventoryReportDetailList[index].realAmount = parseLocaleNumber(e.target.value);
                              amountErrorMessages[index] = '';
                              this.setState({ inventoryReportDetailList });
                            }}
                            value={CurrencyFormatter.format(inventoryReportDetailList[index].realAmount)}
                            invalid={amountErrorMessages[index] !== ''}
                            invalidText={amountErrorMessages[index]}
                            disabled={!editable}
                          />
                        </TableCell>

                        <TableCell key={`remove-button-${index.toString()}`}>
                          <Button
                            onClick={() => {
                              this.setState({
                                inventoryReportDetailList: inventoryReportDetailList.filter((e) => e.materialID !== row.materialID),
                                quantityErrorMessages: Array(inventoryReportDetailList.length).fill('', 0, inventoryReportDetailList.length),
                                amountErrorMessages: Array(inventoryReportDetailList.length).fill('', 0, inventoryReportDetailList.length),
                              });
                            }}
                            disabled={!editable}
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
        </div>
      </div>
    );
  }
}

InventoryReport.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReport);
