import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComboBox,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assignErrorMessage, setLoadingValue, setSubmitValue } from '../../actions/commonAction';
import { CurrencyFormatter } from '../../constants';
import { exportFuelReport, getEngineListByCompany, getFuelOrderList, getSupplierList } from '../../services';

const fuelMaterialList = [{ id: '000050001', label: 'Dầu Diesel' }];

class FuelReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuelOrderList: [],
      fuelOrderListDisplay: [],
      fromDate: this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
      toDate: this.formatDate(new Date()),
      filterOrderType: '',
      filterSupplier: '',
      supplierList: [],
      filterConsumer: '',
      consumerList: [],
      filterStatus: '',
      page: 1,
      pageSize: 10,
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth } = this.props;
    setLoading(true);
    const getSupplierListResult = await getSupplierList();
    const getEngineListResult = await getEngineListByCompany(auth.companyID);
    await this.findFuelOrderList();
    setLoading(false);
    this.setState({
      consumerList: [
        ...getEngineListResult.data.map((e) => {
          return { id: e.engineID, label: e.engineID };
        }),
        ...getSupplierListResult.data
          .sort((a, b) => a.supplierName.localeCompare(b.supplierName))
          .map((e) => {
            return { id: e.supplierID, label: e.supplierID.concat(' - ').concat(e.supplierName) };
          }),
      ],
      supplierList: [
        ...getSupplierListResult.data
          .sort((a, b) => a.supplierName.localeCompare(b.supplierName))
          .map((e) => {
            return { id: e.supplierID, label: e.supplierID.concat(' - ').concat(e.supplierName) };
          }),
        ...getEngineListResult.data.map((e) => {
          return { id: e.engineID, label: e.engineID };
        }),
      ],
    });
  };

  findFuelOrderList = async () => {
    const { setLoading, auth } = this.props;
    const { fromDate, toDate, filterOrderType, filterSupplier, filterConsumer, filterStatus } = this.state;
    setLoading(true);
    const getFuelOrderListResult = await getFuelOrderList(auth.companyID, fromDate, toDate, filterOrderType, filterSupplier, filterConsumer, filterStatus);
    setLoading(false);
    this.setState({
      fuelOrderList: getFuelOrderListResult.data,
      fuelOrderListDisplay: getFuelOrderListResult.data.slice(0, 10),
    });
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  exportFuelReport = async () => {
    const { auth, setErrorMessage } = this.props;
    const { fromDate, toDate } = this.state;
    await exportFuelReport(fromDate, toDate, auth.companyID, auth.userID)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bao_cao_nhien_lieu.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  getSupplierName = (supplier) => {
    const { supplierList } = this.state;
    if (supplier === '') {
      return '';
    }
    const selectedSupplier = supplierList.find((e) => e.id === supplier);
    if (selectedSupplier === undefined) {
      return '';
    }
    if (!selectedSupplier.label.includes(' - ')) {
      return selectedSupplier.label;
    }
    return selectedSupplier.label.split(' - ')[1];
  };

  getConsumerName = (consumer) => {
    const { consumerList } = this.state;
    if (consumer === '') {
      return '';
    }
    const selectedConsumer = consumerList.find((e) => e.id === consumer);
    if (selectedConsumer === undefined) {
      return '';
    }
    if (!selectedConsumer.label.includes(' - ')) {
      return selectedConsumer.label;
    }
    return selectedConsumer.label.split(' - ')[1];
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const { fuelOrderList, fuelOrderListDisplay, page, pageSize, consumerList, supplierList, filterConsumer, filterSupplier, fromDate, toDate } = this.state;

    return (
      <div className="fuel-report">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Báo cáo nhiên liệu</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Dropdown
                id="filterFuelOrderType-Dropdown"
                titleText="Loại đơn"
                label=""
                items={[
                  { id: '', label: '' },
                  { id: 'I', label: 'Nhập nhiên liệu' },
                  { id: 'O', label: 'Xuất nhiên liệu' },
                ]}
                onChange={(e) => this.setState({ filterOrderType: e.selectedItem.id, filterConsumer: '', filterSupplier: '' })}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="filterConsumer-Dropdown"
                titleText="Đối tượng tiêu thụ"
                label=""
                items={consumerList}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                selectedItem={filterConsumer === '' ? null : consumerList.find((e) => e.id === filterConsumer)}
                onChange={(e) => this.setState({ filterConsumer: e.selectedItem == null ? '' : e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="filterSupplier-ComboBox"
                titleText="Đơn vị cung cấp"
                placeholder=""
                label=""
                items={supplierList}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                selectedItem={filterSupplier === '' ? null : supplierList.find((e) => e.id === filterSupplier)}
                onChange={(e) => this.setState({ filterSupplier: e.selectedItem == null ? '' : e.selectedItem.id })}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Dropdown
                id="filterStatus-Dropdown"
                titleText="Trạng thái"
                label=""
                items={[
                  { id: '', label: '' },
                  { id: 'cancelled', label: 'Bị huỷ' },
                  { id: 'created', label: 'Chờ phê duyệt' },
                  { id: 'completed', label: 'Đã phê duyệt' },
                ]}
                onChange={(e) => this.setState({ filterStatus: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <DatePicker datePickerType="single" dateFormat="d/m/Y" onChange={(e) => this.setState({ fromDate: this.formatDate(e[0]) })} value={fromDate}>
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Đầu kì" id="fromDate-datepicker" />
              </DatePicker>
            </div>
            <div className="bx--col-lg-4">
              <DatePicker datePickerType="single" dateFormat="d/m/Y" onChange={(e) => this.setState({ toDate: this.formatDate(e[0]) })} value={toDate}>
                <DatePickerInput datePickerType="single" placeholder="dd/mm/yyyy" labelText="Cuối kì" id="toDate-datepicker" />
              </DatePicker>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.findFuelOrderList()} style={{ marginTop: '1rem' }}>
                Tìm
              </Button>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.exportFuelReport()} style={{ marginTop: '1rem' }}>
                Xuất báo cáo
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer
                title="Báo cáo xuất/nhập nhiên liệu trong kì"
                description={
                  <div>
                    <br />
                    Số lượng nhập trong kì (lít thực tế):{' '}
                    {CurrencyFormatter.format(
                      fuelOrderList.reduce(
                        (previousValue, currentValue) => previousValue + (currentValue.orderType === 'I' ? currentValue.realFuelQuantity : 0),
                        0
                      )
                    )}
                    <br />
                    <br />
                    Số lượng xuất trong kì (lít thực tế):{' '}
                    {CurrencyFormatter.format(
                      fuelOrderList.reduce(
                        (previousValue, currentValue) => previousValue + (currentValue.orderType === 'O' ? currentValue.realFuelQuantity : 0),
                        0
                      )
                    )}
                  </div>
                }
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Số hiệu</TableHeader>
                      <TableHeader>Loại đơn</TableHeader>
                      <TableHeader>Loại nhiên liệu</TableHeader>
                      <TableHeader>Đơn vị cung cấp</TableHeader>
                      <TableHeader>Đối tượng tiêu thụ</TableHeader>
                      <TableHeader>Số lượng (lít thực tế)</TableHeader>
                      <TableHeader>Số lượng (lít tại 15°C)</TableHeader>
                      <TableHeader>Ngày tạo đơn</TableHeader>
                      <TableHeader>Trạng thái</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fuelOrderListDisplay.map((fuelOrder, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell key={`orderNo-${index.toString()}`}>
                          <Link
                            to={{
                              pathname: fuelOrder.orderType === 'I' ? '/fuel/in/detail' : '/fuel/out/detail',
                              search: `?id=${fuelOrder.id}`,
                            }}
                          >
                            {fuelOrder.orderNo}
                          </Link>
                        </TableCell>
                        <TableCell key={`orderType-${index.toString()}`}>{fuelOrder.orderType === 'I' ? 'Nhập nhiên liệu' : 'Xuất nhiên liệu'}</TableCell>
                        <TableCell key={`materialID-${index.toString()}`}>{fuelMaterialList.find((e) => e.id === fuelOrder.materialID).label}</TableCell>
                        <TableCell key={`supplier-${index.toString()}`}>{this.getSupplierName(fuelOrder.supplier)}</TableCell>
                        <TableCell key={`consumer-${index.toString()}`}>{this.getConsumerName(fuelOrder.consumer)}</TableCell>
                        <TableCell key={`realQuantity-${index.toString()}`}>{fuelOrder.realFuelQuantity}</TableCell>
                        <TableCell key={`standardQuantity-${index.toString()}`}>{fuelOrder.standardFuelQuantity}</TableCell>
                        <TableCell key={`requestDate-${index.toString()}`}>{fuelOrder.requestDate}</TableCell>
                        <TableCell key={`status-${index.toString()}`}>
                          {fuelOrder.status === 'cancelled' && 'Bị huỷ'}
                          {fuelOrder.status === 'completed' && 'Đã phê duyệt'}
                          {fuelOrder.status === 'created' && 'Chờ phê duyệt'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[10, 20, 30, 40, 50]}
                totalItems={fuelOrderList.length}
                onChange={(target) => {
                  this.setState({
                    fuelOrderListDisplay: fuelOrderList.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
        </div>
      </div>
    );
  }
}

FuelReport.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  common: PropTypes.shape({ submitResult: PropTypes.string, errorMessage: PropTypes.string, isLoading: PropTypes.bool }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FuelReport);
