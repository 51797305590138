import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assignErrorMessage, setLoadingValue, setSubmitValue } from '../../actions/commonAction';
import { deleteEngineAnalysisInfo, exportEngineAnalysisReport, getCompanyList, getEngineAnalysisList } from '../../services';
import { repairLevelList } from '../../constants';

function getDefaultEngineAnalysisList(engineAnalysisListResult) {
  const engineAnalysisList = [];

  engineAnalysisListResult
    .filter((e) => e.status === 'half-approved')
    .sort((a, b) => b.repairDate - a.repairDate)
    .forEach((e) => {
      engineAnalysisList.push(e);
    });

  engineAnalysisListResult
    .filter((e) => e.status === 'full-approved')
    .sort((a, b) => b.repairDate - a.repairDate)
    .forEach((e) => {
      engineAnalysisList.push(e);
    });

  engineAnalysisListResult
    .filter((e) => e.status === 'complete-approved')
    .sort((a, b) => b.repairDate - a.repairDate)
    .forEach((e) => {
      engineAnalysisList.push(e);
    });

  engineAnalysisListResult
    .filter((e) => e.status === 'created')
    .sort((a, b) => b.repairDate - a.repairDate)
    .forEach((e) => {
      engineAnalysisList.push(e);
    });

  engineAnalysisListResult
    .filter((e) => e.status === 'disabled')
    .sort((a, b) => b.repairDate - a.repairDate)
    .forEach((e) => {
      engineAnalysisList.push(e);
    });
  return engineAnalysisList;
}

class EngineAnalysisList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engineAnalysisName: '',
      engineID: '',
      engineIDList: [],
      repairLevel: '',
      repairDate: '',
      repairDateList: [],
      status: '',
      engineAnalysisList: [],
      searchResult: [],
      engineAnalysisListDisplay: [],
      page: 1,
      pageSize: 10,
      companyID: props.auth.companyID,
      companyIDs: [],
    };
  }

  componentDidMount = async () => {
    const { setLoading, setErrorMessage, auth } = this.props;
    setLoading(true);
    try {
      const getEngineAnalysisListResult = await getEngineAnalysisList(auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID);
      const getCompanyListResult = await getCompanyList();
      const engineIDs = [
        ...new Set(
          getEngineAnalysisListResult.data.map((e) => {
            return e.engineID;
          })
        ),
      ].sort();
      // Default data
      const defaultEngineAnalysisList = getDefaultEngineAnalysisList(getEngineAnalysisListResult.data);
      const engineAnalysisList = [];
      if (auth.role === 'tongcongty') {
        defaultEngineAnalysisList
          .filter((e) => e.status === 'full-approved' && e.thirdApprover === auth.userID)
          .forEach((e) => {
            engineAnalysisList.push(e);
          });
        const existedIds = engineAnalysisList.map((e) => {
          return e.id;
        });
        defaultEngineAnalysisList
          .filter((e) => !existedIds.includes(e.id))
          .forEach((e) => {
            engineAnalysisList.push(e);
          });
      }
      if (auth.role === 'bangiamdoc') {
        defaultEngineAnalysisList
          .filter((e) => e.status === 'half-approved' && e.secondApprover === auth.userID)
          .forEach((e) => {
            engineAnalysisList.push(e);
          });
        let existedIds = engineAnalysisList.map((e) => {
          return e.id;
        });
        defaultEngineAnalysisList
          .filter((e) => e.status === 'created' && e.firstApprover === auth.userID && !existedIds.includes(e.id))
          .forEach((e) => {
            engineAnalysisList.push(e);
          });
        existedIds = engineAnalysisList.map((e) => {
          return e.id;
        });
        defaultEngineAnalysisList
          .filter((e) => !existedIds.includes(e.id))
          .forEach((e) => {
            engineAnalysisList.push(e);
          });
      }
      if (auth.role === 'phongkythuat') {
        defaultEngineAnalysisList
          .filter((e) => e.status === 'created' && e.requestor === auth.userID)
          .forEach((e) => {
            engineAnalysisList.push(e);
          });

        const existedIds = engineAnalysisList.map((e) => {
          return e.id;
        });
        defaultEngineAnalysisList
          .filter((e) => !existedIds.includes(e.id))
          .forEach((e) => {
            engineAnalysisList.push(e);
          });
      }
      if (engineAnalysisList.length === 0) {
        defaultEngineAnalysisList.forEach((e) => {
          engineAnalysisList.push(e);
        });
      }

      this.setState({
        engineAnalysisList,
        searchResult: engineAnalysisList,
        engineAnalysisListDisplay: engineAnalysisList.slice(0, 10),
        engineIDList: engineIDs.map((e) => {
          return { id: e, label: e };
        }),
        repairDateList: [
          ...new Set(
            engineAnalysisList.map((e) => {
              return { id: e.repairDate, label: e.repairDate };
            })
          ),
        ],
        companyIDs: [
          { id: '', label: 'Toàn Tổng công ty' },
          ...getCompanyListResult.data.map((e) => {
            return { id: e.companyID, label: e.companyName };
          }),
        ],
      });
    } catch {
      setErrorMessage('Lỗi khi tải trang. Vui lòng thử lại');
    }
    setLoading(false);
  };

  delete = async (engineAnalysisID) => {
    const { setLoading, auth } = this.props;
    setLoading(true);
    await deleteEngineAnalysisInfo(engineAnalysisID);
    const getEngineAnalysisListResult = await getEngineAnalysisList(auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID);
    const engineIDs = [
      ...new Set(
        getEngineAnalysisListResult.data.map((e) => {
          return e.engineID;
        })
      ),
    ].sort();
    setLoading(false);
    this.setState({
      engineAnalysisList: getEngineAnalysisListResult.data,
      searchResult: getEngineAnalysisListResult.data,
      engineAnalysisListDisplay: getEngineAnalysisListResult.data.slice(0, 10),
      engineIDList: engineIDs.map((e) => {
        return { id: e, label: e };
      }),
      repairDateList: [
        ...new Set(
          getEngineAnalysisListResult.data.map((e) => {
            return { id: e.repairDate, label: e.repairDate };
          })
        ),
      ],
    });
  };

  exportReport = async (engineAnalysisID, engineID, repairDate, repairLevel) => {
    const { setErrorMessage } = this.props;
    await exportEngineAnalysisReport(engineAnalysisID)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Bien_ban_giai_the_${engineID}_${repairLevel}_${repairDate.replace('/', '_')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  findEngineAnalysisList = () => {
    const { engineAnalysisList, engineAnalysisName, engineID, repairLevel, repairDate, companyID, status } = this.state;
    let searchResult = JSON.parse(JSON.stringify(engineAnalysisList));
    if (engineAnalysisName.trim() !== '') {
      searchResult = searchResult.filter((e) => e.engineAnalysisName.toUpperCase().includes(engineAnalysisName.toUpperCase()));
    }
    if (engineID !== '') {
      searchResult = searchResult.filter((e) => e.engineID === engineID);
    }
    if (repairLevel !== '') {
      searchResult = searchResult.filter((e) => e.repairLevel === repairLevel);
    }
    if (repairDate !== '') {
      searchResult = searchResult.filter((e) => e.repairDate === repairDate);
    }
    if (companyID !== '') {
      searchResult = searchResult.filter((e) => e.companyID === companyID);
    }
    if (status !== '') {
      searchResult = searchResult.filter((e) => e.status === status);
    }
    this.setState({ searchResult, engineAnalysisListDisplay: searchResult.slice(0, 10) });
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      searchResult,
      engineAnalysisListDisplay,
      page,
      pageSize,
      engineAnalysisName,
      engineID,
      engineIDList,
      repairLevel,
      repairDateList,
      repairDate,
      companyID,
      companyIDs,
      status,
    } = this.state;

    const statusList = [
      { id: 'created', label: 'Đã được khởi tạo' },
      { id: 'half-approved', label: 'Đã được trưởng phòng phê duyệt' },
      { id: 'full-approved', label: 'Đã được ban giám đóc phê duyệt' },
      { id: 'complete-approved', label: 'Đã được tổng công ty phê duyệt' },
    ];

    return (
      <div className="engine-analysis-list">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Danh sách biên bản giải thể</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="engineAnalysisName-TextInput"
                placeholder="Nhập một phần tên biên bản để tìm kiếm"
                labelText="Tên biên bản"
                value={engineAnalysisName}
                onChange={(e) => this.setState({ engineAnalysisName: e.target.value })}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="engineID-Dropdown"
                titleText="Đầu máy tiêu thụ"
                label=""
                items={engineIDList}
                selectedItem={engineID === '' ? null : engineIDList.find((e) => e.id === engineID)}
                onChange={(e) => this.setState({ engineID: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="repairLevel-Dropdown"
                titleText="Cấp sửa chữa"
                label=""
                items={repairLevelList}
                selectedItem={repairLevel === '' ? null : repairLevelList.find((e) => e.id === repairLevel)}
                onChange={(e) => this.setState({ repairLevel: e.selectedItem.id })}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="repairDate-Dropdown"
                titleText="Ngày vào sửa chữa"
                label=""
                items={repairDateList}
                selectedItem={repairDate === '' ? null : repairDateList.find((e) => e.id === repairDate)}
                onChange={(e) => this.setState({ repairDate: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="companyID-Dropdown"
                titleText="Đơn vị"
                label=""
                items={companyIDs}
                selectedItem={companyIDs.find((e) => e.id === companyID)}
                onChange={(e) => this.setState({ companyID: e.selectedItem.id })}
                disabled={auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="status-Dropdown"
                titleText="Trạng thái"
                label=""
                items={statusList}
                selectedItem={status === '' ? null : statusList.find((e) => e.id === status)}
                onChange={(e) => this.setState({ status: e.selectedItem.id })}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Button style={{ marginTop: '1rem', marginRight: '1rem' }} onClick={() => this.findEngineAnalysisList()}>
                Tìm kiếm
              </Button>
              {auth.role === 'phongkythuat' && auth.companyID === companyID && auth.companyID !== 'TCT_DS_VN' && (
                <Button style={{ marginTop: '1rem' }} onClick={() => history.push('/engine/analysis')}>
                  Tạo BBGT
                </Button>
              )}
            </div>
          </div>
          <br />
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Có tất cả ${searchResult.length} biên bản giải thể.`}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="engineAnalysisName">Tên biên bản giải thể</TableHeader>
                      <TableHeader key="engineID">Đầu máy</TableHeader>
                      <TableHeader key="repairDate">Ngày vào sửa chữa</TableHeader>
                      <TableHeader key="repairLevel">Cấp sửa chữa</TableHeader>
                      <TableHeader key="status">Trạng thái</TableHeader>
                      <TableHeader key="action" />
                      <TableHeader key="download" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {engineAnalysisListDisplay.map((row, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell>
                          <Link to={`/engine/analysis?engineAnalysisID=${row.id}`}>{row.engineAnalysisName}</Link>
                        </TableCell>
                        <TableCell>{row.engineID}</TableCell>
                        <TableCell>{row.repairDate}</TableCell>
                        <TableCell>{row.repairLevel}</TableCell>
                        <TableCell>
                          {row.status === 'disabled' && <p style={{ color: '#da1e28' }}>Đã bị huỷ</p>}
                          {row.status === 'created' && <p style={{ color: '#da1e28' }}>Chờ được phê duyệt</p>}
                          {row.status === 'half-approved' && <p style={{ color: '#cfe625' }}>Đã được trưởng phòng phê duyệt</p>}
                          {row.status === 'full-approved' && <p style={{ color: '#12e65c' }}>Đã được ban giám đốc phê duyệt</p>}
                          {row.status === 'complete-approved' && <p style={{ color: '#8142f5' }}>Đã được Tổng công ty phê duyệt</p>}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => this.delete(row.id)}
                            disabled={
                              (auth.userID !== row.firstApprover && auth.userID !== row.secondApprover) ||
                              row.status === 'disabled' ||
                              row.status === 'full-approved' ||
                              row.status === 'complete-approved'
                            }
                          >
                            Huỷ
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => history.push(`/engine/analysis?templateEngineAnalysisID=${row.id}`)}>Copy</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[5, 10, 15]}
                totalItems={searchResult.length}
                onChange={(target) => {
                  this.setState({
                    engineAnalysisListDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

EngineAnalysisList.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  common: PropTypes.shape({ submitResult: PropTypes.string, errorMessage: PropTypes.string, isLoading: PropTypes.bool }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EngineAnalysisList);
