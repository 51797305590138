import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { materialTypes } from '../../constants';
import { deleteMaterial, getMaterialList, getStockList } from '../../services';

class MaterialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterMaterialID: '',
      filterProductCode: '',
      filterMaterialName: '',
      filterMaterialType: '',
      materialList: [],
      materialListDisplay: [],
      searchResult: [],
      page: 1,
      pageSize: 30,
      redirect: '',
    };
  }

  componentDidMount = async () => {
    const { setLoading, setErrorMessage, common, setMaterialList, auth } = this.props;
    const { pageSize } = this.state;
    let { materialList } = common;
    setLoading(true);
    try {
      if (materialList.length === 0) {
        const getMaterialListResult = await getStockList(auth.companyID);
        materialList = getMaterialListResult.data;
        setMaterialList(materialList);
      }
      this.setState({
        materialList,
        searchResult: materialList,
        materialListDisplay: materialList.slice(0, pageSize),
      });
    } catch {
      setErrorMessage('Không thể tải trang. Vui lòng thử lại.');
    }
    setLoading(false);
  };

  findMaterial = () => {
    const { filterMaterialID, filterProductCode, filterMaterialName, filterMaterialType, pageSize, materialList } = this.state;
    let searchResult = JSON.parse(JSON.stringify(materialList));
    if (filterMaterialID !== '') {
      searchResult = searchResult.filter((e) => e.materialID.includes(filterMaterialID));
    }
    if (filterMaterialName !== '') {
      searchResult = searchResult.filter((e) => e.materialName.toUpperCase().includes(filterMaterialName.toUpperCase()));
    }
    if (filterProductCode !== '') {
      searchResult = searchResult.filter((e) => e.productCode.toUpperCase().includes(filterProductCode.toUpperCase()));
    }
    if (filterMaterialType !== '') {
      searchResult = searchResult.filter((e) => e.materialTypeID === filterMaterialType);
    }
    this.setState({
      searchResult,
      materialListDisplay: searchResult.slice(0, pageSize),
    });
  };

  removeMaterial = async (materialID) => {
    const { setLoading, setErrorMessage, setSubmitResult } = this.props;
    setLoading(true);
    try {
      await deleteMaterial(materialID);
      setSubmitResult('Danh mục vật tư được xoá thành công');
    } catch {
      setErrorMessage('Có lỗi xảy ra khi xoá danh mục vật tư');
    }
    setLoading(false);
  };

  reload = async () => {
    const { setLoading, setSubmitResult } = this.props;
    const { pageSize } = this.state;
    setSubmitResult('');
    setLoading(true);
    const getMaterialListResult = await getMaterialList();
    setLoading(false);
    this.setState({
      materialList: getMaterialListResult.data,
      searchResult: getMaterialListResult.data,
      materialListDisplay: getMaterialListResult.data.slice(0, pageSize),
      filterMaterialID: '',
      filterProductCode: '',
      filterMaterialName: '',
      filterMaterialType: '',
    });
  };

  render() {
    const { filterMaterialID, filterProductCode, filterMaterialName, filterMaterialType, materialListDisplay, searchResult, page, pageSize, redirect } =
      this.state;
    if (redirect !== '') {
      return redirect;
    }
    const { common, setErrorMessage, history, auth } = this.props;
    const { errorMessage, isLoading, submitResult } = common;

    return (
      <div className="material-list">
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal className="btn-success" open={submitResult !== ''} size="sm" onClose={() => this.reload()}>
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter onRequestSubmit={() => this.reload()} primaryButtonText="OK" secondaryButtonText="" />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Danh mục vật tư</h4>
        </div>
        <br />

        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="filterMaterialID-TextInput"
                placeholder="Vui lòng nhập một phần mã vật tư để tìm kiếm"
                labelText="Mã vật tư"
                value={filterMaterialID}
                onChange={(e) => this.setState({ filterMaterialID: e.target.value })}
              />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <TextInput
                id="filterProductCode-TextInput"
                placeholder="Vui lòng nhập một phần mã nhà sản xuất để tìm kiếm"
                labelText="Mã nhà sản xuất"
                value={filterProductCode}
                onChange={(e) => this.setState({ filterProductCode: e.target.value })}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.findMaterial()} style={{ marginTop: '1rem' }}>
                Tìm
              </Button>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="filterMaterialName-TextInput"
                placeholder="Vui lòng nhập một phần tên vật tư để tìm kiếm"
                labelText="Tên vật tư"
                value={filterMaterialName}
                onChange={(e) => this.setState({ filterMaterialName: e.target.value })}
              />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <Dropdown
                id="filterMaterialType-Dropdown"
                titleText="Kho vật tư (tài khoản kho)"
                label=""
                items={materialTypes}
                selectedItem={filterMaterialType === '' ? null : materialTypes.find((e) => e.id === filterMaterialType)}
                onChange={(e) => this.setState({ filterMaterialType: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Button
                style={{ marginTop: '1rem' }}
                onClick={() => this.setState({ filterMaterialID: '', filterProductCode: '', filterMaterialName: '', filterMaterialType: '' })}
              >
                Xoá bộ lọc
              </Button>
            </div>
          </div>
          <br />
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
        </div>

        <div className="bx--grid">
          <br />
          {auth.companyID === 'TCT_DS_VN' && auth.role === 'phongkythuat' && (
            <div className="bx--row">
              <div className="bx--col-lg-4">
                <Button onClick={() => history.push('/material/add')}>Thêm vật tư mới</Button>
              </div>
              <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            </div>
          )}
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Tìm thấy tất cả ${searchResult.length} danh mục vật tư.`}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="productCode">Mã nhà sản xuất</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit">Đơn vị tính</TableHeader>
                      <TableHeader key="engineList">Đầu máy</TableHeader>
                      {auth.companyID === 'TCT_DS_VN' && auth.role === 'phongkythuat' && <TableHeader />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialListDisplay.map((material, index) => (
                      <TableRow key={`row-${index.toString()}}`}>
                        <TableCell key={`materialID-${index.toString()}`}>
                          <Link
                            to={{
                              pathname: '/material/update',
                              search: `?materialID=${material.materialID}`,
                            }}
                          >
                            {material.materialID}
                          </Link>
                        </TableCell>
                        <TableCell key={`productCode-${index.toString()}`}>{material.productCode}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>{material.materialName}</TableCell>
                        <TableCell key={`unit-${index.toString()}`}>{material.unit}</TableCell>
                        <TableCell key={`engineList-${index.toString()}`}>{material.engineList}</TableCell>
                        {auth.companyID === 'TCT_DS_VN' && auth.role === 'phongkythuat' && (
                          <TableCell>
                            <OverflowMenu>
                              <OverflowMenuItem
                                itemText="Sửa"
                                onClick={() => this.setState({ redirect: <Redirect to={`/material/update?materialID=${material.materialID}`} /> })}
                              />
                              <OverflowMenuItem itemText="Xoá" onClick={() => this.removeMaterial(material.materialID)} />
                            </OverflowMenu>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[30, 40, 50]}
                totalItems={searchResult.length}
                onChange={(target) => {
                  this.setState({
                    materialListDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
        </div>
      </div>
    );
  }
}

MaterialList.propTypes = {
  setSubmitResult: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList);
