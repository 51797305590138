import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  Dropdown,
  FileUploader,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { API_BASE_URL, materialTypes, CurrencyFormatter, parseLocaleNumber } from '../../constants';
import { downTempFile, getNumberOfUnapprovedOrder, getStockList, stockUpdate } from '../../services';

class StockUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimumQuantityErrorMessages: [],
      quantityErrorMessages: [],
      amountErrorMessages: [],

      materialList: [],
      searchResult: [],
      materialListDisplay: [],
      page: 1,
      pageSize: 5,
      filterMaterialID: '',
      filterProductCode: '',
      filterMaterialName: '',
      filterMaterialType: '',

      uploadFile: '',
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, setErrorMessage, common, setMaterialList } = this.props;
    if (auth.role !== 'phongketoantaichinh') {
      setErrorMessage('Chỉ có người của phòng tài chính kế toán mới có thể truy cập chức năng này.');
      return;
    }
    setLoading(true);
    const { pageSize } = this.state;
    try {
      let { materialList } = common;
      if (materialList.length === 0) {
        const getMaterialListResult = await getStockList(auth.companyID);
        materialList = getMaterialListResult.data;
        setMaterialList(materialList);
      }
      this.setState({
        materialList,
        searchResult: materialList,
        materialListDisplay: materialList.slice(0, pageSize),
        minimumQuantityErrorMessages: Array(materialList.length).fill('', 0, materialList.length),
        quantityErrorMessages: Array(materialList.length).fill('', 0, materialList.length),
        amountErrorMessages: Array(materialList.length).fill('', 0, materialList.length),
      });
    } catch {
      setErrorMessage('Không thể tải trang. Vui lòng thử lại.');
    }
    setLoading(false);
  };

  findMaterial = () => {
    const { filterMaterialID, filterProductCode, filterMaterialName, filterMaterialType, pageSize, materialList } = this.state;
    let searchResult = JSON.parse(JSON.stringify(materialList));
    if (filterMaterialID !== '') {
      searchResult = searchResult.filter((e) => e.materialID.includes(filterMaterialID));
    }
    if (filterMaterialName !== '') {
      searchResult = searchResult.filter((e) => e.materialName.toUpperCase().includes(filterMaterialName.toUpperCase()));
    }
    if (filterProductCode !== '') {
      searchResult = searchResult.filter((e) => e.productCode.toUpperCase().includes(filterProductCode.toUpperCase()));
    }
    if (filterMaterialType !== '') {
      searchResult = searchResult.filter((e) => e.materialTypeID === filterMaterialType);
    }
    this.setState({
      searchResult,
      materialListDisplay: searchResult.slice(0, pageSize),
    });
  };

  saveUpdatedStock = async () => {
    const { setErrorMessage, setLoading, setSubmitResult, auth } = this.props;
    const { materialListDisplay, quantityErrorMessages, amountErrorMessages } = this.state;

    this.setState({
      minimumQuantityErrorMessages: Array(materialListDisplay.length).fill('', 0, materialListDisplay.length),
      quantityErrorMessages: Array(materialListDisplay.length).fill('', 0, materialListDisplay.length),
      amountErrorMessages: Array(materialListDisplay.length).fill('', 0, materialListDisplay.length),
    });
    setErrorMessage('');

    let hasError = false;
    materialListDisplay.forEach((e, index) => {
      // eslint-disable-next-line no-restricted-globals
      if ((e.minimumQuantity !== '' && isNaN(e.minimumQuantity)) || Number(e.minimumQuantity) < 0) {
        hasError = true;
        quantityErrorMessages[index] = 'Lượng tồn tối thiểu không hợp lệ';
      }
      if (e.quantity === '') {
        hasError = true;
        quantityErrorMessages[index] = 'Cần nhập vào số lượng';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.quantity !== '' && isNaN(e.quantity)) || Number(e.quantity) < 0) {
        hasError = true;
        quantityErrorMessages[index] = 'Số lượng không hợp lệ';
      }
      if (e.amount === '') {
        hasError = true;
        amountErrorMessages[index] = 'Cần nhập vào thành tiền';
      }
      // eslint-disable-next-line no-restricted-globals
      if ((e.amount !== '' && isNaN(e.amount)) || Number(e.amount) < 0) {
        hasError = true;
        amountErrorMessages[index] = 'Thành tiền không đúng định dạng';
      }
    });
    this.setState({ quantityErrorMessages, amountErrorMessages });

    if (hasError) {
      return;
    }

    setLoading(true);
    try {
      const getNumberOfUnapprovedOrderResult = await getNumberOfUnapprovedOrder(auth.companyID);
      if (getNumberOfUnapprovedOrderResult.data > 0) {
        setErrorMessage('Vẫn còn một số đơn chưa được phê duyệt. Vui lòng thử lại sau.');
        setLoading(false);
        return;
      }
      await stockUpdate(materialListDisplay, auth.companyID);
    } catch {
      setErrorMessage('Cập nhật kho đầu kì bị lỗi. Vui lòng thử lại sau.');
    }
    setLoading(false);
    setSubmitResult('Danh mục kho được câp nhật thành công');
  };

  downloadTempFile = async () => {
    const { setErrorMessage, setLoading } = this.props;
    setLoading(true);
    await downTempFile()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file_mau.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi download file mẫu. Vui lòng thử lại');
      });
    setLoading(false);
  };

  updateStockByExcelFile = async () => {
    const { setLoading, setErrorMessage } = this.props;
    const { uploadFile } = this.state;
    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadFile);
    fetch(`${API_BASE_URL}/stock/excel/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        setErrorMessage('Có lỗi xảy ra khi download file kết quả. Vui lòng thử lại');
        return null;
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file_ket_qua.xlsx');
        document.body.appendChild(link);
        link.click();
      });

    setLoading(false);
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      filterMaterialID,
      filterProductCode,
      filterMaterialName,
      filterMaterialType,
      materialListDisplay,
      searchResult,
      page,
      pageSize,
      minimumQuantityErrorMessages,
      quantityErrorMessages,
      amountErrorMessages,
    } = this.state;

    return (
      <div className="stock-update">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Cập nhật kho đầu kì</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="filterMaterialID-TextInput"
                placeholder="Vui lòng nhập một phần mã vật tư để tìm kiếm"
                labelText="Mã vật tư"
                value={filterMaterialID}
                onChange={(e) => this.setState({ filterMaterialID: e.target.value })}
              />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <TextInput
                id="filterProductCode-TextInput"
                placeholder="Vui lòng nhập một phần mã nhà sản xuất để tìm kiếm"
                labelText="Mã nhà sản xuất"
                value={filterProductCode}
                onChange={(e) => this.setState({ filterProductCode: e.target.value })}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Button onClick={() => this.findMaterial()} style={{ marginTop: '1rem' }}>
                Tìm
              </Button>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="filterMaterialName-TextInput"
                placeholder="Vui lòng nhập một phần tên vật tư để tìm kiếm"
                labelText="Tên vật tư"
                value={filterMaterialName}
                onChange={(e) => this.setState({ filterMaterialName: e.target.value })}
              />
            </div>
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-4">
              <Dropdown
                id="filterMaterialType-Dropdown"
                titleText="Kho vật tư (tài khoản kho)"
                label=""
                items={materialTypes}
                selectedItem={filterMaterialType === '' ? null : materialTypes.find((e) => e.id === filterMaterialType)}
                onChange={(e) => this.setState({ filterMaterialType: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <Button
                style={{ marginTop: '1rem' }}
                onClick={() => this.setState({ filterMaterialID: '', filterProductCode: '', filterMaterialName: '', filterMaterialType: '' })}
              >
                Xoá bộ lọc
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.saveUpdatedStock()}>Cập nhật kho</Button>
            </div>

            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.downloadTempFile()}>Tải file mẫu</Button>
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <FileUploader
                accept={['.xlsx']}
                buttonKind="primary"
                buttonLabel="Tải file lên"
                filenameStatus="edit"
                iconDescription="Clear file"
                labelDescription="Tải file cập nhật vật tư lên"
                labelTitle="Upload"
                size="sm"
                multiple={false}
                onChange={(e) => this.setState({ uploadFile: e.target.files[0] })}
              />
            </div>
            <div className="bx--col-lg-4">
              <Button onClick={() => this.updateStockByExcelFile()}>Cập nhật kho bằng file</Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Kết quả tìm kiếm cho ra ${searchResult.length} mục vật tư tương ứng.`}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="productCode">Mã nhà sản xuất</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit">Đơn vị tính</TableHeader>
                      <TableHeader key="engineList">Đầu máy</TableHeader>
                      <TableHeader key="minimumQuantity">Lượng tồn tối thiểu</TableHeader>
                      <TableHeader key="quantity">Lượng tồn trong kho</TableHeader>
                      <TableHeader key="amount" style={{ whiteSpace: 'nowrap' }}>
                        Thành tiền
                      </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialListDisplay.map((material, index) => (
                      <TableRow key={`row-${index.toString()}}`}>
                        <TableCell key={`materialID-${index.toString()}`}>{material.materialID}</TableCell>
                        <TableCell key={`productCode-${index.toString()}`}>{material.productCode}</TableCell>
                        <TableCell key={`materialName-${index.toString()}`}>{material.materialName}</TableCell>
                        <TableCell key={`unit-${index.toString()}`}>{material.unit}</TableCell>
                        <TableCell key={`engineList-${index.toString()}`}>{material.engineList}</TableCell>
                        <TableCell key={`minimumQuantity-${index.toString()}`}>
                          <TextInput
                            id={`minimumQuantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              materialListDisplay[index].minimumQuantity = e.target.value;
                              minimumQuantityErrorMessages[index] = '';
                              this.setState({ materialListDisplay, minimumQuantityErrorMessages });
                            }}
                            value={material.minimumQuantity}
                            invalid={minimumQuantityErrorMessages[index] !== ''}
                            invalidText={minimumQuantityErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`quantity-${index.toString()}`}>
                          <TextInput
                            id={`quantity-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              materialListDisplay[index].quantity = e.target.value;
                              quantityErrorMessages[index] = '';
                              this.setState({ materialListDisplay, quantityErrorMessages });
                            }}
                            value={material.quantity}
                            invalid={quantityErrorMessages[index] !== ''}
                            invalidText={quantityErrorMessages[index]}
                          />
                        </TableCell>
                        <TableCell key={`amount-${index.toString()}`}>
                          <TextInput
                            id={`amount-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              if (e.target.value === '') {
                                e.target.value = '0';
                              }
                              materialListDisplay[index].amount = parseLocaleNumber(e.target.value);
                              amountErrorMessages[index] = '';
                              this.setState({ materialListDisplay, amountErrorMessages });
                            }}
                            value={CurrencyFormatter.format(material.amount)}
                            invalid={amountErrorMessages[index] !== ''}
                            invalidText={amountErrorMessages[index]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[5, 10, 15]}
                totalItems={searchResult.length}
                onChange={(target) => {
                  this.setState({
                    materialListDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                    minimumQuantityErrorMessages: Array(target.pageSize).fill('', 0, target.pageSize),
                    quantityErrorMessages: Array(target.pageSize).fill('', 0, target.pageSize),
                    amountErrorMessages: Array(target.pageSize).fill('', 0, target.pageSize),
                  });
                }}
              />
            </div>
          </div>
          <br />
        </div>
      </div>
    );
  }
}

StockUpdate.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockUpdate);
