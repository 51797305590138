import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComboBox,
  ComposedModal,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NumberInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { CurrencyFormatter } from '../../constants';
import { getPriceList, getStockList } from '../../services';

class PriceAdjust extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceList: [],
      materialID: '',
      month: '01',
      year: '2022',
    };
  }

  componentDidMount = async () => {
    const { auth, setErrorMessage, common, setMaterialList, setLoading } = this.props;
    if (auth.role !== 'phongketoantaichinh') {
      setErrorMessage('Bạn không đủ quyền truy cập trang này');
      return;
    }
    let { materialList } = common;
    if (materialList.length === 0) {
      const getMaterialListResult = await getStockList(auth.companyID);
      materialList = getMaterialListResult.data;
      setMaterialList(materialList);
    }

    let month = '';
    let year = '';
    const currentDate = new Date();
    if (currentDate.getMonth() === 0) {
      month = '12';
      year = currentDate.getFullYear() - 1;
    } else {
      month = String(currentDate.getMonth()).padStart(2, '0');
      year = String(currentDate.getFullYear());
    }
    this.setState({ month, year });

    setLoading(true);
    try {
      const getPriceListResult = await getPriceList(auth.companyID, year.concat('-').concat(month));
      if (getPriceListResult.data.length === 0) {
        setErrorMessage('Chưa tới kì cập nhật hoặc chưa có dữ liệu. Vui lòng thử lại sau.');
        this.setState({ priceList: [] });
      } else {
        this.setState({
          priceList: getPriceListResult.data.map((e) => {
            const selectedMaterial = materialList.find((material) => material.materialID === e.materialID);
            e.materialName = selectedMaterial.materialName;
            e.unit = selectedMaterial.unit;
            return e;
          }),
        });
      }
      setLoading(false);
    } catch {
      setErrorMessage('Có lỗi khi tải trang. Vui lòng thử lại');
      setLoading(false);
    }
  };

  getPriceList = async (adjustTime, materialID) => {
    const { auth, setErrorMessage, setLoading, common } = this.props;
    const { materialList } = common;
    setLoading(true);
    try {
      const getPriceListResult = await getPriceList(auth.companyID, adjustTime);
      const priceList = getPriceListResult.data
        .filter((e) => {
          if (materialID === '') {
            return true;
          }
          return e.materialID === materialID;
        })
        .map((e) => {
          const selectedMaterial = materialList.find((material) => material.materialID === e.materialID);
          e.materialName = selectedMaterial === undefined ? '' : selectedMaterial.materialName;
          e.unit = selectedMaterial === undefined ? '' : selectedMaterial.unit;
          return e;
        });
      if (priceList.length === 0) {
        setErrorMessage('Chưa tới kì cập nhật hoặc chưa có dữ liệu. Vui lòng thử lại sau.');
        this.setState({ priceList: [] });
      } else {
        this.setState({ priceList });
      }
      setLoading(false);
    } catch {
      setErrorMessage('Có lỗi khi tải trang. Vui lòng thử lại');
      setLoading(false);
    }
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const { month, year, priceList, materialID } = this.state;

    const monthList = [
      { id: '01', label: 'Tháng 1' },
      { id: '02', label: 'Tháng 2' },
      { id: '03', label: 'Tháng 3' },
      { id: '04', label: 'Tháng 4' },
      { id: '05', label: 'Tháng 5' },
      { id: '06', label: 'Tháng 6' },
      { id: '07', label: 'Tháng 7' },
      { id: '08', label: 'Tháng 8' },
      { id: '09', label: 'Tháng 9' },
      { id: '10', label: 'Tháng 10' },
      { id: '11', label: 'Tháng 11' },
      { id: '12', label: 'Tháng 12' },
    ];

    const materialIDList = priceList.map((e) => {
      return { id: e.materialID, label: e.materialID.concat(' - ').concat(e.materialName) };
    });

    return (
      <div className="price-list">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Danh sách giá vật tư trong kì</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2">
              <Dropdown
                id="status-Dropdown"
                titleText="Tháng"
                label=""
                items={monthList}
                selectedItem={monthList.find((e) => e.id === month)}
                onChange={(e) => this.setState({ month: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3">
              <NumberInput
                helperText=""
                id="year-NumberInput"
                invalidText="Năm không đúng định dạng"
                label="Năm"
                max={9999}
                min={2022}
                size="lg"
                value={year}
                onChange={(e) => this.setState({ year: e.imaginaryTarget.value })}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="materialID-ComboBox"
                titleText="Mã vật tư"
                label=""
                items={materialIDList}
                selectedItem={materialID === '' ? null : materialIDList.find((e) => e.id === materialID)}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                onChange={(e) => this.setState({ materialID: e.selectedItem == null ? '' : e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2">
              <Button onClick={() => this.getPriceList(year.concat('-').concat(month), materialID)} style={{ marginTop: '1rem' }}>
                Tìm
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-12">
              <TableContainer title={`Danh sách giá vật tư trong ${monthList.find((e) => e.id === month).label.toLocaleLowerCase()} năm ${year} `}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Mã vật tư</TableHeader>
                      <TableHeader>Tên vật tư</TableHeader>
                      <TableHeader>Đơn vị</TableHeader>
                      <TableHeader>Lượng tồn đầu kì</TableHeader>
                      <TableHeader>Tiền tồn đầu kì</TableHeader>
                      <TableHeader>Lượng nhập</TableHeader>
                      <TableHeader>Tiền nhập</TableHeader>
                      <TableHeader>Lượng xuất</TableHeader>
                      <TableHeader>Tiền xuất(tạm tính)</TableHeader>
                      <TableHeader>Giá xuất(tạm tính)</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceList.map((item, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        <TableCell>{item.materialID}</TableCell>
                        <TableCell>{item.materialName}</TableCell>
                        <TableCell>{item.unit}</TableCell>
                        <TableCell>{item.startQuantity}</TableCell>
                        <TableCell>{CurrencyFormatter.format(item.startAmount)}</TableCell>
                        <TableCell>{item.inQuantity}</TableCell>
                        <TableCell>{CurrencyFormatter.format(item.inAmount)}</TableCell>
                        <TableCell>{item.outQuantity}</TableCell>
                        <TableCell>{CurrencyFormatter.format(item.outAmount)}</TableCell>
                        <TableCell>{CurrencyFormatter.format(item.tempOutPrice)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

PriceAdjust.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,

  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceAdjust);
