import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComboBox,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { CurrencyFormatter } from '../../constants';
import { exportDistributeReport, getAccountTitleList, getCategoryList, getCompanyList, getDistributeReport, getEngineList } from '../../services';

class DistributeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyID: props.auth.companyID,
      companyIDs: [],
      filterMaterialType: '',
      filterNo: '',
      filterAccount: '',
      categoryList: [],
      accountList: [],
      engineID: '',
      engineList: [],
      fromDate: this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
      toDate: this.formatDate(new Date()),
      searchResult: [],
      searchResultDisplay: [],
      page: 1,
      pageSize: 20,
    };
  }

  componentDidMount = async () => {
    const { setLoading, auth, setErrorMessage } = this.props;
    if (auth.role !== 'phongketoantaichinh') {
      setErrorMessage('Chỉ có người của phòng tài chính kế toán mới có thể truy cập chức năng này.');
      return;
    }
    setLoading(true);
    const getCompanyListResult = await getCompanyList();
    const getAccountListResult = await getAccountTitleList();
    const getCategoryListResult = await getCategoryList();
    const getEngineListResult = await getEngineList();
    try {
      this.setState({
        companyIDs: [
          { id: '', label: 'Toàn Tổng công ty' },
          ...getCompanyListResult.data.map((e) => {
            return { id: e.companyID, label: e.companyName };
          }),
        ],
        categoryList: [
          { id: '', label: '' },
          ...getCategoryListResult.data.map((e) => {
            return { id: e.categoryID, label: e.categoryID.concat(' - ').concat(e.categoryName) };
          }),
        ],
        accountList: [
          { id: '', label: '' },
          ...getAccountListResult.data.map((e) => {
            return { id: e.accountID, label: e.accountTitle.concat(' - ').concat(e.accountName) };
          }),
        ],
        engineList: getEngineListResult.data
          .filter((e) => (auth.companyID === 'TCT_DS_VN' ? true : e.companyID === auth.companyID))
          .map((e) => {
            return { id: e.engineID, label: e.engineID };
          }),
      });
    } catch {
      setErrorMessage('Có lỗi khi tải trang. Vui lòng thử lại sau.');
    }
    setLoading(false);
  };

  getSearchResultList = async () => {
    const { setLoading, setErrorMessage } = this.props;
    const { fromDate, toDate, filterMaterialType, companyID, filterNo, filterAccount, engineID } = this.state;
    if (fromDate === '' || toDate === '') {
      setErrorMessage('Đầu kì và cuối kì không được bỏ trống');
      return;
    }
    setLoading(true);
    const getDistributeReportResult = await getDistributeReport(companyID, fromDate, toDate, filterMaterialType, filterNo, filterAccount, engineID);
    const searchResult = getDistributeReportResult.data;
    const page = 1;
    const pageSize = 20;
    const searchResultDisplay = searchResult.slice(0, 20);
    this.setState({ searchResult, searchResultDisplay, page, pageSize });
    setLoading(false);
  };

  exportDistributeReport = async () => {
    const { setErrorMessage } = this.props;
    const { companyID, fromDate, toDate, filterMaterialType, filterNo, filterAccount, engineID } = this.state;
    await exportDistributeReport(companyID, fromDate, toDate, filterMaterialType, filterNo, filterAccount, engineID)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bao_cao_phan_bo.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      fromDate,
      toDate,
      filterMaterialType,
      companyID,
      companyIDs,
      categoryList,
      filterNo,
      accountList,
      filterAccount,
      engineList,
      engineID,
      page,
      pageSize,
      searchResultDisplay,
      searchResult,
    } = this.state;

    const materialTypes = [
      { id: '', label: '' },
      { id: '1521', label: 'Kho nguyên vật liệu chính' },
      { id: '1522', label: 'Kho vật liệu xây dựng cơ bản' },
      { id: '1523', label: 'Kho dầu mỡ bôi trơn' },
      { id: '1524', label: 'Kho phụ tùng' },
      { id: '1525', label: 'Kho nhiên liệu' },
      { id: '1526', label: 'Kho nguyên vật liệu phụ' },
      { id: '1527', label: 'Kho phế liệu' },
      { id: '1528', label: 'Kho phụ tùng gia công cơ khí' },
      { id: '1529', label: 'Kho nhiên liệu tồn trên phương tiện' },
      { id: '1531', label: 'Kho công cụ dụng cụ' },
    ];

    return (
      <div className="order-report">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Báo cáo phân bổ nguyên vật liệu</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="companyID-Dropdown"
                titleText="Đơn vị"
                label=""
                items={companyIDs}
                selectedItem={companyIDs.find((e) => e.id === companyID)}
                onChange={(e) => this.setState({ companyID: e.selectedItem.id })}
                disabled={auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-lg-4">
              <Dropdown
                id="filterMaterialType-Dropdown"
                titleText="Kho vật tư (tài khoản kho)"
                label=""
                items={materialTypes}
                selectedItem={filterMaterialType === '' ? null : materialTypes.find((e) => e.id === filterMaterialType)}
                onChange={(e) => this.setState({ filterMaterialType: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <ComboBox
                id="no-ComboBox"
                titleText="Nợ"
                placeholder=""
                label=""
                items={categoryList}
                selectedItem={filterNo === '' ? null : categoryList.find((e) => e.id === filterNo)}
                onChange={(e) => this.setState({ filterNo: e.selectedItem == null ? '' : e.selectedItem.id })}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <ComboBox
                id="category-ComboBox"
                titleText="Khoản mục"
                placeholder=""
                label=""
                items={accountList}
                selectedItem={filterAccount === '' ? null : accountList.find((e) => e.id === filterAccount)}
                onChange={(e) => this.setState({ filterAccount: e.selectedItem == null ? '' : e.selectedItem.id })}
                shouldFilterItem={({ item, inputValue }) => {
                  if (!inputValue) return true;
                  return item.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
              />
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="engineID-Dropdown"
                titleText="Đầu máy"
                label=""
                items={engineList}
                selectedItem={engineID === '' ? null : engineList.find((e) => e.id === engineID)}
                onChange={(e) => this.setState({ engineID: e.selectedItem.id })}
              />
            </div>
            <div className="bx--col-lg-4">
              <DatePicker datePickerType="single" dateFormat="d/m/Y" onChange={(e) => this.setState({ fromDate: this.formatDate(e[0]) })} value={fromDate}>
                <DatePickerInput datePickerType="single" placeholder="dd/MM/yyyy" labelText="Ngày bắt đầu" id="fromDate-datepicker" />
              </DatePicker>
            </div>
            <div className="bx--col-lg-4">
              <DatePicker datePickerType="single" dateFormat="d/m/Y" onChange={(e) => this.setState({ toDate: this.formatDate(e[0]) })} value={toDate}>
                <DatePickerInput datePickerType="single" placeholder="dd/MM/yyyy" labelText="Ngày kết thúc" id="toDate-datepicker" />
              </DatePicker>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Button onClick={() => this.getSearchResultList()} style={{ marginTop: '1rem' }}>
                Tìm
              </Button>
            </div>
            <div className="bx--col-lg-4">
              <Button onClick={() => this.exportDistributeReport()} style={{ marginTop: '1rem' }}>
                Xuất báo cáo
              </Button>
            </div>
          </div>
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title="Kết quả phân bổ vật tư">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader key="account">Mã tài khoản</TableHeader>
                      <TableHeader key="categoryName">Tên khoản mục</TableHeader>
                      <TableHeader key="materialType">Kho</TableHeader>
                      <TableHeader key="engineID">Đầu máy</TableHeader>
                      <TableHeader key="description">Diễn giải</TableHeader>
                      <TableHeader key="total">Tổng tiền</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResultDisplay.map((record, index) => (
                      <TableRow key={`row-${index.toString()}}`}>
                        <TableCell key={`account-${index.toString()}`}>{record.no}</TableCell>
                        <TableCell key={`categoryName-${index.toString()}`}>{record.accountName}</TableCell>
                        <TableCell key={`materialType-${index.toString()}`}>
                          {() => {
                            const materialType = materialTypes.find((e) => e.id === record.materialType);
                            return materialType === undefined ? '' : materialType.label;
                          }}
                        </TableCell>
                        <TableCell key={`engineID-${index.toString()}`}>{record.engineID}</TableCell>
                        <TableCell key={`description-${index.toString()}`}>{record.description}</TableCell>
                        <TableCell key={`total-${index.toString()}`}>{CurrencyFormatter.format(record.totalAmount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[10, 20, 30, 40, 50]}
                totalItems={searchResult.length}
                onChange={(target) => {
                  this.setState({
                    searchResultDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

DistributeReport.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,

  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributeReport);
