/* eslint-disable no-restricted-globals */
import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  Dropdown,
  ModalHeader,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { assignErrorMessage, setLoadingValue, setMaterialListValue, setSubmitValue } from '../../actions/commonAction';
import { materialTypes, repairLevelList } from '../../constants';
import {
  addEngineAnalysisInfo,
  approveEngineAnalysis,
  exportEngineAnalysisReport,
  getEngineAnalysisDetailList,
  getEngineAnalysisInfo,
  getEngineAnalysisList,
  getEngineList,
  getEngineListByCompany,
  getStockList,
  getUserList,
  insertEngineAnalysisDetailList,
} from '../../services';

class EngineAnalysis extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;
    this.state = {
      engineAnalysisInfo: {
        id: '',
        engineAnalysisName: '',
        engineID: '',
        companyID: auth.companyID,
        requestDate: this.formatDate(new Date()),
        repairLevel: '',
        repairDate: this.formatDate(new Date()),
        firstApprover: '',
        secondApprover: '',
        status: 'created',
        requestor: auth.userID,
      },
      engineAnalysisDetailList: [],
      engineAnalysisNameErrorMessage: '',
      engineIDErrorMessage: '',
      engineList: [],
      firstApproverList: [],
      secondApproverList: [],
      thirdApproverList: [],
      firstApproverErrorMessage: '',
      secondApproverErrorMessage: '',
      thirdApproverErrorMessage: '',

      materialList: [],
      searchResult: [],
      materialListDisplay: [],
      page: 1,
      pageSize: 5,
      filterMaterialID: '',
      filterProductCode: '',
      filterMaterialName: '',
      filterMaterialType: '',

      engineAnalysisList: [],
      phandien: [],
      phangam: [],
      phandongco: [],
      phanham: [],
      phancokhi: [],
      phantruyendong: [],
      templateEngineAnalysisID: '',
    };
  }

  componentDidMount = async () => {
    const { setLoading, setErrorMessage, location, auth, common, setMaterialList } = this.props;
    const { engineAnalysisInfo } = this.state;
    const params = new URLSearchParams(location.search);

    setLoading(true);
    let { materialList } = common;
    try {
      if (materialList.length === 0) {
        const getStockListResult = await getStockList(auth.companyID);
        materialList = getStockListResult.data;
        setMaterialList(materialList);
      }
      const getEngineAnalysisListResult = await getEngineAnalysisList(auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID);

      this.setState({
        materialList,
        searchResult: materialList,
        materialListDisplay: materialList.slice(0, 5),
        engineAnalysisList: getEngineAnalysisListResult.data.map((e) => {
          return { id: e.id, label: e.engineAnalysisName };
        }),
      });
    } catch {
      setErrorMessage('Lỗi khi tải trang. Vui lòng thử lại!!!');
      setLoading(false);
      return;
    }

    const getEngineListResult = auth.companyID !== 'TCT_DS_VN' ? await getEngineListByCompany(auth.companyID) : await getEngineList();
    const getApproverListResult =
      auth.companyID !== 'TCT_DS_VN' ? await getUserList('', '', auth.companyID, 'bangiamdoc') : await getUserList('', '', '', 'bangiamdoc');
    let approverList = getApproverListResult.data.map((e) => {
      return { id: e.userID, label: e.username };
    });
    if (engineAnalysisInfo.status === 'created') {
      approverList = approverList.filter((e) => e.id !== auth.userID);
    }

    const getThirdApproverListResult =
      auth.companyID !== 'TCT_DS_VN' ? await getUserList('', '', auth.companyID, 'tongcongty') : await getUserList('', '', '', 'tongcongty');

    this.setState({
      engineList: getEngineListResult.data,
      firstApproverList: approverList,
      secondApproverList: approverList,
      thirdApproverList: getThirdApproverListResult.data.map((e) => {
        return { id: e.userID, label: e.username };
      }),
    });

    if (params != null && params.get('engineAnalysisID') != null) {
      try {
        const engineAnalysisID = params.get('engineAnalysisID');
        const getEngineAnalysisInfoResult = await getEngineAnalysisInfo(engineAnalysisID);
        const getEngineAnalysisDetailsResult = await getEngineAnalysisDetailList(engineAnalysisID);
        const engineAnalysisDetailList = getEngineAnalysisDetailsResult.data.map((e) => {
          const material = materialList.find((item) => item.materialID === e.materialID);
          e.materialName = material.materialName;
          e.unit = material.unit;
          return e;
        });

        this.setState({
          engineAnalysisInfo: getEngineAnalysisInfoResult.data,
          engineAnalysisDetailList,
          phandien: engineAnalysisDetailList.filter((e) => e.part === 'phandien'),
          phangam: engineAnalysisDetailList.filter((e) => e.part === 'phangam'),
          phandongco: engineAnalysisDetailList.filter((e) => e.part === 'phandongco'),
          phanham: engineAnalysisDetailList.filter((e) => e.part === 'phanham'),
          phancokhi: engineAnalysisDetailList.filter((e) => e.part === 'phancokhi'),
          phantruyendong: engineAnalysisDetailList.filter((e) => e.part === 'phantruyendong'),
        });
      } catch (error) {
        setErrorMessage('Mã biên bản giải thể không tồn tại!!!');
        setLoading(false);
        return;
      }
    }

    if (params != null && params.get('templateEngineAnalysisID') != null) {
      try {
        this.setState({ templateEngineAnalysisID: params.get('templateEngineAnalysisID') });
        this.loadTemplate();
      } catch (error) {
        setErrorMessage('Mã biên bản giải thể template không tồn tại!!!');
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  findMaterial = () => {
    const { filterMaterialID, filterProductCode, filterMaterialName, filterMaterialType, pageSize, materialList } = this.state;
    let searchResult = JSON.parse(JSON.stringify(materialList));
    if (filterMaterialID !== '') {
      searchResult = searchResult.filter((e) => e.materialID.includes(filterMaterialID));
    }
    if (filterMaterialName !== '') {
      searchResult = searchResult.filter((e) => e.materialName.toUpperCase().includes(filterMaterialName.toUpperCase()));
    }
    if (filterProductCode !== '') {
      searchResult = searchResult.filter((e) => e.productCode.toUpperCase().includes(filterProductCode.toUpperCase()));
    }
    if (filterMaterialType !== '') {
      searchResult = searchResult.filter((e) => e.materialTypeID === filterMaterialType);
    }
    this.setState({
      searchResult,
      materialListDisplay: searchResult.slice(0, pageSize),
    });
  };

  formatDate = (inputDate) => {
    const yyyy = inputDate.getFullYear().toString();
    const mm = `0${inputDate.getMonth() + 1}`.slice(-2);
    const dd = `0${inputDate.getDate()}`.slice(-2);
    return `${dd}/${mm}/${yyyy}`;
  };

  save = async () => {
    const { setLoading, setErrorMessage, setSubmitResult } = this.props;
    const { engineAnalysisInfo, engineAnalysisDetailList, phandien, phangam, phandongco, phanham, phancokhi, phantruyendong } = this.state;

    let hasError = false;
    if (engineAnalysisInfo.engineAnalysisName === '') {
      hasError = true;
      this.setState({ engineAnalysisNameErrorMessage: 'Tên biên bản không được bỏ trống' });
    }
    if (engineAnalysisInfo.engineID === '') {
      hasError = true;
      this.setState({ engineIDErrorMessage: 'Số hiệu đầu máy không được bỏ trống' });
    }
    if (engineAnalysisInfo.firstApprover === '') {
      hasError = true;
      this.setState({ firstApproverErrorMessage: 'Trưởng phòng phê duyệt không được bỏ trống' });
    }
    if (engineAnalysisInfo.secondApprover === '') {
      hasError = true;
      this.setState({ secondApproverErrorMessage: 'Ban giám đốc phê duyệt không được bỏ trống' });
    }

    if (engineAnalysisDetailList.length === 0) {
      hasError = true;
      this.scrollToTop();
      setErrorMessage('Nội dung biên bản giải thể không thể bỏ trống');
    }

    phandien.forEach((e) => {
      if (e.measure === '' || e.status === '' || e.quantity === '' || isNaN(e.quantity) || Number(e.quantity) < 0) {
        hasError = true;
      }
    });

    phangam.forEach((e) => {
      if (e.measure === '' || e.status === '' || e.quantity === '' || isNaN(e.quantity) || Number(e.quantity) < 0) {
        hasError = true;
      }
    });

    phandongco.forEach((e) => {
      if (e.measure === '' || e.status === '' || e.quantity === '' || isNaN(e.quantity) || Number(e.quantity) < 0) {
        hasError = true;
      }
    });

    phanham.forEach((e) => {
      if (e.measure === '' || e.status === '' || e.quantity === '' || isNaN(e.quantity) || Number(e.quantity) < 0) {
        hasError = true;
      }
    });

    phancokhi.forEach((e) => {
      if (e.measure === '' || e.status === '' || e.quantity === '' || isNaN(e.quantity) || Number(e.quantity) < 0) {
        hasError = true;
      }
    });

    phantruyendong.forEach((e) => {
      if (e.measure === '' || e.status === '' || e.quantity === '' || isNaN(e.quantity) || Number(e.quantity) < 0) {
        hasError = true;
      }
    });

    if (hasError) {
      this.scrollToTop();
      setErrorMessage('Biên bản giải thể không đúng định dạng. Vui lòng kiểm tra lại.');
      return;
    }

    setLoading(true);
    try {
      let engineAnalysisID = engineAnalysisInfo.id;
      if (engineAnalysisInfo.id === '') {
        engineAnalysisInfo.status = 'created';
        const saveEngineAnalysisInfoResult = await addEngineAnalysisInfo(engineAnalysisInfo);
        engineAnalysisID = saveEngineAnalysisInfoResult.data.id;
        this.setState({ engineAnalysisInfo: saveEngineAnalysisInfoResult.data });
      }

      engineAnalysisDetailList.length = 0;
      const updatedEngineAnalysisDetailList = engineAnalysisDetailList
        .concat(phandien)
        .concat(phangam)
        .concat(phandongco)
        .concat(phanham)
        .concat(phancokhi)
        .concat(phantruyendong);

      await insertEngineAnalysisDetailList(engineAnalysisID, updatedEngineAnalysisDetailList);
      setSubmitResult('Biên bản giải thể đầu máy đã được lưu thành công');
    } catch (error) {
      this.scrollToTop();
      setErrorMessage('Lỗi khi thêm biên bản giải thể. Vui lòng kiểm tra lại.');
    }
    setLoading(false);
  };

  exportReport = async () => {
    const { setErrorMessage } = this.props;
    const { engineAnalysisInfo } = this.state;
    await exportEngineAnalysisReport(engineAnalysisInfo.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bien_ban_giai_the.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setErrorMessage('Có lỗi xảy ra khi xuất file báo cáo. Vui lòng thử lại');
      });
  };

  addEngineAnalysisDetail = (material, part) => {
    const { engineAnalysisDetailList, engineAnalysisInfo, phandien, phangam, phandongco, phanham, phancokhi, phantruyendong } = this.state;
    const component = {
      id: '',
      engineAnalysisID: engineAnalysisInfo.id,
      materialID: material.materialID,
      materialName: material.materialName,
      unit: material.unit,
      part,
      quantity: 1,
      status: '',
      measure: '',
    };
    if (part === 'phandien') {
      phandien.push(component);
    }
    if (part === 'phangam') {
      phangam.push(component);
    }
    if (part === 'phandongco') {
      phandongco.push(component);
    }
    if (part === 'phanham') {
      phanham.push(component);
    }
    if (part === 'phancokhi') {
      phancokhi.push(component);
    }
    if (part === 'phantruyendong') {
      phantruyendong.push(component);
    }
    engineAnalysisDetailList.push(component);
    this.setState({ engineAnalysisDetailList, phandien, phangam, phandongco, phanham, phancokhi, phantruyendong });
  };

  getEngineAnalysisInfoStatus = (status) => {
    if (status === 'created') {
      return 'Đã được khởi tạo';
    }
    if (status === 'half-approved') {
      return 'Đã được trưởng phòng phê duyệt';
    }
    if (status === 'full-approved') {
      return 'Đã được ban giám đóc phê duyệt';
    }
    if (status === 'complete-approved') {
      return 'Đã được Tổng công ty phê duyệt';
    }
    return '';
  };

  firstApprove = async () => {
    const { engineAnalysisInfo } = this.state;
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    setLoading(true);
    try {
      const getEngineAnalysisInfoResult = await approveEngineAnalysis(engineAnalysisInfo.id, 'half-approved');
      this.setState({ engineAnalysisInfo: getEngineAnalysisInfoResult.data });
    } catch {
      setErrorMessage('Có lỗi khi phê duyệt. Vui lòng thử lại');
      setLoading(false);
      return;
    }
    setLoading(false);
    setSubmitResult('Biên bản giải thể đầu máy đã được phê duyệt bởi trưởng phòng kỹ thuật');
  };

  secondApprove = async () => {
    const { engineAnalysisInfo } = this.state;
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    setLoading(true);
    try {
      const getEngineAnalysisInfoResult = await approveEngineAnalysis(engineAnalysisInfo.id, 'full-approved');
      this.setState({ engineAnalysisInfo: getEngineAnalysisInfoResult.data });
    } catch {
      setErrorMessage('Có lỗi khi phê duyệt. Vui lòng thử lại');
      setLoading(false);
      return;
    }
    setLoading(false);
    setSubmitResult('Biên bản giải thể đầu máy đã được phê duyệt bởi ban giám đốc');
  };

  thirdApprove = async () => {
    const { engineAnalysisInfo } = this.state;
    const { setErrorMessage, setLoading, setSubmitResult } = this.props;
    setLoading(true);
    try {
      const getEngineAnalysisInfoResult = await approveEngineAnalysis(engineAnalysisInfo.id, 'complete-approved');
      this.setState({ engineAnalysisInfo: getEngineAnalysisInfoResult.data });
    } catch {
      setErrorMessage('Có lỗi khi phê duyệt. Vui lòng thử lại');
      setLoading(false);
      return;
    }
    setLoading(false);
    setSubmitResult('Biên bản giải thể đầu máy đã được phê duyệt bởi Tổng công ty');
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  loadTemplate = async () => {
    const { templateEngineAnalysisID, materialList } = this.state;
    const { auth, setErrorMessage, setLoading } = this.props;
    if (templateEngineAnalysisID === '') {
      return;
    }
    setLoading(true);
    try {
      const getEngineAnalysisInfoResult = await getEngineAnalysisInfo(templateEngineAnalysisID);
      const getEngineAnalysisDetailsResult = await getEngineAnalysisDetailList(templateEngineAnalysisID);
      const engineAnalysisInfo = getEngineAnalysisInfoResult.data;
      engineAnalysisInfo.id = '';
      engineAnalysisInfo.status = 'created';
      engineAnalysisInfo.requestor = auth.userID;
      engineAnalysisInfo.engineAnalysisName = '';

      const engineAnalysisDetailList = getEngineAnalysisDetailsResult.data.map((e) => {
        const material = materialList.find((item) => item.materialID === e.materialID);
        e.materialName = material.materialName;
        e.unit = material.unit;
        e.id = '';
        e.engineAnalysisID = '';
        return e;
      });
      this.setState({
        engineAnalysisInfo,
        engineAnalysisDetailList,
        phandien: engineAnalysisDetailList.filter((e) => e.part === 'phandien'),
        phangam: engineAnalysisDetailList.filter((e) => e.part === 'phangam'),
        phandongco: engineAnalysisDetailList.filter((e) => e.part === 'phandongco'),
        phanham: engineAnalysisDetailList.filter((e) => e.part === 'phanham'),
        phancokhi: engineAnalysisDetailList.filter((e) => e.part === 'phancokhi'),
        phantruyendong: engineAnalysisDetailList.filter((e) => e.part === 'phantruyendong'),
      });
    } catch {
      setErrorMessage('Có lỗi khi tải bản mẫu. Vui lòng thử lại');
    }
    setLoading(false);
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const {
      engineAnalysisInfo,
      engineAnalysisDetailList,
      engineAnalysisNameErrorMessage,
      engineIDErrorMessage,
      engineList,
      firstApproverList,
      secondApproverList,
      thirdApproverList,
      filterMaterialID,
      filterProductCode,
      filterMaterialName,
      filterMaterialType,
      materialListDisplay,
      searchResult,
      page,
      pageSize,
      firstApproverErrorMessage,
      secondApproverErrorMessage,
      thirdApproverErrorMessage,
      engineAnalysisList,
      templateEngineAnalysisID,
      phandien,
      phangam,
      phandongco,
      phanham,
      phancokhi,
      phantruyendong,
    } = this.state;

    const engineIDList = engineList.map((e) => {
      return { id: e.engineID, label: e.engineID };
    });

    if (engineAnalysisInfo.id !== '' && engineAnalysisInfo.firstApprover === auth.userID) {
      firstApproverList.push({ id: auth.userID, label: auth.username });
    }
    if (engineAnalysisInfo.id !== '' && engineAnalysisInfo.secondApprover === auth.userID) {
      secondApproverList.push({ id: auth.userID, label: auth.username });
    }
    if (engineAnalysisInfo.id !== '' && engineAnalysisInfo.thirdApprover === auth.userID) {
      thirdApproverList.push({ id: auth.userID, label: auth.username });
    }

    const measureList = [
      { id: 'Thay mới', label: 'Thay mới' },
      { id: 'Lĩnh mới', label: 'Lĩnh mới' },
      { id: 'Thay khôi phục', label: 'Thay khôi phục' },
    ];

    return (
      <div className="engine-analyst">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push(`/engine/analysis?engineAnalysisID=${engineAnalysisInfo.id}`);
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Biên bản giải thể đầu máy</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="engineAnalysisName-TextInput"
                placeholder=""
                labelText="Tên biên bản giải thể"
                value={engineAnalysisInfo.engineAnalysisName}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    engineAnalysisInfo: { ...prevState.engineAnalysisInfo, engineAnalysisName: e.target.value },
                    engineAnalysisNameErrorMessage: '',
                  }))
                }
                invalid={engineAnalysisNameErrorMessage !== ''}
                invalidText={engineAnalysisNameErrorMessage}
                readOnly={engineAnalysisInfo.id !== ''}
              />
            </div>
            <div className="bx--col-lg-4">
              {engineAnalysisInfo.id !== '' && (
                <TextInput id="engineID-TextInput" placeholder="" labelText="Số hiệu đầu máy" value={engineAnalysisInfo.engineID} readOnly />
              )}
              {engineAnalysisInfo.id === '' && (
                <Dropdown
                  id="engineID-Dropdown"
                  titleText="Số hiệu đầu máy"
                  label=""
                  helperText={engineAnalysisInfo.engineID === '' ? '' : engineList.find((e) => e.engineID === engineAnalysisInfo.engineID).engineType}
                  items={engineIDList}
                  selectedItem={engineAnalysisInfo.engineID === '' ? null : engineIDList.find((e) => e.id === engineAnalysisInfo.engineID)}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      engineAnalysisInfo: { ...prevState.engineAnalysisInfo, engineID: e.selectedItem == null ? '' : e.selectedItem.id },
                      engineIDErrorMessage: '',
                    }))
                  }
                  invalid={engineIDErrorMessage !== ''}
                  invalidText={engineIDErrorMessage}
                  // disabled={engineAnalysisInfo.id !== ''}
                />
              )}
            </div>
            <div className="bx--col-lg-4">
              {engineAnalysisInfo.id !== '' && (
                <TextInput id="repairLevel-TextInput" placeholder="" labelText="Cấp sửa chữa" value={engineAnalysisInfo.repairLevel} readOnly />
              )}
              {engineAnalysisInfo.id === '' && (
                <Dropdown
                  id="repairLevel-Dropdown"
                  titleText="Cấp sửa chữa"
                  label=""
                  items={repairLevelList}
                  selectedItem={engineAnalysisInfo.repairLevel === '' ? undefined : repairLevelList.find((e) => e.id === engineAnalysisInfo.repairLevel)}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      engineAnalysisInfo: {
                        ...prevState.engineAnalysisInfo,
                        repairLevel: e.selectedItem == null ? '' : e.selectedItem.id,
                        thirdApprover:
                          e.selectedItem.id === 'R2' || e.selectedItem.id === 'Rk' || e.selectedItem.id === 'Đại tu'
                            ? prevState.engineAnalysisInfo.thirdApprover
                            : '',
                      },
                    }))
                  }
                  // disabled={engineAnalysisInfo.id !== ''}
                />
              )}
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              {engineAnalysisInfo.id !== '' && (
                <TextInput
                  id="first-approver-TextInput"
                  placeholder=""
                  labelText="Trưởng phòng phê duyệt"
                  value={
                    engineAnalysisInfo.firstApprover === '' || engineAnalysisInfo.firstApprover == null
                      ? ''
                      : firstApproverList.find((e) => e.id === engineAnalysisInfo.firstApprover).label
                  }
                  readOnly
                />
              )}
              {engineAnalysisInfo.id === '' && (
                <Dropdown
                  id="first-approver-Dropdown"
                  titleText="Trưởng phòng phê duyệt"
                  label=""
                  items={firstApproverList}
                  selectedItem={engineAnalysisInfo.firstApprover === '' ? null : firstApproverList.find((e) => e.id === engineAnalysisInfo.firstApprover)}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      engineAnalysisInfo: { ...prevState.engineAnalysisInfo, firstApprover: e.selectedItem.id },
                      firstApproverErrorMessage: '',
                    }))
                  }
                  invalid={firstApproverErrorMessage !== ''}
                  invalidText={firstApproverErrorMessage}
                  // disabled={engineAnalysisInfo.id !== ''}
                />
              )}
            </div>
            <div className="bx--col-lg-4">
              {engineAnalysisInfo.id !== '' && (
                <TextInput
                  id="second-approver-TextInput"
                  placeholder=""
                  labelText="Ban giám đốc phê duyệt"
                  value={
                    engineAnalysisInfo.secondApprover === '' || engineAnalysisInfo.secondApprover == null
                      ? ''
                      : secondApproverList.find((e) => e.id === engineAnalysisInfo.secondApprover).label
                  }
                  readOnly
                />
              )}
              {engineAnalysisInfo.id === '' && (
                <Dropdown
                  id="second-approver-Dropdown"
                  titleText="Ban giám đốc phê duyệt"
                  label=""
                  items={secondApproverList}
                  selectedItem={engineAnalysisInfo.secondApprover === '' ? null : secondApproverList.find((e) => e.id === engineAnalysisInfo.secondApprover)}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      engineAnalysisInfo: { ...prevState.engineAnalysisInfo, secondApprover: e.selectedItem.id },
                      secondApproverErrorMessage: '',
                    }))
                  }
                  invalid={secondApproverErrorMessage !== ''}
                  invalidText={secondApproverErrorMessage}
                  // disabled={engineAnalysisInfo.id !== ''}
                />
              )}
            </div>
            <div className="bx--col-lg-4">
              {engineAnalysisInfo.id !== '' && (
                <TextInput
                  id="third-approver-TextInput"
                  placeholder=""
                  labelText="Tổng công ty phê duyệt"
                  value={
                    engineAnalysisInfo.thirdApprover === '' || engineAnalysisInfo.thirdApprover == null
                      ? ''
                      : thirdApproverList.find((e) => e.id === engineAnalysisInfo.thirdApprover).label
                  }
                  readOnly
                />
              )}
              {engineAnalysisInfo.id === '' && (
                <Dropdown
                  id="third-approver-Dropdown"
                  titleText="Tổng công ty phê duyệt"
                  label=""
                  items={thirdApproverList}
                  selectedItem={engineAnalysisInfo.thirdApprover === '' ? null : thirdApproverList.find((e) => e.id === engineAnalysisInfo.thirdApprover)}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      engineAnalysisInfo: { ...prevState.engineAnalysisInfo, thirdApprover: e.selectedItem.id },
                      thirdApproverErrorMessage: '',
                    }))
                  }
                  invalid={thirdApproverErrorMessage !== ''}
                  invalidText={thirdApproverErrorMessage}
                  // disabled={
                  //   (engineAnalysisInfo.repairLevel !== 'R2' && engineAnalysisInfo.repairLevel !== 'Rk' && engineAnalysisInfo.repairLevel !== 'Đại tu') ||
                  //   engineAnalysisInfo.id !== ''
                  // }
                />
              )}
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => this.setState((prevState) => ({ engineAnalysisInfo: { ...prevState.engineAnalysisInfo, requestDate: this.formatDate(e[0]) } }))}
                value={engineAnalysisInfo.requestDate}
              >
                <DatePickerInput
                  datePickerType="single"
                  placeholder="dd/mm/yyyy"
                  labelText="Ngày lập biên bản giải thể"
                  id="requestDate-datepicker"
                  disabled={engineAnalysisInfo.id !== ''}
                />
              </DatePicker>
            </div>
            <div className="bx--col-lg-4">
              <DatePicker
                datePickerType="single"
                dateFormat="d/m/Y"
                onChange={(e) => this.setState((prevState) => ({ engineAnalysisInfo: { ...prevState.engineAnalysisInfo, repairDate: this.formatDate(e[0]) } }))}
                value={engineAnalysisInfo.repairDate}
              >
                <DatePickerInput
                  datePickerType="single"
                  placeholder="dd/mm/yyyy"
                  labelText="Ngày vào sửa chữa"
                  id="repairDate-datepicker"
                  disabled={engineAnalysisInfo.id !== ''}
                />
              </DatePicker>
            </div>
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <TextInput
                id="status-TextInput"
                placeholder=""
                labelText="Trạng thái biên bản giải thể"
                value={this.getEngineAnalysisInfoStatus(engineAnalysisInfo.status)}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    engineAnalysisInfo: { ...prevState.engineAnalysisInfo, status: e.target.value },
                  }))
                }
                readOnly
              />
            </div>

            {engineAnalysisInfo.status === 'created' && engineAnalysisInfo.requestor === auth.userID && (
              <div className="bx--col-lg-3 bx--col-md-3">
                <Dropdown
                  id="second-approver-Dropdown"
                  titleText="Mẫu BBGT"
                  label=""
                  items={engineAnalysisList}
                  selectedItem={templateEngineAnalysisID !== '' ? engineAnalysisList.find((e) => e.id.toString() === templateEngineAnalysisID) : null}
                  onChange={(e) => this.setState({ templateEngineAnalysisID: e.selectedItem.id })}
                  invalid={secondApproverErrorMessage !== ''}
                  invalidText={secondApproverErrorMessage}
                  disabled={engineAnalysisInfo.id !== ''}
                />
              </div>
            )}
            {engineAnalysisInfo.status === 'created' && engineAnalysisInfo.requestor === auth.userID && (
              <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1">
                <Button onClick={() => this.loadTemplate()} style={{ marginTop: '1rem' }}>
                  Tải
                </Button>
              </div>
            )}
            {engineAnalysisInfo.firstApprover === auth.userID && engineAnalysisInfo.status === 'created' && (
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.firstApprove()} style={{ marginTop: '1rem' }}>
                  Trưởng phòng phê duyệt
                </Button>
              </div>
            )}
            {engineAnalysisInfo.secondApprover === auth.userID && engineAnalysisInfo.status === 'half-approved' && (
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.secondApprove()} style={{ marginTop: '1rem' }}>
                  Ban giám đốc phê duyệt
                </Button>
              </div>
            )}
            {engineAnalysisInfo.thirdApprover === auth.userID && engineAnalysisInfo.status === 'full-approved' && (
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.thirdApprove()} style={{ marginTop: '1rem' }}>
                  Tổng công ty phê duyệt
                </Button>
              </div>
            )}
          </div>
          <br />
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Button
                onClick={() => this.save()}
                style={{ marginTop: '1rem' }}
                disabled={
                  auth.companyID !== engineAnalysisInfo.companyID ||
                  engineAnalysisInfo.status === 'full-approved' ||
                  engineAnalysisInfo.status === 'complete-approved' ||
                  (auth.userID !== engineAnalysisInfo.requestor &&
                    auth.userID !== engineAnalysisInfo.firstApprover &&
                    auth.userID !== engineAnalysisInfo.secondApprover)
                }
              >
                Lưu thông tin
              </Button>
            </div>
            <div className="bx--col-lg-4">
              <Button onClick={() => this.exportReport()} style={{ marginTop: '1rem', marginLeft: '1rem' }} disabled={engineAnalysisInfo.id === ''}>
                Xuất ra file
              </Button>
            </div>
          </div>
          <hr className="LeftNav-module--divider--1Z49I" />
          <br />
        </div>
        {auth.role === 'phongkythuat' && engineAnalysisInfo.status === 'created' && (
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-lg-4">
                <TextInput
                  id="filterMaterialID-TextInput"
                  placeholder="Vui lòng nhập một phần mã vật tư để tìm kiếm"
                  labelText="Mã vật tư"
                  value={filterMaterialID}
                  onChange={(e) => this.setState({ filterMaterialID: e.target.value })}
                />
              </div>
              <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
              <div className="bx--col-lg-4">
                <TextInput
                  id="filterProductCode-TextInput"
                  placeholder="Vui lòng nhập một phần mã nhà sản xuất để tìm kiếm"
                  labelText="Mã nhà sản xuất"
                  value={filterProductCode}
                  onChange={(e) => this.setState({ filterProductCode: e.target.value })}
                />
              </div>
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button onClick={() => this.findMaterial()} style={{ marginTop: '1rem' }}>
                  Tìm
                </Button>
              </div>
            </div>
            <br />
            <div className="bx--row">
              <div className="bx--col-lg-4">
                <TextInput
                  id="filterMaterialName-TextInput"
                  placeholder="Vui lòng nhập một phần tên vật tư để tìm kiếm"
                  labelText="Tên vật tư"
                  value={filterMaterialName}
                  onChange={(e) => this.setState({ filterMaterialName: e.target.value })}
                />
              </div>
              <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
              <div className="bx--col-lg-4">
                <Dropdown
                  id="filterMaterialType-Dropdown"
                  titleText="Kho vật tư (tài khoản kho)"
                  label=""
                  items={materialTypes}
                  selectedItem={filterMaterialType === '' ? null : materialTypes.find((e) => e.id === filterMaterialType)}
                  onChange={(e) => this.setState({ filterMaterialType: e.selectedItem.id })}
                />
              </div>
              <div className="bx--col-lg-3 bx--col-md-3">
                <Button
                  style={{ marginTop: '1rem' }}
                  onClick={() => this.setState({ filterMaterialID: '', filterProductCode: '', filterMaterialName: '', filterMaterialType: '' })}
                >
                  Xoá bộ lọc
                </Button>
              </div>
            </div>

            <br />
            <hr className="LeftNav-module--divider--1Z49I" />
            <div className="bx--row">
              <div className="bx--col-lg-2 bx--col-md-2" />
              <div className="bx--col-lg-12">
                <TableContainer title={`Kết quả tìm kiếm cho ra ${searchResult.length} mục vật tư tương ứng.`}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeader key="materialID">Mã vật tư</TableHeader>
                        <TableHeader key="productCode">Mã nhà sản xuất</TableHeader>
                        <TableHeader key="materialName">Tên vật tư</TableHeader>
                        <TableHeader key="engineList">Đầu máy</TableHeader>
                        <TableHeader key="unit">Đơn vị tính</TableHeader>
                        <TableHeader key="minimumQuantity">Lượng tồn tối thiểu</TableHeader>
                        <TableHeader key="quantity">Lượng tồn trong kho</TableHeader>
                        <TableHeader style={{ minWidth: '12%' }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialListDisplay.map((material, index) => (
                        <TableRow key={`row-${index.toString()}}`}>
                          <TableCell key={`materialID-${index.toString()}`}>{material.materialID}</TableCell>
                          <TableCell key={`productCode-${index.toString()}`}>{material.productCode}</TableCell>
                          <TableCell key={`materialName-${index.toString()}`}>{material.materialName}</TableCell>
                          <TableCell key={`engineList-${index.toString()}`}>{material.engineList}</TableCell>
                          <TableCell key={`unit-${index.toString()}`}>{material.unit}</TableCell>
                          <TableCell key={`minimumQuantity-${index.toString()}`}>{material.minimumQuantity}</TableCell>
                          <TableCell key={`quantity-${index.toString()}`}>{material.quantity}</TableCell>
                          <TableCell key={`action-${index.toString()}`}>
                            <OverflowMenu ariaLabel="overflow-menu" size="md">
                              <OverflowMenuItem
                                itemText="Phần Điện"
                                onClick={() => this.addEngineAnalysisDetail(material, 'phandien')}
                                disabled={engineAnalysisDetailList.find((e) => e.materialID === material.materialID && e.part === 'phandien') != null}
                              />
                              <OverflowMenuItem
                                itemText="Phần Khung Gầm"
                                onClick={() => this.addEngineAnalysisDetail(material, 'phangam')}
                                disabled={engineAnalysisDetailList.find((e) => e.materialID === material.materialID && e.part === 'phangam') != null}
                              />
                              <OverflowMenuItem
                                itemText="Phần Động Cơ"
                                onClick={() => this.addEngineAnalysisDetail(material, 'phandongco')}
                                disabled={engineAnalysisDetailList.find((e) => e.materialID === material.materialID && e.part === 'phandongco') != null}
                              />
                              <OverflowMenuItem
                                itemText="Phần Hãm"
                                onClick={() => this.addEngineAnalysisDetail(material, 'phanham')}
                                disabled={engineAnalysisDetailList.find((e) => e.materialID === material.materialID && e.part === 'phanham') != null}
                              />
                              <OverflowMenuItem
                                itemText="Phần Cơ Khí"
                                onClick={() => this.addEngineAnalysisDetail(material, 'phancokhi')}
                                disabled={engineAnalysisDetailList.find((e) => e.materialID === material.materialID && e.part === 'phancokhi') != null}
                              />
                              <OverflowMenuItem
                                itemText="Phần Truyền Động"
                                onClick={() => this.addEngineAnalysisDetail(material, 'phantruyendong')}
                                disabled={engineAnalysisDetailList.find((e) => e.materialID === material.materialID && e.part === 'phantruyendong') != null}
                              />
                            </OverflowMenu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  className="fixed-pagination"
                  backwardText="Previous page"
                  forwardText="Next page"
                  itemsPerPageText="Items per page:"
                  page={page}
                  pageNumberText="Page Number"
                  pageSize={pageSize}
                  pageSizes={[5, 10, 15]}
                  totalItems={searchResult.length}
                  onChange={(target) => {
                    this.setState({
                      materialListDisplay: searchResult.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                      page: target.page,
                      pageSize: target.pageSize,
                    });
                  }}
                />
              </div>
            </div>
            <br />
          </div>
        )}
        <br />
        <div className="bx--grid">
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title="Chi tiết danh mục giải thể">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader style={{ width: '5%' }} />
                      <TableHeader key="stt" style={{ width: '5%' }}>
                        STT
                      </TableHeader>
                      <TableHeader key="materialID">Mã vật tư</TableHeader>
                      <TableHeader key="materialName">Tên vật tư</TableHeader>
                      <TableHeader key="unit" style={{ width: '5%' }}>
                        Đơn vị
                      </TableHeader>
                      <TableHeader key="quantity" style={{ width: '10%' }}>
                        Số lượng
                      </TableHeader>
                      <TableHeader key="status" style={{ width: '12.5%' }}>
                        Trạng thái giải thể
                      </TableHeader>
                      <TableHeader key="measure" style={{ width: '12.5%' }}>
                        Biện pháp
                      </TableHeader>
                      <TableHeader style={{ width: '7.5%' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="phandien">
                      <TableCell colSpan={9}>
                        <strong>Phần Điện</strong>
                      </TableCell>
                    </TableRow>
                    {phandien.map((row, index) => (
                      <TableRow key={`row-phandien-${index.toString()}`}>
                        <TableCell />
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.materialID}</TableCell>
                        <TableCell>{row.materialName}</TableCell>
                        <TableCell>{row.unit}</TableCell>
                        <TableCell>
                          <TextInput
                            id={`quantity-phandien-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phandien[index].quantity = e.target.value;
                              this.setState({ phandien });
                            }}
                            value={phandien[index].quantity}
                            invalidText="Số lượng không hợp lệ"
                            invalid={phandien[index].quantity !== '' && (isNaN(phandien[index].quantity) || Number(phandien[index].quantity) < 0)}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <TextInput
                            id={`status-phandien-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phandien[index].status = e.target.value;
                              this.setState({ phandien });
                            }}
                            value={phandien[index].status}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Dropdown
                            id={`measure-phandien-Dropdown-${index}`}
                            titleText=""
                            label=""
                            items={measureList}
                            selectedItem={phandien[index].measure === '' ? null : measureList.find((e) => e.id === phandien[index].measure)}
                            onChange={(e) => {
                              phandien[index].measure = e.selectedItem.id;
                              this.setState({ phandien });
                            }}
                            disabled={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              this.setState({
                                phandien: phandien.filter((e) => e.materialID !== row.materialID),
                                engineAnalysisDetailList: engineAnalysisDetailList.filter((e) => e.materialID !== row.materialID),
                              });
                            }}
                            disabled={
                              auth.companyID !== engineAnalysisInfo.companyID ||
                              engineAnalysisInfo.status === 'full-approved' ||
                              engineAnalysisInfo.status === 'complete-approved' ||
                              (auth.userID !== engineAnalysisInfo.requestor &&
                                auth.userID !== engineAnalysisInfo.firstApprover &&
                                auth.userID !== engineAnalysisInfo.secondApprover)
                            }
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="phangam">
                      <TableCell colSpan={9}>
                        <strong>Phần Khung gầm</strong>
                      </TableCell>
                    </TableRow>
                    {phangam.map((row, index) => (
                      <TableRow key={`row-phangam-${index.toString()}`}>
                        <TableCell />
                        <TableCell>{index + phandien.length + 1}</TableCell>
                        <TableCell>{row.materialID}</TableCell>
                        <TableCell>{row.materialName}</TableCell>
                        <TableCell>{row.unit}</TableCell>
                        <TableCell>
                          <TextInput
                            id={`quantity-phangam-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phangam[index].quantity = e.target.value;
                              this.setState({ phangam });
                            }}
                            value={phangam[index].quantity}
                            invalidText="Số lượng không hợp lệ"
                            invalid={phangam[index].quantity !== '' && (isNaN(phangam[index].quantity) || Number(phangam[index].quantity) < 0)}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <TextInput
                            id={`status-phangam-textinput-${index.toString()}`}
                            labelText=""
                            onChange={(e) => {
                              phangam[index].status = e.target.value;
                              this.setState({ phangam });
                            }}
                            value={phangam[index].status}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Dropdown
                            id={`measure-phangam-Dropdown-${index.toString()}`}
                            titleText=""
                            label=""
                            items={measureList}
                            selectedItem={phangam[index].measure === '' ? null : measureList.find((e) => e.id === phangam[index].measure)}
                            onChange={(e) => {
                              phangam[index].measure = e.selectedItem.id;
                              this.setState({ phangam });
                            }}
                            disabled={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              this.setState({
                                phangam: phangam.filter((e) => e.materialID !== row.materialID),
                                engineAnalysisDetailList: engineAnalysisDetailList.filter((e) => e.materialID !== row.materialID),
                              });
                            }}
                            disabled={
                              auth.companyID !== engineAnalysisInfo.companyID ||
                              engineAnalysisInfo.status === 'full-approved' ||
                              engineAnalysisInfo.status === 'complete-approved' ||
                              (auth.userID !== engineAnalysisInfo.requestor &&
                                auth.userID !== engineAnalysisInfo.firstApprover &&
                                auth.userID !== engineAnalysisInfo.secondApprover)
                            }
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="phandongco">
                      <TableCell colSpan={9}>
                        <strong>Phần Động cơ</strong>
                      </TableCell>
                    </TableRow>
                    {engineAnalysisDetailList
                      .filter((e) => e.part === 'phandongco')
                      .map((row, index) => (
                        <TableRow key={`row-phandongco-${index.toString()}`}>
                          <TableCell />
                          <TableCell>{index + phandien.length + phangam.length + 1}</TableCell>
                          <TableCell>{row.materialID}</TableCell>
                          <TableCell>{row.materialName}</TableCell>
                          <TableCell>{row.unit}</TableCell>
                          <TableCell>
                            <TextInput
                              id={`quantity-phandongco-textinput-${index.toString()}`}
                              labelText=""
                              onChange={(e) => {
                                if (e.target.value !== '' && (isNaN(e.target.value) || Number(e.target.value) < 0)) {
                                  return;
                                }
                                phandongco[index].quantity = e.target.value;
                                this.setState({ phandongco });
                              }}
                              value={phandongco[index].quantity}
                              invalidText="Số lượng không hợp lệ"
                              invalid={phandongco[index].quantity !== '' && (isNaN(phandongco[index].quantity) || Number(phandongco[index].quantity) < 0)}
                              readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                            />
                          </TableCell>
                          <TableCell>
                            <TextInput
                              id={`status-phandongco-textinput-${index.toString()}`}
                              labelText=""
                              onChange={(e) => {
                                phandongco[index].status = e.target.value;
                                this.setState({ phandongco });
                              }}
                              value={phandongco[index].status}
                              readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                            />
                          </TableCell>
                          <TableCell>
                            <Dropdown
                              id={`measure-phandongco-Dropdown-${index}`}
                              titleText=""
                              label=""
                              items={measureList}
                              selectedItem={phandongco[index].measure === '' ? null : measureList.find((e) => e.id === phandongco[index].measure)}
                              onChange={(e) => {
                                phandongco[index].measure = e.selectedItem.id;
                                this.setState({ phandongco });
                              }}
                              disabled={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => {
                                this.setState({
                                  phandongco: phandongco.filter((e) => e.materialID !== row.materialID),
                                  engineAnalysisDetailList: engineAnalysisDetailList.filter((e) => e.materialID !== row.materialID),
                                });
                              }}
                              disabled={
                                auth.companyID !== engineAnalysisInfo.companyID ||
                                engineAnalysisInfo.status === 'full-approved' ||
                                engineAnalysisInfo.status === 'complete-approved' ||
                                (auth.userID !== engineAnalysisInfo.requestor &&
                                  auth.userID !== engineAnalysisInfo.firstApprover &&
                                  auth.userID !== engineAnalysisInfo.secondApprover)
                              }
                            >
                              Xoá
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow key="phanham">
                      <TableCell colSpan={9}>
                        <strong>Phần Hãm</strong>
                      </TableCell>
                    </TableRow>
                    {phanham.map((row, index) => (
                      <TableRow key={`row-phanham-${index.toString()}`}>
                        <TableCell />
                        <TableCell>{index + phandien.length + phangam.length + phandongco.length + 1}</TableCell>
                        <TableCell>{row.materialID}</TableCell>
                        <TableCell>{row.materialName}</TableCell>
                        <TableCell>{row.unit}</TableCell>
                        <TableCell>
                          <TextInput
                            id={`quantity-phanham-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phanham[index].quantity = e.target.value;
                              this.setState({ phanham });
                            }}
                            value={phanham[index].quantity}
                            invalidText="Số lượng không hợp lệ"
                            invalid={phanham[index].quantity !== '' && (isNaN(phanham[index].quantity) || Number(phanham[index].quantity) < 0)}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <TextInput
                            id={`status-phanham-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phanham[index].status = e.target.value;
                              this.setState({ phanham });
                            }}
                            value={phanham[index].status}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Dropdown
                            id={`measure-phanham-Dropdown-${index}`}
                            titleText=""
                            label=""
                            items={measureList}
                            selectedItem={phanham[index].measure === '' ? null : measureList.find((e) => e.id === phanham[index].measure)}
                            onChange={(e) => {
                              phanham[index].measure = e.selectedItem.id;
                              this.setState({ phanham });
                            }}
                            disabled={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              this.setState({
                                phanham: phanham.filter((e) => e.materialID !== row.materialID),
                                engineAnalysisDetailList: engineAnalysisDetailList.filter((e) => e.materialID !== row.materialID),
                              });
                            }}
                            disabled={
                              auth.companyID !== engineAnalysisInfo.companyID ||
                              engineAnalysisInfo.status === 'full-approved' ||
                              engineAnalysisInfo.status === 'complete-approved' ||
                              (auth.userID !== engineAnalysisInfo.requestor &&
                                auth.userID !== engineAnalysisInfo.firstApprover &&
                                auth.userID !== engineAnalysisInfo.secondApprover)
                            }
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="phancokhi">
                      <TableCell colSpan={9}>
                        <strong>Phần Cơ khí</strong>
                      </TableCell>
                    </TableRow>
                    {phancokhi.map((row, index) => (
                      <TableRow key={`row-phancokhi-${index.toString()}`}>
                        <TableCell />
                        <TableCell>{index + phandien.length + phangam.length + phandongco.length + phanham.length + 1}</TableCell>
                        <TableCell>{row.materialID}</TableCell>
                        <TableCell>{row.materialName}</TableCell>
                        <TableCell>{row.unit}</TableCell>
                        <TableCell>
                          <TextInput
                            id={`quantity-phancokhi-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phancokhi[index].quantity = Number(e.target.value);
                              this.setState({ phancokhi });
                            }}
                            value={phancokhi[index].quantity}
                            invalidText="Số lượng không hợp lệ"
                            invalid={phancokhi[index].quantity !== '' && (isNaN(phancokhi[index].quantity) || Number(phancokhi[index].quantity) < 0)}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <TextInput
                            id={`status-phancokhi-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phancokhi[index].status = e.target.value;
                              this.setState({ phancokhi });
                            }}
                            value={phancokhi[index].status}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Dropdown
                            id={`measure-phancokhi-Dropdown-${index}`}
                            titleText=""
                            label=""
                            items={measureList}
                            selectedItem={phancokhi[index].measure === '' ? null : measureList.find((e) => e.id === phancokhi[index].measure)}
                            onChange={(e) => {
                              phancokhi[index].measure = e.selectedItem.id;
                              this.setState({ phancokhi });
                            }}
                            disabled={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              this.setState({
                                phancokhi: phancokhi.filter((e) => e.materialID !== row.materialID),
                                engineAnalysisDetailList: engineAnalysisDetailList.filter((e) => e.materialID !== row.materialID),
                              });
                            }}
                            disabled={
                              auth.companyID !== engineAnalysisInfo.companyID ||
                              engineAnalysisInfo.status === 'full-approved' ||
                              engineAnalysisInfo.status === 'complete-approved' ||
                              (auth.userID !== engineAnalysisInfo.requestor &&
                                auth.userID !== engineAnalysisInfo.firstApprover &&
                                auth.userID !== engineAnalysisInfo.secondApprover)
                            }
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="phantruyendong">
                      <TableCell colSpan={9}>
                        <strong>Phần Truyền động - Tổng hợp</strong>
                      </TableCell>
                    </TableRow>
                    {phantruyendong.map((row, index) => (
                      <TableRow key={`row-phantruyendong-${index.toString()}`}>
                        <TableCell />
                        <TableCell>{index + phandien.length + phangam.length + phandongco.length + phanham.length + phancokhi.length + 1}</TableCell>
                        <TableCell>{row.materialID}</TableCell>
                        <TableCell>{row.materialName}</TableCell>
                        <TableCell>{row.unit}</TableCell>
                        <TableCell>
                          <TextInput
                            id={`quantity-phantruyendong-textinput-${index.toString()}`}
                            labelText=""
                            onChange={(e) => {
                              phantruyendong[index].quantity = e.target.value;
                              this.setState({ phantruyendong });
                            }}
                            value={phantruyendong[index].quantity}
                            invalidText="Số lượng không hợp lệ"
                            invalid={
                              phantruyendong[index].quantity !== '' && (isNaN(phantruyendong[index].quantity) || Number(phantruyendong[index].quantity) < 0)
                            }
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <TextInput
                            id={`status-phantruyendong-textinput-${index}`}
                            labelText=""
                            onChange={(e) => {
                              phantruyendong[index].status = e.target.value;
                              this.setState({ phantruyendong });
                            }}
                            value={phantruyendong[index].status}
                            readOnly={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Dropdown
                            id={`measure-phantruyendong-Dropdown-${index}`}
                            titleText=""
                            label=""
                            items={measureList}
                            selectedItem={phantruyendong[index].measure === '' ? null : measureList.find((e) => e.id === phantruyendong[index].measure)}
                            onChange={(e) => {
                              phantruyendong[index].measure = e.selectedItem.id;
                              this.setState({ phantruyendong });
                            }}
                            disabled={auth.role !== 'phongkythuat' || engineAnalysisInfo.status !== 'created'}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              this.setState({
                                phantruyendong: phantruyendong.filter((e) => e.materialID !== row.materialID),
                                engineAnalysisDetailList: engineAnalysisDetailList.filter((e) => e.materialID !== row.materialID),
                              });
                            }}
                            disabled={
                              auth.companyID !== engineAnalysisInfo.companyID ||
                              engineAnalysisInfo.status === 'full-approved' ||
                              engineAnalysisInfo.status === 'complete-approved' ||
                              (auth.userID !== engineAnalysisInfo.requestor &&
                                auth.userID !== engineAnalysisInfo.firstApprover &&
                                auth.userID !== engineAnalysisInfo.secondApprover)
                            }
                          >
                            Xoá
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {auth.role === 'phongkythuat' && engineAnalysisInfo.status === 'created' && (
                      <>
                        <TableRow />
                        <TableRow />
                        <TableRow />
                        <TableRow />
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

EngineAnalysis.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  setMaterialList: PropTypes.func.isRequired,
  common: PropTypes.shape({
    submitResult: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    materialList: PropTypes.arrayOf(
      PropTypes.shape({
        materialID: PropTypes.string.isRequired,
        materialName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
  setMaterialList: (materialList) => dispatch(setMaterialListValue(materialList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EngineAnalysis);
