import { CloudUpload32 } from '@carbon/icons-react';
import {
  Button,
  ComposedModal,
  Dropdown,
  InlineNotification,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assignErrorMessage, setLoadingValue, setSubmitValue } from '../../actions/commonAction';
import { getAllUsers, getCompanyList, getInventoryReportList } from '../../services';

class InventoryReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryReportList: [],
      inventoryReportListDisplay: [],
      page: 1,
      pageSize: 10,
      companyID: props.auth.companyID,
      companyIDs: [],
      users: [],
    };
  }

  componentDidMount = async () => {
    const { setLoading, setErrorMessage, auth } = this.props;
    setLoading(true);
    try {
      const getInventoryReportListResult = await getInventoryReportList(auth.companyID === 'TCT_DS_VN' ? '' : auth.companyID);
      const getCompanyListResult = await getCompanyList();
      const getUsers = await getAllUsers();
      this.setState({
        inventoryReportList: getInventoryReportListResult.data,
        inventoryReportListDisplay: getInventoryReportListResult.data.slice(0, 10),
        users: getUsers.data,
        companyIDs: [
          { id: '', label: 'Toàn Tổng công ty' },
          ...getCompanyListResult.data.map((e) => {
            return { id: e.companyID, label: e.companyName };
          }),
        ],
      });
    } catch {
      setErrorMessage('Lỗi khi tải trang. Vui lòng thử lại');
    }
    setLoading(false);
  };

  render() {
    // Props first
    const { setErrorMessage, setSubmitResult, history, common, auth } = this.props;
    const { submitResult, errorMessage, isLoading } = common;

    // Then state
    const { inventoryReportList, inventoryReportListDisplay, page, pageSize, companyID, companyIDs, users } = this.state;

    return (
      <div className="inventory-report-list">
        {/* Loading */}
        {isLoading && <Loading description="Loading data. Please wait..." withOverlay />}
        {/* Success Modal */}
        <ComposedModal
          className="btn-success"
          open={submitResult !== ''}
          size="sm"
          onClose={() => {
            setSubmitResult('');
            history.push('/home');
          }}
        >
          <ModalHeader iconDescription="Close" title={<div>Thao tác thành công</div>} />
          <ModalBody aria-label="Modal content">
            <div className="form-icon">
              <CloudUpload32 className="icon-prop" />
              <p className="bx--modal-content__text">{submitResult}</p>
            </div>
          </ModalBody>
          <ModalFooter
            onRequestSubmit={() => {
              setSubmitResult('');
              history.push('/home');
            }}
            primaryButtonText="OK"
            secondaryButtonText=""
          />
        </ComposedModal>
        {/* Error Message */}
        <div className="bx--grid">
          <div className="bx--row">
            {errorMessage !== '' && <InlineNotification lowContrast kind="error" title={errorMessage} onCloseButtonClick={() => setErrorMessage('')} />}
          </div>
        </div>
        <br />
        <div className="view-header--box">
          <h4>Danh sách biên bản kiểm kê hàng hoá</h4>
        </div>
        <br />

        {/* Content page */}
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-sm-1 bx--col-md-1 bx--col-lg-1" />
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-3 bx--col-md-3" />
            <div className="bx--col-lg-4" />
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Dropdown
                id="companyID-Dropdown"
                titleText="Đơn vị"
                label=""
                items={companyIDs}
                selectedItem={companyIDs.find((e) => e.id === companyID)}
                onChange={(e) => this.setState({ companyID: e.selectedItem.id })}
                disabled={auth.companyID !== 'TCT_DS_VN'}
              />
            </div>
            <div className="bx--col-lg-4">
              {auth.role === 'phongketoantaichinh' && auth.companyID === companyID && auth.companyID !== 'TCT_DS_VN' && (
                <Button style={{ marginTop: '1rem' }} onClick={() => history.push('/inventory/report')}>
                  Tạo biên bản kiểm kê
                </Button>
              )}
            </div>
          </div>
          <br />
          <br />
          <hr className="LeftNav-module--divider--1Z49I" />
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2" />
            <div className="bx--col-lg-12">
              <TableContainer title={`Có tất cả ${inventoryReportList.length} biên bản giải thể.`}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {auth.companyID === companyID && auth.companyID === 'TCT_DS_VN' && <TableHeader key="companyID">Xí nghiệp</TableHeader>}
                      <TableHeader key="creator">Người tạo</TableHeader>
                      <TableHeader key="firstApprover">Trưởng phòng phê duyệt</TableHeader>
                      <TableHeader key="secondApprover">BGĐ phê duyệt</TableHeader>
                      <TableHeader key="status">Trạng thái</TableHeader>
                      <TableHeader key="detail">Chi tiết</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inventoryReportListDisplay.map((row, index) => (
                      <TableRow key={`row-${index.toString()}`}>
                        {auth.companyID === companyID && auth.companyID === 'TCT_DS_VN' && (
                          <TableCell>{companyIDs.find((e) => e.id === row.companyID).label}</TableCell>
                        )}
                        <TableCell>{users.find((e) => e.userID === row.creator).username}</TableCell>
                        <TableCell>{users.find((e) => e.userID === row.firstApprover).username}</TableCell>
                        <TableCell>{users.find((e) => e.userID === row.secondApprover).username}</TableCell>
                        <TableCell>
                          {row.status === 'created' && <p style={{ color: '#da1e28' }}>Chờ được phê duyệt</p>}
                          {row.status === 'half-approved' && <p style={{ color: '#cfe625' }}>Đã được trưởng phòng phê duyệt</p>}
                          {row.status === 'full-approved' && <p style={{ color: '#12e65c' }}>Đã được ban giám đốc phê duyệt</p>}
                        </TableCell>
                        <TableCell>
                          <Link to={`/inventory/report?inventoryReportID=${row.id}`}>Chi tiết</Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="fixed-pagination"
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[5, 10, 15]}
                totalItems={inventoryReportList.length}
                onChange={(target) => {
                  this.setState({
                    inventoryReportListDisplay: inventoryReportList.slice((target.page - 1) * target.pageSize, target.page * target.pageSize),
                    page: target.page,
                    pageSize: target.pageSize,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2" />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

InventoryReportList.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmitResult: PropTypes.func.isRequired,
  common: PropTypes.shape({ submitResult: PropTypes.string, errorMessage: PropTypes.string, isLoading: PropTypes.bool }).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userID: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    roleName: PropTypes.string,
    address: PropTypes.string,
    isActive: PropTypes.bool,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  common: state.common,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (errorMessage) => dispatch(assignErrorMessage(errorMessage)),
  setLoading: (loading) => dispatch(setLoadingValue(loading)),
  setSubmitResult: (submitResult) => dispatch(setSubmitValue(submitResult)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReportList);
